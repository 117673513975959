import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CustomTableCell } from "./TableCellGenerator";
import { Skeleton } from "@mui/material";

function EnhancedTableHead(props) {
  const { headCells } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <CustomTableCell
            key={index}
            tableCell={{
              ...headCell,
            }}
          />
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function CustomTableStatic({
  tableHeads,
  tableRows,
  tablePagination,
  loading,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  const SkeletonRows = React.useMemo(() => {
    return [...Array(5)].map((_, index) => (
      <TableRow key={index}>
        {tableHeads.map((head) => (
          <TableCell key={head.id} id={head.id}>
            <Skeleton variant="text" height={40}></Skeleton>
          </TableCell>
        ))}
      </TableRow>
    ));
  }, [tableHeads]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <TableContainer sx={{ flexGrow: 1, overflow: "auto" }}>
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead headCells={tableHeads} />
            <TableBody>
              {loading && <>{SkeletonRows}</>}
              {!loading &&
                tableRows.map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      {tableHeads.map((tableHead, index) => {
                        return (
                          <TableCell key={index} id={row[tableHead]}>
                            {row[tableHead.id]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{
            minHeight: "4rem",
          }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={tablePagination.total}
          rowsPerPage={tablePagination.per_page}
          page={tablePagination.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
