import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
// import Divider from '@mui/material/Divider';
// import ArchiveIcon from '@mui/icons-material/Archive';
// import FileCopyIcon from '@mui/icons-material/FileCopy';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import DialogContentText from '@mui/material/DialogContentText';
import DeleteIcon from '@mui/icons-material/Delete';
import api from '../../API/api';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Swal from 'sweetalert2';
import DialogModal from '../modal/modal';
import MoveFolder from '../component/moveFolder';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const CustomizedMenus = forwardRef((props, ref) => {
  // console.log(props ,'props dropdown');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openEdit, setOpenEdit] = useState(false);
  const [openMove, setOpenMove] = useState(false);

  const [name, setName] = React.useState();



  const handleClick = (event) => {
    console.log(event, 'event echecj');
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (id) => {
    console.log(id);
    handleClose();

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        api.deleteSyl(id).then((res) => {
          console.log(res);
          props.getSyllabusById();
          setAnchorEl(null);
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        })
          .catch((err) => {
            console.log(err.response);
          })
      }
    })
  }

  const handleEdit = (data) => {
    setOpenEdit(true);
    setName(data.syllabus)
  }
  const cancelEdit = (data) => {
    setOpenEdit(false);
  }
  const handleUpdate = () => {
    console.log(name);
    console.log(props.data);

    api.updateSyllabus(name, props.data.id).then((res) => {
      console.log("=>", res);
      props.getSyllabusById();
      cancelEdit();
      handleClose()
    })
      .catch((err) => {
        console.log(err.response);
      })
  }

  const handleInput = (e) => {
    setName(e.target.value)
  }

  const editForm = () => {
    return (
      <>
        <Dialog open={openEdit} onClose={handleClose}>
          <DialogTitle>Rename </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={`Edit `}
              type="text"
              fullWidth
              variant="standard"
              onChange={handleInput}
              value={name}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelEdit}>Cancel</Button>
            <Button onClick={handleUpdate}>Update</Button>
          </DialogActions>
        </Dialog>

      </>
    )
  }

  const handleMove = (e) => {
    props.setOpenMove(true);
    props.setSelectId(e)
  }

  const handleCloseMove = () => {
    setOpenMove(false);
  }

  const handleCopy = (data) => {
    props.setOpenMove(true);
    props.setSelectId(data)
    props.setCopy(true);
  }

  useImperativeHandle(ref, (event) => ({
    handleClick2(event) {
      console.log('new2');
      setAnchorEl(true);
    }
  }))


  return (
    <div>
      {editForm()}
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        // variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<EditIcon />}
      >
      </Button>



      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleEdit(props.data)} disableRipple>
          <EditIcon />
          Rename
        </MenuItem>

        <MenuItem onClick={() => handleDelete(props.id)} disableRipple>
          <DeleteIcon />
          Delete
        </MenuItem>

        <MenuItem onClick={() => handleMove(props)} disableRipple>
          <DriveFileMoveIcon />
          Move
        </MenuItem>
        <MenuItem onClick={() => handleCopy(props)} disableRipple>
          <ContentCopyIcon />
          Copy
        </MenuItem>


        {/* <MenuItem onClick={handleClose} disableRipple>
          <FileCopyIcon />
          Duplicate
        </MenuItem>
        <Divider sx={{ my: 0.5 }} />
        <MenuItem onClick={handleClose} disableRipple>
          <ArchiveIcon />
          Archive
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <MoreHorizIcon />
          More
        </MenuItem> */}
      </StyledMenu>
    </div>
  );
});

export default CustomizedMenus;