import { Paper, Typography } from "@mui/material";

const SubscriptionPaper = ({ subscription }) => {
  return (
    <Paper sx={{ p: 2 }}>
      <Typography>Paper ID: {subscription.paper.id}</Typography>
      <Typography>Paper Name: {subscription.paper.name}</Typography>
      <Typography>
        Paper Type: {subscription.paper.paper_type}, Paper Price:
        {subscription.paper.paper_type === "paid"
          ? subscription.paper.paper_price
          : "null"}
      </Typography>
      <Typography>Description: {subscription.paper.description}</Typography>
    </Paper>
  );
};

export default SubscriptionPaper;
