import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Avatar,
  Chip,
} from "@mui/material";
import { styled } from "@mui/system";

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  margin: "auto",
  transition: "0.3s",
  boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
  "&:hover": {
    boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
  },
}));

const UserCard = ({ user }) => {
  return (
    <StyledCard>
      {/* <CardMedia
        component="img"
        height="140"
        image={user.cover_image || "https://source.unsplash.com/random/345x140"}
        alt="User cover"
      /> */}
      <CardContent
        sx={{
          borderRadius: "20px",
        }}
      >
        <Box display="flex" justifyContent="center" mb={2} mt={10}>
          <Avatar
            src={
              user.profile_image || "https://source.unsplash.com/random/345x140"
            }
            alt={`${user.f_name} ${user.l_name}`}
            sx={{
              width: 100,
              height: 100,
              border: "4px solid white",
              marginTop: "-50px",
            }}
          />
        </Box>
        <Typography gutterBottom variant="h5" component="div" align="center">
          {user.f_name} {user.l_name}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          mb={2}
        >
          {user.email}
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
          gap={1}
        ></Box>
      </CardContent>
    </StyledCard>
  );
};

export default UserCard;
