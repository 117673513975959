import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DialogDynamic from "../pannel/dialog";
import Button from "@mui/material/Button";
import TablePagination from "@mui/material/TablePagination";
import CheckboxesPrivilege from "../pannel/checkBoxesPrivilege";

// api
import api from '../../API/api';

function UseListTable(props) {
  // state related to pagination
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [users, setUsers] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
    setPerPage(parseInt(event.target.value, 10));
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };



  useEffect(() => {
      getAllUser()
  }, [page, perPage]);

  const getAllUser = () => {
      props.setBackdropLoader(true);
      api.getAllUserPaginate(page, perPage).then((res) => {
          console.log(res);
          setUsers(res.data);
          props.setBackdropLoader(false);
      }).catch((err) => {
          console.log(err.response);
      })
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>User Name</TableCell>
            <TableCell align="right">Role</TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Privileges</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users && users.data &&
            users.data.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.username}
                </TableCell>
                <TableCell align="right">{row.role}</TableCell>
                <TableCell align="right">{row.email}</TableCell>
                <TableCell align="right">Active</TableCell>
                <TableCell align="right">
                  <DialogDynamic
                    buttonText={<Button variant="contained">i</Button>}
                    maxWidth="md"
                    formTitle="Subject Privileges"
                    data={row}
                    form={<CheckboxesPrivilege data={row} />}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={users?.total}
        rowsPerPage={users?.per_page}
        page={users?.current_page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}
export default UseListTable;
