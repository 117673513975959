import { Paper, Stack } from "@mui/material";
import UserDetailTab from "./user-detail-tab";
import { useState } from "react";
import UserDetailGeneral from "./user-detail-general";
import UserDetailPurchases from "./user-detail-purchases";
import UserDetailPaper from "./user-detail-paper";

const UserDetailMain = ({ user }) => {
  const [tabs, setTabs] = useState(["General", "Purchases"]);

  const [activeTab, setActiveTab] = useState(tabs[0]);

  return (
    <Paper
      elevation={3}
      sx={{ flex: 3, padding: 2, overflow: "hidden", position: "relative" }}
      component={Stack}
      direction={"column"}
    >
      <UserDetailTab
        active={activeTab}
        setActiveTab={setActiveTab}
        tabs={tabs}
      />
      {activeTab === "General" && <UserDetailGeneral user={user} />}
      {activeTab === "Purchases" && <UserDetailPurchases user={user} />}
      {activeTab === "Papers" && <UserDetailPaper user={user} />}
    </Paper>
  );
};

export default UserDetailMain;
