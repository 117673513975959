import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled, alpha } from '@mui/material/styles';
import { Chip } from '@mui/material';


function PointsTable({ medals, handleEdit, openDelete }) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const [selectedValue, setSelectedValue] = React.useState();

    const handleClick = (event, data) => {
        setAnchorEl(event.currentTarget);
        setSelectedValue(data)
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    // const handleDelete = () => {
    //     setAnchorEl(true)
    //     handleDelete(selectedValue)
    // }
    const handleClickEdit = () => {
        // setOpen(true)
        handleEdit(selectedValue)
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Sl No.</TableCell>
                        <TableCell>Medal</TableCell>
                        <TableCell align="center">Minimum Value</TableCell>
                        <TableCell align="center">Maximum Value</TableCell>
                        <TableCell align="center">Icon</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {medals && medals.map((row, index) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {index + 1}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="center">{row.min}</TableCell>
                            <TableCell align="center">{row.max}</TableCell>
                            <TableCell align="center"> <img src={`${process.env.REACT_APP_BACKEND}medals/${row.image}`} width={100} alt="medal" /> </TableCell>
                            <TableCell align="center">
                                {
                                    row.isActive === 1 ?
                                        <Chip label="Active" color="success" />
                                        :
                                        <Chip label="Disable" color="error" />
                                }
                            </TableCell>

                            <TableCell align="right">
                                <Stack spacing={1} className='text-center'>
                                    <IconButton aria-label="more"
                                        onClick={(e) => handleClick(e, row)}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                </Stack>

                                <StyledMenu
                                    id="demo-customized-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'demo-customized-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={openMenu}
                                    onClose={handleClose}
                                >
                                    <MenuItem
                                        onClick={() => handleClickEdit()}
                                    >
                                        Edit
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => openDelete(selectedValue)}
                                    >
                                        {
                                            selectedValue?.isActive === 1 ?
                                            'Make Disable'
                                            :
                                            'Make Enable'
                                        }
                                    </MenuItem>
                                </StyledMenu>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default PointsTable;