import React from "react";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelectChip({
  chipID,
  title,
  label,
  change_type,
  data,
  setSelected,
  selected,
  handleChange,
}) {
  const theme = useTheme();

  return (
    <FormControl sx={{ width: "100%" }}>
      <h6>{title}</h6>
      <Select
        id={chipID}
        multiple
        value={selected}
        onChange={(e) => handleChange(e, change_type)}
        input={
          <OutlinedInput id={chipID + "select-multiple-chip"} label={label} />
        }
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected &&
              selected.map((value) => (
                <Chip key={value.name} label={value.name} />
              ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {data &&
          data.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, selected, theme)}
            >
              {name.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
