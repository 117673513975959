import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
// import DialogDynamic from '../pannel/dialog';
// import { Button } from '@mui/material';
// import CheckboxesPrivilege from '../pannel/checkBoxesPrivilege';

// api
import api from "../../../API/api";
import TablePagination from "@mui/material/TablePagination";
import API from "../../../API/apiConfig";
import { IconButton, Menu, Stack, MenuItem, Box } from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import useBasicLoader from "../../../hooks/useBasicLoader";
import {
  PackageAppUserSubscriptions,
  PaperAppUserSubscriptions,
} from "./ConditionalTableAppUser";

function IndividualUserSubscriberTable(props) {
  console.log("props", props);
  // state related to pagination
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [userSubscriptions, setUserSubscriptions] = useState(null);
  const { loading, changeLoading } = useBasicLoader();

  const [filters, setFilters] = useState({
    firstName: "",
    lastName: "",
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const open = Boolean(anchorEl);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
    setPerPage(parseInt(event.target.value, 10));
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    // don't change the selected value
    // on edit button or any other
    // there is a side effect when sub menu is clicked
    setSelectedValue(data);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickDetail = (data) => {};

  const getUserSubscriptions = (page, perPage, filters) => {
    changeLoading(true);

    api
      .getSubscriptionsForUser({
        user_id: props.user.user_id,
        type: props.subscriptionType,
        page: page,
        per_page: perPage,
        filters,
      })
      .then((res) => {
        if (res.data.success) {
          console.log(res.data);
          setUserSubscriptions(res.data.subscriptions);
        }

        changeLoading(false);
      })
      .catch((err) => {
        changeLoading(false);
      });
  };

  useEffect(() => {
    getUserSubscriptions(page, perPage, filters);
  }, [page, perPage, filters, props.subscriptionType]);

  useEffect(() => {
    getUserSubscriptions(page, perPage, filters);

    return () => {
      console.log("what was it", userSubscriptions);
    };
  }, []);

  return (
    <div>
      <section>
        <div>{selectedValue ? selectedValue.email : null}</div>
        <TableContainer component={Paper}>
          {props.subscriptionType === "paper" && (
            <PaperAppUserSubscriptions
              loading={loading}
              userSubscriptions={userSubscriptions}
              key={props.subscriptionType}
            />
          )}

          {props.subscriptionType === "package" && (
            <PackageAppUserSubscriptions
              loading={loading}
              userSubscriptions={userSubscriptions}
              key={props.subscriptionType}
            />
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={userSubscriptions?.total}
          rowsPerPage={userSubscriptions?.per_page}
          page={userSubscriptions?.current_page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </section>
    </div>
  );
}
export default IndividualUserSubscriberTable;
