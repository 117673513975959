import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
// import Swal from 'sweetalert2';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import api from "../../API/api";
import StudyMaterialList from "../table/studyMaterial";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const StudyMaterial = () => {
  const navigate = useNavigate();

  const [stydyMaterials, setStudyMaterials] = useState();

  useEffect(() => {
    getStudyMeterials();
  }, []);

  const getStudyMeterials = () => {
    api
      .getStudyMeterials()
      .then((res) => {
        console.log("==>", res);
        setStudyMaterials(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const [backdroploader, setBackdropLoader] = useState(false);
  const handleClose = () => {
    setBackdropLoader(false);
  };

  const removeStudyMaterialClient = (id) => {
    // remove from client
    setStudyMaterials(
      stydyMaterials.filter((studyMaterial) => studyMaterial.id !== id)
    );
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdroploader}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* <LinearWithValueLabel /> */}
      <section>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="row">
          <Paper>
            <div className="my-3">
              {/* <Button variant="contained" style={{ 'float': 'right' }} size="small">View Questions</Button> */}
            </div>
            <div>
              <div className="row">
                <div className="col-10">
                  <h3>Study Material </h3>
                </div>
                <div className="col-2 text-end">
                  <button
                    onClick={() => navigate("/admin/StudyMaterialForm")}
                    className="btn btn-primary btn-sm"
                  >
                    + Add New{" "}
                  </button>
                </div>
              </div>
            </div>
          </Paper>
        </div>

        <div>
          <div className="row">
            <div className="col-12">
              <div className="m-2">
                <StudyMaterialList
                  stydyMaterials={stydyMaterials}
                  removeStudyMaterialClient={removeStudyMaterialClient}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StudyMaterial;
