import { useEffect, useState } from "react";
import api from "../../API/api";
import useDebounce from "../../hooks/useDebounce";

const usePushNotificationStatesHook = () => {
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [appUsers, setAppUsers] = useState();

  const [filters, setFilters] = useState({
    firstName: "",
    lastName: "",
    email: "",
    id: "",
    phone: "",
  });

  const handleChangePage = (newPage) => {
    setPage(newPage + 1);
    setLoading(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
    setPerPage(parseInt(event.target.value, 10));
    setLoading(true);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const getAllAppUsers = () => {
    setLoading(true);
    api
      .appUsersPaginate(page, perPage, filters)
      .then((res) => {
        setAppUsers(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Debounced fetch function
  const debouncedFetchUsers = useDebounce(() => {
    getAllAppUsers(page, perPage, filters);
  }, 300);

  useEffect(() => {
    debouncedFetchUsers();
  }, [page, perPage]);

  useEffect(() => {
    debouncedFetchUsers();
  }, [filters]);

  const changeFilters = (key, value) => {
    console.log("key", key, "value", value);
    setFilters({ ...filters, [key]: value });
    setLoading(true);
  };

  return {
    loading,
    setLoading,
    appUsers,
    setAppUsers,
    page,
    setPage,
    perPage,
    setPerPage,
    filters,
    setFilters,
    handleChangeDense,
    handleChangePage,
    handleChangeRowsPerPage,
    getAllAppUsers,
    changeFilters,
  };
};

export default usePushNotificationStatesHook;
