import React from "react";
import InfoQues from "../question/infoQues";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import MenuAction from "../question/menuActions";
import AuthUser from "../../API/token";
import CircularProgress from '@mui/material/CircularProgress';
// import "https://unpkg.com/mathlive";
import { Backdrop } from "@mui/material";
// import 'https://unpkg.com/mathlive/dist/mathlive-static.css'
// import { convertLatexToMarkup ,latexToMarkup,renderMathInDocument} from 'mathlive';
import { MathfieldElement, _renderMathInElement, convertAsciiMathToLatex, convertLatexToAsciiMath, convertLatexToMarkup, convertLatexToMathMl, convertLatexToSpeakableText, convertMathJsonToLatex, globalMathLive, makeSharedVirtualKeyboard, renderMathInDocument, renderMathInElement, validateLatex } from 'mathlive';
// import "//unpkg.com/mathlive";
// import '../../mathcss.css'
import EnhancedTable from "./questionsPaginate";


const htmlToFormattedText = require("html-to-formatted-text");

//  if came from subject then this table

const QuestionAllTable = (props) => {
    const { user } = AuthUser();
    const [backdroploader, setBackdropLoader] = React.useState(false);


    const childRef = React.useRef(null);
    const handleClick = () => {
        childRef.current.handleClose();
    };
    const handleCloseLoader = () => {
        setBackdropLoader(false);
    };

    // // Update the mathfield when the value changes
    // React.useEffect(() => {
    //     function replaceSpaces(input) {
    //         if (input == null) return "";
    //         var output = input.toString().replace(/\s/g, "' '");
    //         return "$$" + output + "$$";
    //     }
    //     var elements = document.getElementsByClassName("mathlive");
    //     for (let i = 0; i < elements.length; i++) {
    //         var el = elements[i];

    //         console.log(el,'elements');
    //         el.innerHTML = convertLatexToMarkup(
    //             replaceSpaces(el.innerText), {
    //             letterShapeStyle: 'iso',
    //             mathstyle: 'textstyle'
    //         }
    //         );
    //     }

    //     renderMathInDocument()
    // }, [props.questions])
    // console.log(props, 'check questiosn');
    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdroploader}
                onClick={handleCloseLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Paper elevation={3} >
                <div className="row">
                    <EnhancedTable
                        all={props.questions}
                        page={props.page}
                        setPage={props.setPage}
                        perPage={props.perPage}
                        setPerPage={props.setPerPage}
                        handleDelete={props.handleDelete}
                        handleApprove={props.handleApprove}
                        handleClickEdit={props.handleClickEdit}
                        handleDeleteModal={props.handleDeleteModal}
                    />
                    {/* <TableContainer>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow className='bg-dark'>
                                    <TableCell className='text-white'> <b> #</b> </TableCell>
                                    <TableCell className='text-white'> <b> Questions</b> </TableCell>
                                    <TableCell className='text-white' align='center'> <b> Answers </b> </TableCell>
                                    <TableCell className='text-white' align="center"> <b> Status </b> </TableCell>
                                    <TableCell className='text-white' align="center"> <b> Actions </b> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {props.questions && props.questions.map((row, index) => (
                                    <>
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {index + 1}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {
                                                    row.type === 3 ?
                                                        <span className="mathlive">
                                                            <math-field
                                                                onInput={evt => row.question}
                                                                readonly
                                                                style={{ border: 0 }}
                                                            >
                                                                {row.question}
                                                            </math-field>
                                                        </span>
                                                        :
                                                        <div dangerouslySetInnerHTML={{ __html: row.question }} />
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        {row?.ans.map((el, index) => (
                                                            <>
                                                                {
                                                                    el.question_type === 2 ?
                                                                        el.isRight == 1 &&
                                                                        <div>
                                                                            <img style={{ height: 50, width: 50 }} src={`${process.env.REACT_APP_BACKEND}answers/img/${el.answer}`} />
                                                                        </div>
                                                                        :
                                                                        el.question_type === 1 ?
                                                                            el.isRight == 1 &&
                                                                            <div key={index} className={el.isRight ? 'text-success' : 'text-danger'}>
                                                                                <b>
                                                                                    <strong>
                                                                                        <div dangerouslySetInnerHTML={{ __html: el.answer }} />
                                                                                    </strong>
                                                                                </b>
                                                                            </div>
                                                                            :
                                                                            el.isRight == 1 &&
                                                                            <math-field
                                                                                readonly
                                                                                style={{ border: 0 }}
                                                                                key={index}
                                                                            >
                                                                                {el.answer}
                                                                            </math-field>


                                                                }
                                                            </>
                                                        )
                                                        )}
                                                    </div>
                                                    <div className="col">
                                                        <InfoQues ans={row} />
                                                    </div>

                                                </div>

                                            </TableCell>

                                            <TableCell component="th" scope="row" align="center">
                                                {row?.isApprove ? <><span className='bg-success m-1 px-1 rounded text-white'>Approved</span></> : <><span className='bg-danger m-1 px-3 rounded text-white'>Draft</span></>}
                                            </TableCell>

                                            <TableCell component="th" scope="row" align="center">
                                                <MenuAction data={row} handleClickEdit={props.handleClickEdit} getQuestionBySyl={props.getQuestionBySyl} questions={props.questions} handleApprove={props.handleApprove} ref={childRef}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer> */}
                </div>
            </Paper>
        </>

    );
}

export default QuestionAllTable;


