import React, { useState, useEffect } from "react";

import Button from "@mui/material/Button";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import ExamModal from "../pannel/examModal";

import api from "../../API/api";

import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

import Swal from "sweetalert2";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import LinearProgress from "@mui/material/LinearProgress";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const AddExamName = () => {
  const [examData, setExamData] = useState([]);

  const [loading, setLoading] = useState(true);

  const [organization, setOrganization] = useState();
  const [examType, setExamType] = useState();

  useEffect(() => {
    viewData();
    getOrganizations();
  }, []);

  const getOrganizations = () => {
    api
      .getOrganization()
      .then((res) => {
        console.log(res);
        setOrganization(res.data.organization);
        setExamType(res.data.examType);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const viewData = () => {
    api
      .getExamType()
      .then((res) => {
        console.log(res);
        setExamData(res.data.examtype);
      })
      .catch((err) => {
        console.log(err.response);
      });

    // axios
    //   .get(`${process.env.REACT_APP_MAIN_API}/getExamType`)
    //   .then((res) => {
    //     console.log(res);
    //     setExamData(res.data.examtype);
    //   })
    //   .catch((err) => {
    //     console.log(err.response);
    //   });
  };

  const [nameData, setNameData] = useState([]);

  useEffect(() => {
    viewName();
  }, []);

  const viewName = () => {
    api
      .getExamName()
      .then((res) => {
        setLoading(false);
        console.log(res);
        setNameData(res.data.examname);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const [open, setOpen] = React.useState(false);
  const [update, setUpdate] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setName({});
    setUpdate(false);
  };

  const [name, setName] = useState({
    id: "",
    examName: "",
    institute: "",
    specialization: "",
    grade: "",
  });

  const handleInput = (e) => {
    setName({
      ...name,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    console.log("=>>", name);

    const postData = new FormData();
    postData.append("examName", name.examName);
    postData.append("institute", name.institute);
    postData.append("specialization", name.specialization);
    postData.append("grade", name.grade);

    api
      .postExamName(postData)
      .then((res) => {
        console.log(res);
        viewName();
        handleClose();
        Swal.fire("Good job!", "Registered Successfully!", "success");
      })
      .catch((err) => {
        console.log(err.response);
      });

    // axios
    //   .post(`${process.env.REACT_APP_MAIN_API}/postExamName`, postData)
    //   .then((res) => {
    //     console.log(res);
    //     viewName();
    //     handleClose();
    //     Swal.fire("Good job!", "Registered Successfully!", "success");
    //   })
    //   .catch((err) => {
    //     console.log(err.response);
    //   });
  };

  const handleEdit = (id) => {
    examDataById(id);
  };

  const handleUpdate = (id) => {
    console.log("update", id);
    api
      .updateExamData(id, name)
      .then((res) => {
        console.log(res);
        viewName();
        handleClose();
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  const examDataById = (id) => {
    console.log("working");
    api
      .examDataById(id)
      .then((res) => {
        console.log(res);
        setName(res.data.examname);
        setUpdate(true);
        handleOpen();
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const Loading = () => {
    return (
      <>
        <div className="container p-5">
          <div className="row">
            <div className="col-md-12">
              <div className="p-4 row">
                <div className="col-md-12">
                  <Skeleton
                    height={35}
                    width={200}
                    count={1}
                    style={{ marginBottom: 10, float: "right" }}
                  />
                </div>
                <div className="col-md-12">
                  <Skeleton height={35} count={1} style={{ marginBottom: 6 }} />
                </div>
                <div className="col-md-4 my-2">
                  <Skeleton
                    height={30}
                    count={10}
                    style={{ marginBottom: 6 }}
                  />
                </div>
                <div className="col-md-4 my-2">
                  <Skeleton
                    height={30}
                    count={10}
                    style={{ marginBottom: 6 }}
                  />
                </div>
                <div className="col-md-4 my-2">
                  <Skeleton
                    height={30}
                    count={10}
                    style={{ marginBottom: 6 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {loading ? (
        <>
          <LinearProgress color="secondary" />
          <Loading />
        </>
      ) : (
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Button
                  onClick={handleOpen}
                  variant="contained"
                  className="my-3"
                  style={{ float: "right" }}
                  size="small"
                >
                  + Add Exam Name
                </Button>
                <ExamModal
                  handleInput={handleInput}
                  name={name}
                  examData={examData}
                  organization={organization}
                  handleSubmit={handleSubmit}
                  open={open}
                  handleClose={handleClose}
                  update={update}
                  handleUpdate={handleUpdate}
                />
              </div>

              <div className="col-lg-12">
                <div>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: "100%" }}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center">ID</StyledTableCell>
                          <StyledTableCell align="center">
                            Exam Name
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Organisation Board
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Exam Type
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Grade
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Specialization
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Action
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {organization &&
                          nameData.map((row) => (
                            <StyledTableRow key={row.id}>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                align="center"
                              >
                                {row.id}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.examName}
                              </StyledTableCell>
                              {organization &&
                                organization.map((org) => (
                                  <>
                                    {org.id == row.institute && (
                                      <>
                                        <StyledTableCell align="center">
                                          {org.organization_name}{" "}
                                        </StyledTableCell>

                                        {examType &&
                                          examType.map((type) => (
                                            <>
                                              {type.id == org.examType_id && (
                                                <StyledTableCell align="center">
                                                  {type.examType}
                                                </StyledTableCell>
                                              )}
                                            </>
                                          ))}

                                        <StyledTableCell align="center">
                                          {row.grade}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {row.specialization}
                                        </StyledTableCell>
                                      </>
                                    )}
                                  </>
                                ))}

                              <StyledTableCell align="center">
                                <button
                                  className="btn btn-success btn-sm"
                                  title="edit"
                                  onClick={() => handleEdit(row.id)}
                                >
                                  <DriveFileRenameOutlineIcon fontSize="small" />
                                </button>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default AddExamName;
