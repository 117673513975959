import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import AppUsersTable from "../table/appusers";
import api from "../../API/api";
import useMUIModalState from "../../hooks/useMUIModalState";
import AppUserInfo from "./app-user-info.page";
import { useNavigate } from "react-router-dom";
import UserDetailModal from "./components/user-detail-modal";

const AppUsersList = (props) => {
  const navigate = useNavigate();

  const [backdroploader, setBackdropLoader] = useState(false);

  // this sets the currently clicked row in the table
  const [selectedUser, setSelectedUser] = useState(null);

  // modal state for viewing user info
  const { open, handleOpen, handleClose, handleSelectedValue } =
    useMUIModalState();

  const handleUserClick = (user) => {
    handleOpen();
    setSelectedUser(user);
    // navigate(`/admin/app-users/${user.id}`);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdroploader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <section>
        <div className="row">
          <Paper>
            <div className="my-2">
              <h3>App Users and Details </h3>
              <h6>
                {selectedUser?.f_name} {selectedUser?.l_name}{" "}
              </h6>
            </div>
          </Paper>
        </div>

        <div>
          <div className="row">
            <div className="col-12">
              <div style={{ margin: 5 }}>
                <AppUsersTable
                  setBackdropLoader={setBackdropLoader}
                  handleUserClick={handleUserClick}
                />
              </div>
            </div>
          </div>
        </div>
        <UserDetailModal
          open={open}
          handleClose={handleClose}
          user={selectedUser}
        />
      </section>
    </>
  );
};

export default AppUsersList;
