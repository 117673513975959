import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material';
import { Paper } from '@mui/material';
import Swal from 'sweetalert2';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Form from 'react-bootstrap/Form';
import api from '../../../API/api';

const AddUsers = () => {
    const [backdroploader, setBackdropLoader] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [user, setUser] = useState({
        username: '',
        email: '',
        phoneNumber: '',
        role: '',
        password: '',
        re_password: ''
    })
    const [error, setError] = useState(null);
    console.log(error && error, 'user');

    const handleClose = () => {
        setBackdropLoader(false);
    };

    // email handling part
    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }
    const handleEmail = (e) => {
        if (!isValidEmail(e.target.value)) {
            setError({ ...error, email: 'Email is Invalid' })
            // console.log('falseee');
        } else {
            setError(null)
        }
        setUser({ ...user, email: e.target.value })
    }
    // email handling part
    // handle number
    const handleNumber = (e) => {
        // console.log(e.target.value.length, 'length ');
        if (e.target.value.length === 10) {

        }
        setUser({ ...user, phoneNumber: e.target.value.replace(/\D/g, '') })
    }
    // handle number
    const handlePassword = (e) => {
        if (user?.password === e.target.value) {
            setError('')
            setDisabled(false)
        } else {
            setError({ ...error, password: 'Password does not match' })
            setDisabled(true)
        }
        setUser({ ...user, re_password: e.target.value })
        console.log(error);
    }

    const submitUser = () => {
        // if (error === null) {
            if (user.username.trim() === '') {
                setError({ ...error, username: "Username Can't be empty" })
                return;
            } else if (user.email.trim() === '' && user.email.isValidEmail()) {
                setError({ ...error, email: "Email Can't be empty" })
                return;
            } else if (user.phoneNumber.trim() === '') {
                setError({ ...error, phoneNumber: "PhoneNumber Can't be empty" })
                return;
            } else if (user.role.trim() === '') {
                setError({ ...error, role: "Role Can't be empty" })
                return;
            } else if (user.password.trim() === '') {
                setError({ ...error, password: "password Can't be empty" })
                return;
            } else {
                setError('')
                console.log("q");
            }

        // } else {
            // console.log("q-");
            api.register(user).then((res) => {
                console.log(res);
                Swal.fire(
                    'Success',
                    // '',
                    'User Added Successfully'
                )
            }).catch((err) => {
                console.log(err.response);
            })
        // }
    }

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdroploader}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {/* <LinearWithValueLabel /> */}
            <section>
                <div className='row'>
                    <Paper>
                        <div className='my-3'>
                            {/* <Button variant="contained" style={{ 'float': 'right' }} size="small">View Questions</Button> */}
                        </div>
                        <div>
                            <div className="row">
                                <div className="col-12">
                                    <h3>Add Users </h3>
                                </div>
                            </div>
                        </div>
                    </Paper>
                </div>
                <div>
                    <div className="row">
                        <div className="col-12">
                            <Paper elevation={4} className='p-4 m-2'>
                                <div className='row'>
                                    <Form.Group className="mb-3 col-md-4">
                                        <Form.Label>User Name</Form.Label>
                                        <Form.Control type="text" placeholder='Enter Your Username' onChange={(e) => setUser({ ...user, username: e.target.value })} value={user?.username} required />
                                        {error?.username ? <small className='text-danger'> {error?.username} </small> : ''}
                                    </Form.Group>
                                    <Form.Group className="mb-3 col-md-4">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" placeholder='Enter Your Email address' onChange={handleEmail} value={user?.email} required />
                                        {error?.email ? <small className='text-danger'> {error?.email} </small> : ''}
                                    </Form.Group>
                                    <Form.Group className="mb-3 col-md-4">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control type="text" maxLength={'10'} min={'0'} placeholder='Enter Your Phone Number' onChange={handleNumber} value={user?.phoneNumber} />
                                        {error?.phoneNumber ? <small className='text-danger'> {error?.phoneNumber} </small> : ''}

                                    </Form.Group>
                                    <Form.Group className="col-md-4 mb-5" >
                                        <Form.Label> What role is he in ?</Form.Label>
                                        <Form.Select value={user?.role} onChange={(e) => setUser({ ...user, role: e.target.value })}>
                                            <option value='' disabled >----select----</option>
                                            <option value='QC'>Question Contributers</option>
                                            <option value='QS'>Question Setter</option>
                                            <option value='SA'>Super Admin</option>
                                            <option value='Admin'>Admin</option>
                                            <option value='User'>User</option>
                                        </Form.Select>
                                        {error?.role ? <small className='text-danger'> {error?.role} </small> : ''}
                                    </Form.Group>
                                    <Form.Group className="mb-3 col-md-4">
                                        <Form.Label>Please Enter Password</Form.Label>
                                        <Form.Control type="password" onChange={(e) => setUser({ ...user, password: e.target.value })} value={user?.password} />
                                    </Form.Group>
                                    <Form.Group className="mb-3 col-md-4">
                                        <Form.Label>Re enter Password</Form.Label>
                                        <Form.Control type="password" onChange={handlePassword} value={user?.re_password} />
                                        {
                                            error?.password ? <small className='text-danger'>{error.password}</small> : ''
                                        }
                                    </Form.Group>
                                </div>
                                <Button variant="contained" onClick={submitUser} disabled={disabled && disabled}>Create User</Button>
                            </Paper>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default AddUsers;