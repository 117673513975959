import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import RichText from "../pannel/richtext";
import api from "../../API/api";
import Swal from 'sweetalert2';
import AuthUser from "../../API/token";

const Comprehension = ({ subjects, syllabus, handleChangeSubject }) => {
    const { user } = AuthUser();
    const [draft, setDraft] = useState([]);

    const [form, setForm] = useState({
    });

    const [passage, setPassage] = useState();

    const handleInput = (e) => {
        if (e.target.name == 'subject_id') {
            handleChangeSubject(e)
        }
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const [question, setQuestion] = useState();
    const [ra, setRa] = useState();
    const [w1, setW1] = useState();
    const [w2, setW2] = useState();
    const [w3, setW3] = useState();


    const [hide, setHide] = useState(false)

    const handleQuestion = (e, value) => {
        if (value === "question") {
            setQuestion(e);
        }
        if (value === "right_ans") {
            setRa(e);
        }
        if (value === "w1") {
            setW1(e);
        }
        if (value === "w2") {
            setW2(e);
        }
        if (value === "w3") {
            setW3(e);
        }
    };

    const addDraft = () => {
        setDraft([
            ...draft,
            {
                question: question,
                right_ans: ra,
                w_ans1: w1,
                w_ans2: w2,
                w_ans3: w3,
            }
        ]);

        setHide(!hide)
    };

    const handleSubmit = () => {
        const data = {
            common: form,
            passage: passage,
            questions: draft,
            user_id: user.id
        }
        console.log("==>", data);
        // return;
        api.savePassageQuestion(data).then((res) => {
            console.log("res", res);
            if (res.data.status == 200) {
                Swal.fire({
                    title: 'Saved',
                    text: 'Questions added Successfully ',
                    icon: 'success'
                });
            }
            else {
                Swal.fire({
                    title: 'Opss',
                    text: 'Something went wrong ! ',
                    icon: 'error'
                });
            }
        })
            .catch((err) => {
                console.log(err);
            })
    }


    const mainQuestion = () => {
        return (
            <>
                <Form.Group className="col-md-3 mb-4 mt-2">
                    <Form.Label>Difficulties </Form.Label>
                    <Form.Select name='difficulties'
                        onChange={handleInput}
                    >
                        <option value=''>--select--  </option>
                        <option value='1'>Easy </option>
                        <option value='2'>Medium </option>
                        <option value='3'>Hard </option>
                    </Form.Select>
                </Form.Group>

                <Form.Group className="col-md-3 mb-4 mt-2">
                    <Form.Label>Class</Form.Label>
                    <Form.Select name='class'
                        onChange={handleInput}
                    >
                        <option value=''>--select--  </option>
                        <option value='8'>Class 8 </option>
                        <option value='9'>Class 9 </option>
                        <option value='10'>Class 10 </option>
                        <option value='11'>Class 11 </option>
                        <option value='12'>Class 12 </option>
                        <option value='13'>UG</option>
                        <option value='14'>PG</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group className="col-md-3 mb-4 mt-2">
                    <Form.Label> Select Subject </Form.Label>
                    <>
                        <Form.Select name='subject_id'
                            onChange={handleInput} value={form.subject_id}
                        >
                            <option value=''>--select-- </option>
                            {subjects?.map((sub, index) => (
                                <option key={index} value={sub.id}>{sub.subName}</option>
                            ))}
                        </Form.Select>
                    </>

                </Form.Group>

                <Form.Group className="col-md-3 mb-4 mt-2">
                    <Form.Label>Select Syllabus</Form.Label>
                    <Form.Select name='syllabus_id'
                        onChange={handleInput} value={form.syllabus_id}
                    >
                        {/* ,   */}
                        <option value=''>--select-- </option>
                        {syllabus && syllabus.map((syl) => (
                            <option key={syl.id} value={syl.id}>{syl.syllabus}</option>
                        ))}
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-5">
                    <Form.Label>Enter Passage  </Form.Label>
                    <RichText name="question"
                        handleForm={(e) => setPassage(e)} value={passage}
                    />
                </Form.Group>
                {/* <hr /> */}



            </>
        )
    }



    const viewQuestion = () => {
        return (
            <>
                <div className="row">
                    {draft && draft.map((q) => (
                        <>
                            <div className="card my-2 p-2">
                                <div className="col-12">
                                    <div className="d-flex">
                                        <span> <strong> Question:</strong> &nbsp;</span> <span dangerouslySetInnerHTML={{ __html: q.question }} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex">
                                        <span> <strong> Right Ans:</strong> &nbsp;</span> <span dangerouslySetInnerHTML={{ __html: q.right_ans }} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex">
                                        <span> <strong> Wrong Ans 1:</strong> &nbsp;</span> <span dangerouslySetInnerHTML={{ __html: q.w_ans1 }} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex">
                                        <span> <strong> Wrong Ans 2:</strong> &nbsp;</span> <span dangerouslySetInnerHTML={{ __html: q.w_ans2 }} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex">
                                        <span> <strong> Wrong Ans 3:</strong> &nbsp;</span> <span dangerouslySetInnerHTML={{ __html: q.w_ans3 }} />
                                    </div>
                                </div>
                            </div>
                        </>
                    ))}
                </div>
            </>
        )
    }


    return (
        <>
            <div className="row">
                {/* ///////// */}

                <div className="row">
                    {mainQuestion()}
                </div>
                <div>
                    {viewQuestion()}
                </div>
                {!hide &&
                    <div>
                        <div className="float-end pb-3">
                            <button className="btn btn-warning btn-sm" onClick={() => setHide(!hide)}> + Add Sub Question</button>
                        </div>
                    </div>
                }
                {hide &&
                    <div>
                        <div className="bg-light mb-4">
                            <div className="text-end" onClick={() => setHide(!hide)()}>
                                x
                            </div>
                            <Form.Group className="mb-5">
                                <Form.Label className="m-2">Enter Question </Form.Label>

                                <RichText
                                    name={`question`}
                                    handleForm={(e) => handleQuestion(e, "question")}
                                />
                            </Form.Group>
                            <Form.Group className="mb-5 bg-success">
                                <Form.Label className="m-2 text-white">Right Answer </Form.Label>
                                <RichText
                                    name="question"
                                    handleForm={(e) => handleQuestion(e, "right_ans")}
                                    value={""}
                                />
                            </Form.Group>

                            <Form.Group className="mb-5 bg-danger ">
                                <Form.Label className="m-2 text-white">Wrong Answer </Form.Label>
                                <RichText
                                    name="question"
                                    handleForm={(e) => handleQuestion(e, "w1")}
                                />
                            </Form.Group>
                            <Form.Group className="mb-5 bg-danger">
                                <Form.Label className="m-2 text-white">Wrong Answer </Form.Label>
                                <RichText
                                    name="question"
                                    handleForm={(e) => handleQuestion(e, "w2")}
                                />
                            </Form.Group>
                            <Form.Group className="mb-5 bg-danger">
                                <Form.Label className="m-2 text-white">Wrong Answer </Form.Label>
                                <RichText
                                    name="question"
                                    handleForm={(e) => handleQuestion(e, "w3")}
                                />
                            </Form.Group>
                        </div>

                        <div className="float-end">
                            <button className="btn btn-danger btn-sm m-2" onClick={()=>setHide(!hide)}>
                                Cancel
                            </button>
                            <button className="btn btn-primary btn-sm m-2" onClick={addDraft}>
                                Add sub Question
                            </button>
                        </div>
                    </div>
                }
                <hr />

                <div className="my-3">
                    <div className="text-center">
                        <button className="btn btn-success" onClick={handleSubmit}>Submit and Save</button>
                    </div>
                </div>

            </div>
        </>
    );
};

export default Comprehension;
