import React, { useEffect } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { Link, Outlet } from "react-router-dom";
import Navbar from "../../components/common/nav";
import MenuIcon from "@mui/icons-material/Menu";
import AuthUser from "../../API/token";
import { sidebar } from "../sidenav";
import { ToastContainer } from "react-toastify";

const Dash = () => {
  useEffect(() => {
    console.log("userr===>>>", JSON.parse(sessionStorage.getItem("user")).role);
  }, []);

  const { token, logout, user } = AuthUser();

  const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } =
    useProSidebar();
  return (
    <>
      <Navbar />
      <div style={{ display: "flex", minHeight: "90vh" }}>
        <Sidebar
          collapsedWidth="70px"
          style={{ maxHeight: "90vh", overflow: "auto" }}
        >
          <Menu>
            {/* <div className='text-center m-2'>
                            <strong> LearnOindia </strong>
                        </div> */}
            {sidebar.map((main) => (
              <>
                {main.UserType ==
                  JSON.parse(sessionStorage.getItem("user")).role && (
                  <>
                    {main.items.map((nav) => (
                      <>
                        <span key={nav.text}></span>
                        {nav.children ? (
                          <>
                            <SubMenu icon={nav.icon} label={nav.text}>
                              {nav.children.map((ch) => (
                                <>
                                  <span key={ch.text}></span>
                                  <MenuItem
                                    rootStyles={{
                                      paddingLeft: "10px",
                                      color: "#b13939",
                                    }}
                                    icon={ch.icon}
                                    component={<Link to={ch.action} />}
                                  >
                                    {" "}
                                    {ch.text}{" "}
                                  </MenuItem>
                                </>
                              ))}
                            </SubMenu>
                          </>
                        ) : (
                          <>
                            <MenuItem
                              icon={nav.icon}
                              component={<Link to={nav.action} />}
                            >
                              {" "}
                              {nav.text}{" "}
                            </MenuItem>
                          </>
                        )}
                      </>
                    ))}
                  </>
                )}
              </>
            ))}
          </Menu>
        </Sidebar>

        <main>
          <button className="collapseSidebar" onClick={() => collapseSidebar()}>
            <MenuIcon />
          </button>
        </main>

        <div className="container">
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          {/* <Card> */}
          <div className="m-2 card " style={{ minHeight: "90vh" }}>
            <div className="p-3" style={{ minHeight: "90vh" }}>
              <Outlet />
            </div>
          </div>
          {/* </Card> */}
        </div>
      </div>
    </>
  );
};

export default Dash;
