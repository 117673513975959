import DashboardIcon from "@mui/icons-material/Dashboard";
// import CategoryIcon from '@mui/icons-material/Category';
import MenuBookIcon from "@mui/icons-material/MenuBook";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddTaskIcon from "@mui/icons-material/AddTask";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import GroupIcon from "@mui/icons-material/Group";
import ListIcon from "@mui/icons-material/List";
import School from "@mui/icons-material/School";
import SupervisedUserCircle from "@mui/icons-material/SupervisedUserCircle";
import BorderHorizontalSharpIcon from "@mui/icons-material/BorderHorizontalSharp";
import AssessmentIcon from "@mui/icons-material/Assessment";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SettingsIcon from "@mui/icons-material/Settings";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";

export const sidebar = [
  {
    UserType: "SA",
    items: [
      {
        icon: <DashboardIcon />,
        text: "Dashboard",
        action: "/admin",
      },
      // {
      //     "icon": <CategoryIcon/>,
      //     "text": "Exam Set",
      //     "action": "/home/exam",
      //     "children": [
      //         {
      //             "icon": <AddCircleIcon/>,
      //             "text": "Exam Type",
      //             "action": "/admin/type"
      //         },
      //         {
      //             "icon": <AddCircleIcon />,
      //             "text": "Exam Name",
      //             "action": "/admin/name"
      //         }
      //     ]
      // },
      // {
      //     "icon": <AddBusinessIcon />,
      //     "text": "Exam Name",
      //     "action": "/home/exam",
      //     "children": [
      //         {
      //             "icon": <AddHomeIcon />,
      //             "text": "Add Exam Name",
      //             "action": "/admin/name"
      //         }
      //     ]
      // },
      {
        icon: <MenuBookIcon />,
        text: "Subject Set",
        action: "/home/exam",
        children: [
          {
            icon: <AddCircleIcon />,
            text: "Subjects",
            action: "/admin/subject",
          },
        ],
      },
      {
        icon: <AddTaskIcon />,
        text: "Manage Question",
        action: "/home/exam",
        children: [
          // {
          //     "icon": <AddCircleIcon />,
          //     "text": "Add Question",
          //     "action": "/admin/AddQuestion"
          // },
          {
            icon: <AddCircleIcon />,
            text: "View Questions",
            action: "/admin/questionsList",
          },
          {
            icon: <BorderHorizontalSharpIcon />,
            text: "Questions from Excel",
            action: "/admin/QuestionsFromExcel",
          },
        ],
      },
      {
        icon: <QuestionAnswerIcon />,
        text: "Paper Management",
        action: "/admin/questions_templates",
        children: [
          {
            icon: <AddCircleIcon />,
            text: "Create Paper",
            action: "/admin/createPaper",
          },
          {
            icon: <ListIcon />,
            text: "List of Papers",
            action: "/admin/PaperPage",
          },
          {
            icon: <ListIcon />,
            text: "Live Paper Results",
            action: "/admin/live-paper",
          },
        ],
      },
      // {
      //     "icon": <DynamicFormIcon />,
      //     "text": "Question Templates",
      //     "action": "/admin/questions_templates",
      //     "children": [
      //         {
      //             "icon": <AddCircleIcon />,
      //             "text": "Add Template",
      //             "action": "/admin/add__question_sets"
      //         },
      //         {
      //             "icon": <ListIcon />,
      //             "text": "List of Templates",
      //             "action": "/admin/templatesList"
      //         },
      //     ]
      // },
      {
        icon: <ListIcon />,
        text: "Users List",
        action: "/admin/questions_templates",
        children: [
          {
            icon: <AddCircleIcon />,
            text: "Add User",
            action: "/admin/addUsers",
          },
          {
            icon: <GroupIcon />,
            text: "List of Users",
            action: "/admin/listUsers",
          },
        ],
      },
      {
        icon: <School />,
        text: "Study Material",
        action: "/admin/StudyMaterial",
      },
      {
        icon: <AssessmentIcon />,
        text: "Reports",
        action: "/admin/Reports",
      },
      //  {
      //     "icon": <MenuBookIcon />,
      //     "text": "TestPaper",
      //     "action": "/admin/TestPaper",
      // },
      {
        icon: <SupervisedUserCircle />,
        text: "App users",
        action: "/admin/app-users",
        children: [
          {
            icon: <GroupIcon />,
            text: "User List",
            action: "/admin/app-users",
          },
        ],
      },
      {
        icon: <SettingsIcon />,
        text: "Masters",
        action: "/admin/questions_templates",
        children: [
          {
            icon: <MilitaryTechIcon />,
            text: "User Medals",
            action: "/admin/pointsMaster",
          },
          {
            icon: <GroupIcon />,
            text: "App Package",
            action: "/admin/AppPackages",
          },
          {
            icon: <WorkspacePremiumIcon />,
            text: "App Rewards",
            action: "/admin/RewardMaster",
          },
        ],
      },
      {
        icon: <SettingsIcon />,
        text: "Notifications",
        action: "/admin/notifications",
        children: [
          {
            icon: <MilitaryTechIcon />,
            text: "Push Notification",
            action: "/admin/notifications/push",
          },
        ],
      },

      {
        icon: <SettingsIcon />,
        text: "Newsletter",
        action: "/admin/newsletter",
        children: [
          {
            icon: <MilitaryTechIcon />,
            text: "Subscribers",
            action: "/admin/newsletter/subscribers",
          },
        ],
      },
      {
        icon: <SettingsIcon />,
        text: "Subscribers",
        action: "/admin/app_user",
        children: [
          {
            icon: <MilitaryTechIcon />,
            text: "AppUser Subscribers",
            action: "/admin/app_user/subscribers",
          },
        ],
      },
    ],
  },

  {
    UserType: "Admin",
    items: [
      {
        icon: <DashboardIcon />,
        text: "Dashboard",
        action: "/admin",
      },

      {
        icon: <MenuBookIcon />,
        text: "Subject Set",
        action: "/home/exam",
        children: [
          {
            icon: <AddCircleIcon />,
            text: "Subjects",
            action: "/admin/subject",
          },
        ],
      },
      {
        icon: <AddTaskIcon />,
        text: "Manage Question",
        action: "/home/exam",
        children: [
          {
            icon: <AddCircleIcon />,
            text: "View Questions",
            action: "/admin/questionsList",
          },
          {
            icon: <BorderHorizontalSharpIcon />,
            text: "Questions from Excel",
            action: "/admin/QuestionsFromExcel",
          },
        ],
      },
      {
        icon: <QuestionAnswerIcon />,
        text: "Paper Management",
        action: "/admin/questions_templates",
        children: [
          {
            icon: <AddCircleIcon />,
            text: "Create Paper",
            action: "/admin/createPaper",
          },
          {
            icon: <ListIcon />,
            text: "List of Papers",
            action: "/admin/PaperPage",
          },
        ],
      },
      {
        icon: <DynamicFormIcon />,
        text: "Question Templates",
        action: "/admin/questions_templates",
        children: [
          {
            icon: <AddCircleIcon />,
            text: "Add Template",
            action: "/admin/add__question_sets",
          },
          {
            icon: <ListIcon />,
            text: "List of Templates",
            action: "/admin/templatesList",
          },
        ],
      },
      {
        icon: <School />,
        text: "Study Material",
        action: "/admin/StudyMaterial",
      },
      {
        icon: <AssessmentIcon />,
        text: "Reports",
        action: "/admin/Reports",
      },
      //  {
      //     "icon": <MenuBookIcon />,
      //     "text": "TestPaper",
      //     "action": "/admin/TestPaper",
      // },
      {
        icon: <SupervisedUserCircle />,
        text: "App users",
        action: "/admin/appUsers",
      },
      {
        icon: <SettingsIcon />,
        text: "Masters",
        action: "/admin/questions_templates",
        children: [
          {
            icon: <MilitaryTechIcon />,
            text: "User Medals",
            action: "/admin/pointsMaster",
          },
          {
            icon: <GroupIcon />,
            text: "App Package",
            action: "/admin/AppPackages",
          },
          {
            icon: <WorkspacePremiumIcon />,
            text: "App Rewards",
            action: "/admin/RewardMaster",
          },
        ],
      },
    ],
  },

  // question contributors
  {
    UserType: "QC",
    items: [
      {
        icon: <DashboardIcon />,
        text: "Dashboard",
        action: "/admin",
      },

      {
        icon: <MenuBookIcon />,
        text: "Subject Set",
        action: "/home/exam",
        children: [
          {
            icon: <AddCircleIcon />,
            text: "Subjects",
            action: "/admin/subject",
          },
        ],
      },
      {
        icon: <AddTaskIcon />,
        text: "Manage Question",
        action: "/home/exam",
        children: [
          {
            icon: <AddCircleIcon />,
            text: "View Questions",
            action: "/admin/questionsList",
          },
          {
            icon: <BorderHorizontalSharpIcon />,
            text: "Questions from Excel",
            action: "/admin/QuestionsFromExcel",
          },
          // {
          //     "icon": <BorderHorizontalSharpIcon />,
          //     "text": "Add Maths Questions ",
          //     "action": "/admin/AddQuestionMaths"
          // }
        ],
      },
    ],
  },
];
