import React, { useState, useEffect, useRef } from 'react'
import ViewListIcon from '@mui/icons-material/ViewList';
import { useNavigate, useParams } from "react-router-dom"
import SyllabusModal from '../pannel/syllabusModal';
// import Button from '@mui/material/Button';
import FolderIcon from '@mui/icons-material/Folder';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import api from '../../API/api';
import { Paper } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Dropdown from '../pannel/dropdown';
import SortBy from '../pannel/Layout';
import DialogModal from '../modal/modal';
import MoveFolder from '../component/moveFolder';
import CustomizedMenus from '../pannel/dropdown';



const AddSyllabus = () => {
    const navigation = useNavigate();
    const rightClickMenu = useRef(null);
    // let checkval = useParams()
    const id = useParams('id');
    const subName = useParams('sub');
    const selfId = useParams('selfId');

    const [openMove, setOpenMove] = useState(false);
    const [selectId, setSelectId] = useState();

    const [files, setFiles] = useState([]);
    const [loader, setLoader] = useState(false);
    const [copy, setCopy] = useState(false);
    const [gridFilter, setGridFilter] = useState(localStorage.getItem('grid') || 2);
    const [orderBy, setOrderBy] = useState(localStorage.getItem('orderBy') || 1);
    const [sortBy, setSortBy] = useState(localStorage.getItem('sortby') || 1);

    // console.log(typeof (orderBy), typeof (sortBy), 'sortBysortBysortBy');
    const handleClose = () => {
        setLoader(false);
    };
    const handleOpen = () => {
        setLoader(true);
    };
    const handleCloseMove = () => {
        setOpenMove(false);
    }

    useEffect(() => {
        // console.log("id=>>", id.id);
        // console.log("subName=>>", subName.sub);
        // console.log("selfId=>>", selfId);
        getSyllabusById();
        // sortByAcsending();

    }, [id.id, selfId.selfId]);

    const getSyllabusById = () => {
        // setLoader(true);
        api.getSyllabusById(id.id, selfId.selfId).then((res) => {
            console.log(res);
            setFiles(res.data.files);
            setLoader(false)
        })
            .catch((err) => {
                console.log(err.response);
            })
    }

    const [isActive, setIsActive] = useState(false);

    const handleClick = (e, sub) => {
        // console.log(e);
        // console.log(sub);
        // return;
        switch (e.detail) {
            case 1:
                // console.log(sub.type);
                setIsActive(sub);
                break;
            case 2:
                console.log("double click");
                if (sub.type === 'folder') {
                    navigation(`/admin/subject/${subName.sub}/${id.id}/${sub.id}`)
                }
                else {
                    // navigation(`/admin/AddQuestion/${subName.sub}/${id.id}/${sub.id}`)
                    navigation(`/admin/viewQuestion/${subName.sub}/${id.id}/${sub.id}`)

                }
                break;
            case 3:
                console.log("triple click");
                break;
        }
    };

    const sortByName = (evt) => {
        if (evt === 1) {
            setSortBy(1);
            setFiles(files.sort(function (a, b) {
                return new Date(b.created_at) - new Date(a.created_at);
            }));
            localStorage.setItem('sortby', 1);
        } else if (evt === 2) {
            setFiles(files.sort(function (a, b) {
                return a.syllabus.localeCompare(b.syllabus);
            }));
            setSortBy(2)
            localStorage.setItem('sortby', 2);
        } else {

        }
    }
    const handleLayoutFilter = (obj) => {
        if (obj == 1) {
            setGridFilter(1);
            localStorage.setItem(
                'grid', 1
            )
            // console.log(localStorage.getItem('grid') ,'cn');
        } else {
            setGridFilter(2);
            localStorage.setItem(
                'grid', 2
            )
        }

    }
    const sortByAcsending = (evt) => {
        if (sortBy == localStorage.getItem('sortby')) {
            if (evt == localStorage.getItem('orderBy')) {
                setFiles(files.sort(function (a, b) {
                    return new Date(b.created_at) - new Date(a.created_at);
                }));
                setLoader(false)
                setOrderBy(1)
                localStorage.setItem('orderBy', 1)
            } else if (evt == localStorage.getItem('orderBy')) {
                setFiles(files.sort(function (a, b) {
                    return new Date(a.created_at) - new Date(b.created_at);
                }));
                setLoader(false)
                setOrderBy(2)
                localStorage.setItem('orderBy', 2)
            } else {

            }
        } else {
            if (evt == localStorage.getItem('orderBy')) {
                setFiles(files.sort(function (a, b) {
                    return a.syllabus.localeCompare(b.syllabus);
                }));
                setLoader(false)
                setOrderBy(1)
                localStorage.setItem('orderBy', 1)
            } else if (evt == localStorage.getItem('orderBy')) {
                setFiles(files.sort(function (a, b) {
                    return b.syllabus.localeCompare(a.syllabus);
                }));
                setLoader(false)
                setOrderBy(2)
                localStorage.setItem('orderBy', 2)
            } else {

            }
        }
    }

    const handleMenuRight = () => {
        // console.log('new');
        rightClickMenu.current.handleClick2();
            // return (
            //     <CustomizedMenus />
            // );
    }

    // console.log(copy && copy, 'gridFilter');
    return (
        <>
            <div className='space-between'>
                <div>
                    <button className='btn btn-success btn-sm' onClick={() => navigation(-1)}> Back </button>
                </div>
                <div>
                    <strong> <h3> {subName.sub} </h3> </strong>
                </div>
                <div>
                    <div className='d-flex'>
                        <div>
                            <small>Sort By</small>
                            <SortBy name={'Sort By'} setGridFilter={setGridFilter} sortByName={sortByName} sortByAcsending={sortByAcsending} orderBy={orderBy} sortBy={sortBy} />
                        </div>
                        <div className='d-block mx-2'>
                            <small>Layout</small>
                            <div>
                                <button title='Show as grid' type='button' className='m-1 text-primary filter-btn' onClick={() => handleLayoutFilter(1)}><AppsIcon /></button>
                                <button title='Show as List' type='button' className='text-primary filter-btn' onClick={() => handleLayoutFilter(2)}><ViewListIcon /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div className="row">
                <div className="col-12">
                    <SyllabusModal id={id.id} selfId={selfId.selfId} getSyllabusById={getSyllabusById} />
                </div>
            </div>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader} onClick={handleClose} >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className='folder-stucture'>
                <div className="row">
                    {files.length !== 0 ?
                        <>
                            {
                                files.map((file) => (
                                    <div className={gridFilter && gridFilter == 1 ? 'col-md-2 width-grid' : ''} key={file.id} >
                                        <div className={`fileOne one ${isActive == file ? "bg-active" : 'file'}`} onClick={e => handleClick(e, file)}>
                                            <div
                                                className={`fileTwo p-2 m-1 ${isActive == file ? "bg-active" : 'file'}`}
                                                onContextMenu={(e) => {
                                                    e.preventDefault();
                                                    handleMenuRight()
                                                }}
                                            >
                                                {file.type === 'folder' ?
                                                    < >
                                                        <FolderIcon style={{ fontSize: gridFilter == true ? 100 : 25 }} color="primary" />
                                                        <strong className={`${gridFilter == true ? 'folderTextGrid' : ''}`}>  {file.syllabus} </strong>
                                                        <span className='float-ends'> <Dropdown id={file.id} getSyllabusById={getSyllabusById} data={file} selfId={selfId} fId={id} setOpenMove={setOpenMove} setSelectId={setSelectId} setCopy={setCopy} ref={rightClickMenu} /> </span>
                                                    </>
                                                    :
                                                    <>
                                                        <NoteAltIcon style={{ fontSize: gridFilter == true ? 100 : 25 }} color="disabled" fontSize={gridFilter && gridFilter === true ? 'large' : ''} />
                                                        <strong className={`${gridFilter == true ? 'folderTextGrid' : ''}`}>  {file.syllabus} </strong>
                                                        <span className='float-ends'> <Dropdown id={file.id} getSyllabusById={getSyllabusById} data={file} selfId={selfId} fId={id} setOpenMove={setOpenMove} setSelectId={setSelectId} setCopy={setCopy} ref={rightClickMenu} /> </span>
                                                    </>
                                                }
                                            </div>

                                        </div>

                                    </div>
                                ))}
                        </>
                        :
                        <div className="row">
                            <div className="col-12">
                                <Paper>
                                    <div className='p-3'>
                                        <div className="d-flex justify-content-center align-items-center empty-file">
                                            <div className="row">
                                                <div className='text-center text-secondary'>
                                                    <b>No Data Found</b>
                                                </div>
                                                <div className='text-canter d-flex justify-content-center'>
                                                    <SyllabusModal id={id.id} selfId={selfId.selfId} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <DialogModal
                form={<MoveFolder
                    open={true}
                    selfId={selfId}
                    fId={id}
                    selectId={selectId}
                    setOpenMove={setOpenMove}
                    copy={copy}
                    getSyllabusById={getSyllabusById}
                />}
                open={openMove} handleCloseMove={handleCloseMove} setOpenMove={setOpenMove}
            />


        </>
    )
}

export default AddSyllabus;