import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

/**
 * Table Cell Generator
 *
 * @param {object} params
 * @param {import('./table_jsdoc_types').TableCellGeneratorParam} params.tableCells
 */
const TableCellGenerator = ({ tableCells }) => {
  return tableCells.map((tableCell) => (
    <CustomTableCell key={tableCell.id} tableCell={tableCell} />
  ));
};

export const CustomTableCell = ({ tableCell }) => {
  if (tableCell.editable) {
    if (tableCell.editType === "select") {
      return (
        <TableCell
          sx={{
            minWidth: tableCell.minWidth ? tableCell.minWidth : 100,
            maxWidth: tableCell.maxWidth ? tableCell.maxWidth : 100,
            paddingY: 2,
          }}
          className={tableCell.className}
          align={tableCell.align}
        >
          <TextField
            label={tableCell.label}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            select
            onChange={(e) => {
              tableCell.onChange(e);
            }}
          >
            {tableCell.selectOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </TableCell>
      );
    }

    return (
      <TableCell
        sx={{
          minWidth: tableCell.minWidth ? tableCell.minWidth : 100,
          maxWidth: tableCell.maxWidth ? tableCell.maxWidth : 100,
        }}
        align={tableCell.align}
      >
        <TextField
          label={tableCell.label}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          type={tableCell.numeric ? "number" : "text"}
          fullWidth
          onChange={(e) => tableCell.onChange(e)}
        />
      </TableCell>
    );
  }

  return (
    <TableCell
      sx={{
        minWidth: tableCell.minWidth ? tableCell.minWidth : 100,
        maxWidth: tableCell.maxWidth ? tableCell.maxWidth : 100,
        ...tableCell.sx,
      }}
      className={tableCell.className}
      align={tableCell.align}
    >
      {tableCell.label}
    </TableCell>
  );
};

export default TableCellGenerator;
