import { configureStore } from '@reduxjs/toolkit'

// import rootReducers from "./reducer";
import  QuestionFetchSLice  from './reducer/fetchQuestions';

export const store = configureStore({
    reducer : {
        QuestionFetchSLice : QuestionFetchSLice
    }
});

export default store;