import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Divider } from "@mui/material";

import "mathlive";
// import "//unpkg.com/mathlive";
// import { latexToMarkup } from 'mathlive';

const htmlToFormattedText = require("html-to-formatted-text");
export default function PreviewQuestion(props) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  console.log("graphicAns", props.wrongAns);
  useEffect(() => {
    if (props.open == true) {
      handleClickOpen();
    } else {
      handleClose();
    }
  }, [props.open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.cancelPreview();
    setOpen(false);
  };

  const handleSave = () => {
    console.log(props.questionForm);
    // return;
    props.saveQuestion(props.questionForm, props.wrongAns);
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Preview the question"}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            <div className="row mb-2">
              <strong>
                <u>Question:</u>
              </strong>
              <div
                dangerouslySetInnerHTML={{
                  __html: props.questionForm.question,
                }}
              />
            </div>

            <div className="row mb-2">
              <strong>
                <u>Answers :</u>
              </strong>

              {props.questionForm.ans_type &&
              props.questionForm.ans_type === "1" ? (
                <div className="row">
                  <div className="col-6 text-success">
                    <strong>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: props.questionForm.right_ans,
                        }}
                      />
                    </strong>
                  </div>

                  {props.wrongAns &&
                    props.wrongAns.map((w, index) => (
                      <>
                        <div className="col-6 text-danger">
                          {/* {index + 2})  */}
                          <div dangerouslySetInnerHTML={{ __html: w }} />
                          {/* {htmlToFormattedText(w)} */}
                        </div>
                      </>
                    ))}
                </div>
              ) : (
                <div>
                  <>
                    <div className="row  justify-content-center">
                      {props.graphicAns &&
                        props.graphicAns.map((preview, index) => (
                          <>
                            {preview.preview && (
                              <div className={`col-2`}>
                                <div>
                                  <div
                                    className={`questionPreview border ${
                                      preview.type === 1
                                        ? "border-success"
                                        : "border-danger"
                                    } `}
                                  >
                                    <img
                                      src={preview.preview}
                                      className="col-12"
                                      alt=""
                                    />
                                  </div>
                                  <div className="text-center">
                                    {/* {preview.type === '1' ?
                                                                        <span className='text-success'>Right Answer</span> : <span className='text-danger'>Wrong Answer</span>
                                                                    } */}
                                    <span className="text-success">
                                      {(index + 10).toString(36).toUpperCase()}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                    </div>
                  </>
                </div>
              )}

              {props.questionForm.ans_type &&
                props.questionForm.ans_type === "3" && (
                  <div className="row">
                    <div className="col-6 text-success">
                      <strong>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: props.questionForm.right_ans,
                          }}
                        />
                      </strong>
                    </div>

                    {props.wrongAns &&
                      props.wrongAns.map((w, index) => (
                        <>
                          <div className="col-6 text-danger">
                            {/* {index + 2})  */}
                            <div dangerouslySetInnerHTML={{ __html: w }} />
                            {/* {htmlToFormattedText(w)} */}
                          </div>
                        </>
                      ))}
                  </div>
                )}
            </div>

            {props.questionForm.ans_desc && (
              <div className="row mb-2">
                <strong>
                  <u>Description:</u>
                </strong>
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.questionForm.ans_desc,
                  }}
                />
              </div>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Edit
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            autoFocus
            disabled={props.backdroploader ? true : false}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
