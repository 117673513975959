import { Paper, Typography } from "@mui/material";

const SubscriptionPackage = ({ subscription }) => {
  return (
    <Paper sx={{ p: 2 }}>
      <Typography>Package ID: {subscription.package.sl}</Typography>
      <Typography>Package Name: {subscription.package.name}</Typography>
      <Typography>
        Package Price: {subscription.package.package_price}
      </Typography>
      <Typography>Description</Typography>
      {/* <Typography
        dangerouslySetInnerHTML={{ __html: subscription.package.description }}
      /> */}
    </Paper>
  );
};

export default SubscriptionPackage;
