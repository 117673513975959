import { Skeleton, TableCell, TableRow } from "@mui/material";
import React from "react";

const SkeletonRows = ({ tableHeads }) => {
  return (
    <>
      {[...Array(5)].map((_, index) => (
        <TableRow key={index}>
          {tableHeads.map((head) => (
            <TableCell
              sx={{
                minWidth: head.minWidth ? head.minWidth : "100px",
                maxWidth: head.maxWidth ? head.maxWidth : "100%",
                width: head.minWidth ? head.minWidth : "100px",
              }}
              key={head.id}
            >
              <Skeleton
                variant="text"
                height={40}
                sx={{
                  maxWidth: head.maxWidth ? head.maxWidth : "100%",
                }}
              />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

// Memoize the component if needed
const MemoizedSkeletonRows = React.memo(SkeletonRows);

export default MemoizedSkeletonRows;
