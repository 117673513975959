import {
  Modal,
  Box,
  Typography,
  Button,
  Stack,
  IconButton,
} from "@mui/material";
import AppUserInfo from "../app-user-info.page";
import CloseIcon from "@mui/icons-material/Close";

const UserDetailModal = ({ open, handleClose, user }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          width: {
            md: "80%",
            xs: "90%",
          },
          height: {
            md: "90%",
            xs: "90%",
          },
          //   overflow: "auto",
          mt: 0,
        }}
      >
        <IconButton
          onClick={handleClose}
          color="primary"
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Stack
          direction={"row"}
          spacing={2}
          flexGrow={1}
          justifyContent={"space-between"}
          overflow={"hidden"}
        >
          <Typography variant="h6" component="h2">
            User Detail
          </Typography>
        </Stack>
        <AppUserInfo user={user} />

        {/* <Button onClick={handleClose} color="primary">
          Close
        </Button> */}
      </Box>
    </Modal>
  );
};

export default UserDetailModal;
