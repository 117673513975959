import React, { useEffect, useState } from "react";
import api from "../../API/api";
import EditIcon from "@mui/icons-material/Edit";
import { Divider } from "@mui/material";
import { Button } from "@mui/material";
import CachedIcon from "@mui/icons-material/Cached";
import LinearProgress from "@mui/material/LinearProgress";
import Swal from 'sweetalert2';

const htmlToFormattedText = require("html-to-formatted-text");

export default function CreateLivePaper(props) {
  const [paper, setPaper] = useState();

  const [load, setLoad] = useState(false);

  useEffect(() => {
    generateLiveQuestions();
  }, []);

  const generateLiveQuestions = () => {
    setLoad(true);
    console.log(props.sections,'props.sections');
    api
      .generateLiveQuestions(props.sections)
      .then((res) => {
        console.log("res set", res);
        setPaper(res.data.papers);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err.response);
        setLoad(false);
      });
  };

  const handleClick = () => {};

  const handleChangeQuestion = (q_id, s_id) => {
    console.log(q_id, s_id);

    setLoad(true);

    setPaper(
      paper.map((e) => (e.q_id === q_id ? { ...e, changing: "red" } : e))
    );

    api
      .changeLiveQuestion(q_id, s_id)
      .then((res) => {
        console.log("res=>>", res.data.question);
        var newQuestion = res.data.question;
        newQuestion.changing = "green";
        setPaper(paper.map((e) => (e.q_id === q_id ? newQuestion : e)));
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };

  const savePaper = () => {
    setLoad(true);
    console.log(paper);
    console.log(props.formdata);
    var formdata = props.formdata;
    var questions = paper;

    var data = {
      syllabus: props.sections,
      questions: paper,
      formdata: props.formdata,
    };

    api
      .saveLiveQuestions(data)
      .then((res) => {
        console.log("res", res);
        Swal.fire({
            title: 'Live Paper Created',
            text: 'Your Paper is Generated',
        });
        setLoad(false);
        props.handleCloseMove()
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
        Swal.fire({
            title: 'Error',
            text: 'Something you missed',
        });
      });
  };

  return (
    <>
      {/* <div>CreateLivePaper</div> */}

      <div className="Question-paper m-3 pt-4 mx-5">
        {/* <h3 className='text-center'>{location.state.paperName}</h3> */}
        <div>
          <div className="syllabus">
            <div className="headers">
              <p>
                <strong>Instruction : </strong>
                <div>
                  {/* Syllabi included on this Paper are - {syllabus && syllabus.map((s) => (<> {s}, </>))} */}
                </div>
                <div>Every Refresh will Randomize the Questions.</div>
              </p>
            </div>
            <div>
              <div className="space-between">
                <p>Total Questions : {paper?.length}</p>
                <p>Negative Marks : {props.formdata?.negative_marks}/Question</p>
              </div>
            </div>
            <div className="body">
              {paper &&
                paper.map((question, index) => (
                  <>
                    {/* <div className='headers'>
                                    <h5 className='my-4 text-center'>
                                        {sets[0] ? sets[0].syllabus : <span className='text-danger'>Question Not Found</span>}
                                    </h5>
                                </div> */}
                    <hr />
                    {/* {sets.map((question, index) => ( */}

                    <div
                      className={`row p-3 my-3 rounded  ${
                        question.changing === "red" && "bg-danger"
                      }  ${question.changing === "green" && "bg-light"} `}
                    >
                      <div className="Question">
                        <div>
                          <button
                            className="edit-btn mx-3 float-end"
                            onClick={() =>
                              handleChangeQuestion(
                                question.q_id,
                                question.syllabus_id
                              )
                            }
                          >
                            <CachedIcon fontSize={"small"} />
                          </button>
                        </div>
                        Question {index + 1}.
                        <strong>
                          {" "}
                          &nbsp;
                          {htmlToFormattedText(question.question)} |{" "}
                          {question.q_id}
                          {/* <span dangerouslySetInnerHTML={{ __html: question.question }} /> */}
                        </strong>
                      </div>
                      <div className="Answers">
                        <strong>Answer :</strong>
                        <div className="row">
                          {question.ans.map((ans, i) => (
                            <div className="col-md-6 p-2">
                              <button
                                className="btn"
                                onClick={() =>
                                  handleClick(
                                    question.q_id,
                                    ans.ans_id,
                                    ans.isRight
                                  )
                                }
                              >
                                {i + 1}) &nbsp;
                                {htmlToFormattedText(ans.answer)} | {ans.ans_id}{" "}
                                | {ans.isRight}
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                      <Divider />
                    </div>

                    {/* ))} */}
                  </>
                ))}
            </div>
          </div>
        </div>

        <div className="p-2 m-2 text-center">
          <Button
            variant="contained"
            onClick={savePaper}
            disabled={load ? true : false}
          >
            Save Paper
          </Button>
        </div>
      </div>

      {load && <LinearProgress />}
    </>
  );
}
