import React, { useState , useEffect} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Swal from 'sweetalert2';
import EditSections from './editSection';
import { useLocation } from 'react-router-dom';
import api from '../../API/api';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


function EditQuestionSets(props) {
    const location = useLocation();
    console.log(location, 'propsprops');
    console.log(props, 'propsprops');
    const [open, setOpen] = useState();
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#709dc9',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
    }));
    
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    }));
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [editTemplate , setEditTemplate] = useState({
        id                   : location.state.id,
        name                 : location.state.name,
        description          : location.state.description,
        tot_questions        : location.state.tot_questions,
        marks_per_question   : location.state.marks_per_question,
        negative_marks       : location.state.negative_marks,
    })
    const [templateSections , setTemplateSections ] = useState(location.state.sections);
    const [backdroploader, setBackdropLoader] = useState(false);
    const [subject, setSubject] = useState();
    const [syllabus, setSyllabus] = useState();
    const [countTotal , setcountTotal] = useState(0);
    const [editSections , setEditSections] = useState({
        subject_id       : '',
        syl_id           : '',
        sectionQuestions : ''
    });

    const handleTemplate = (e) => {
        setEditTemplate({
            ...editTemplate,
            [e.target.name]: e.target.value
        })
    }
    const handleDeleteSection = (row) => {
        console.log(row ,'row');
        setTemplateSections(current => current.filter(data => data.syl_id != row.syl_id));
        setcountTotal( prevstate =>  parseInt(prevstate) - parseInt(row.sectionQuestions))
        console.log(templateSections && templateSections ,'templateSections');
    }

    const getSubject = () => {
        setBackdropLoader(true);
        api.getSubject()
            .then((res) => {
                setBackdropLoader(false);
                console.log(res);
                setSubject(res.data.subject)
            })
            .catch((err) => {
                console.log(err.response);
            })
    }
    const handleChangeSubject = (evt) => {
        console.log(evt.target.value, 'evt');
        let index = evt.nativeEvent.target.selectedIndex;
        let getSubjectText =  evt.nativeEvent.target[index].text;
        setEditSections({
            ...editSections,
            subject_id  : evt.target.value,
            subject_name : getSubjectText
        })
        getSyllabus(evt.target.value);
    }

    const handleChangeSyllabus = (evt) => {
        console.log(evt.target.value, 'mjoikmnok');
        let index = evt.nativeEvent.target.selectedIndex;
        let getSubjectText =  evt.nativeEvent.target[index].text;
        setEditSections({
            ...editSections,
            syl_id  : evt.target.value,
            syllabus_name : getSubjectText
        })
    }

    const getSyllabus = (id) => {
        setBackdropLoader(true);
        api.getSyllabusBySub(id)
            .then((res) => {
                setBackdropLoader(false);
                console.log(res.data.syllabus,'logs');
                setSyllabus(res.data.syllabus);
            })
            .catch((err) => {
                console.log(err.response);
            })
    }
    const handleSectionQues = (evt) => {
        setEditSections({
            ...editSections,
            sectionQuestions  : evt.target.value.replace(/\D/g, ''),
        })
    }

    const handleSections = () => {
       const data ={
            sectionQuestions    :editSections.sectionQuestions,
            subName             :editSections.subject_name,
            sub_id              :editSections.subject_id,
            syl_id              :editSections.syl_id,
            syllabus            :editSections.syllabus_name
       }
       const objPresent = templateSections.find(obj =>obj.syl_id == editSections?.syl_id);
       if (Number(editSections?.sectionQuestions) > Number(editTemplate?.tot_questions)) {
            Swal.fire({  
                title: `section cannot be greater then  ${editTemplate.tot_questions}`,  
                text: 'Please check overall Questions mark ',
            });  
            }else {
                if(Number(editSections?.sectionQuestions) + countTotal > Number(editTemplate.tot_questions)){
                    Swal.fire({  
                        title: 'Limit Exists',  
                        text: `Question number cant exist beyond ${editTemplate.tot_questions}`,
                    });
                }else {
                    if (!objPresent) {
                        setTemplateSections(current => [...current , data ]);
                        // templateSections?.find(obj => {
                            setcountTotal( prevstate =>  parseInt(prevstate) + parseInt(editSections.sectionQuestions))
                        // })
                    }else {
                    Swal.fire({  
                        title: 'Syllabus Already exist',  
                        text: 'Please try another Syllabus ',
                    });     
                }
            }
       }
    }

    const handleSubmit = () => {
        console.log(editTemplate && editTemplate ,'editSections');
        console.log(templateSections && templateSections ,'templateSections');
    }

    useEffect(() => {
        getSubject();
    },[])
    useEffect(()=> {
        let newvar = 0;
        for (var i = 0; i< templateSections.length; i++){
            newvar += templateSections[i].sectionQuestions
        }
        console.log(newvar,'countTotal');
        setcountTotal(newvar)
    },[subject])
  

    return (
        <>
            {/* <Form> */}
            <div >
                <h4 className='text-center'>Edit Sections</h4>
                <div className='row'>
                    <Form.Group className="mb-3">
                        <Form.Label>Template Name</Form.Label>
                        <Form.Control type="text" name='name'  placeholder='Type Template Name' value={editTemplate.name} onChange={handleTemplate}   />
                        {props.error?.name ? <small style={{color : 'red'}}>{props.error.name}</small> : ''}
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-12" controlId="formBasicPassword">
                        <Form.Label>Description</Form.Label>
                        <Form.Control type="text" name='description'  placeholder="What this template is about" value={editTemplate.description} onChange={handleTemplate} />
                        {props.error?.description ? <small style={{color : 'red'}}>{props.error.description}</small> : ''}
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-3">
                        <Form.Label>Total Questions</Form.Label>
                        <Form.Control type="number" name='tot_questions'  placeholder='Enter Total Questions' value={editTemplate.tot_questions} onChange={handleTemplate} />
                        {props.error?.tot_questions ? <small style={{color : 'red'}}>{props.error.tot_questions}</small> : ''}
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-3">
                        <Form.Label>Marks Per Question</Form.Label>
                        <Form.Control type="number" name='marks_per_question'   placeholder='Enter Marks Per Question' value={editTemplate.marks_per_question} onChange={handleTemplate} />
                        {props.error?.marks_per_question ? <small style={{color : 'red'}}>{props.error.marks_per_question}</small> : ''}
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-3">
                        <Form.Label>Negative Marks per Question</Form.Label>
                        <Form.Control type="number" name='negative_marks'  placeholder='Enter Negative Marks per Question' value={editTemplate.negative_marks} onChange={handleTemplate} />
                        {/* {props.error?.marks_per_question ? <small style={{color : 'red'}}>{props.error.marks_per_question}</small> : ''} */}
                    </Form.Group>
                    <Form.Group className="mb-3 col-md-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Sections</Form.Label>
                        <Form.Control type="number" defaultValue={4} />
                    </Form.Group>
                    <Form.Group className="col-md-3 mb-5 mt-3" controlId="exampleForm.ControlInput1">
                        <Form.Label> Select Subject</Form.Label>
                        <Form.Select   name='subject_id'  onChange={handleChangeSubject}  value={editSections.subject_id}>
                            <option value='' disabled>--select-- </option>
                            {subject && subject.map((sub) => (
                                <option   value={sub.id}>{sub.subName}</option>
                            ))}
                        </Form.Select>
                    {
                        props.error?.sectionArrayId ? <small style={{color : 'red'}}>{props.error.sectionArrayId}</small> : ''
                    }
                    </Form.Group>

                    <Form.Group className="col-md-3 mb-5 mt-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Select Syllabus</Form.Label>
                        <Form.Select name='syllabus_id' onChange={handleChangeSyllabus}  value={editSections.syl_id} >
                            <option value=''>--select-- </option>
                            {syllabus && syllabus.map((syl) => (
                                <option key={syl.id} value={syl.id}>{syl.syllabus}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="col-md-3 mb-5 mt-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Questions from this section</Form.Label>
                        <Form.Control type="text"   onChange={handleSectionQues} value={editSections.sectionQuestions} />
                    </Form.Group>
                    <Form.Group className="col-md-3 mb-5 mt-5">
                        <Button onClick={handleSections} variant="primary">+ Add Sections</Button>
                    </Form.Group>
                    {
                        templateSections?.length > 0 ?
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead className='bg-theme'>
                                <TableRow>
                                    <StyledTableCell>SL </StyledTableCell>
                                    <StyledTableCell align="left">Subjects</StyledTableCell>
                                    <StyledTableCell align="left">Syllabus</StyledTableCell>
                                    <StyledTableCell align="left">Questions</StyledTableCell>
                                    <StyledTableCell align="left">Action</StyledTableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                    {templateSections.map((row ,index) => (
                                        <StyledTableRow key={index}>
                                        <StyledTableCell component="th" scope="row">
                                            {index + 1}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">{row.subName}</StyledTableCell>
                                        <StyledTableCell align="left">{row.syllabus}</StyledTableCell>
                                        <StyledTableCell align="left">{row.sectionQuestions}</StyledTableCell>
                                        <StyledTableCell align="left">
                                        {/* <button onClick={()=>props.handleSectionQuesDelete(row.id)} type="button">Delete</button> */}

                                        {/* <button  type="button" onClick={()=>handleEditModal(row)}>Edit</button> */}
                                        <button  type="button" onClick={()=>handleDeleteSection(row)}>Delete</button>
                                        </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                <TableRow>
                                    <TableCell rowSpan={3} />
                                    <TableCell colSpan={1}>Subtotal</TableCell>
                                    <TableCell align="right">{countTotal}</TableCell>
                                </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        : ''
                    }
                    {
                        props.error?.count ? <small style={{color : 'red'}}>{props.error.count}</small> : ''
                    }
                    <div className='text-center'>
                        <Button className='btn btn-primary mt-5 btn-block' variant='primary' onClick={handleSubmit} >Submit</Button>
                    </div>

                    {/* <Modal open={open}  onClose={handleClose}>
                        <Box sx={style}>
                            <div className='row'>
                                <Form.Group className="mb-3 col-md-12" controlId="formBasicPassword">
                                        <Form.Label> Select Subject</Form.Label>
                                        <Form.Select   name='subject_id' value={modalData?.sub_id} onChange={handleSubjectChange}  >
                                            <option value='' disabled>--select-- </option>
                                            {subject && subject.map((sub) => (
                                                <option   value={sub.id}>{sub.subName}</option>
                                            ))}
                                        </Form.Select>
                                    {
                                        props.error?.sectionArrayId ? <small style={{color : 'red'}}>{props.error.sectionArrayId}</small> : ''
                                    }

                                    <Form.Text className="text-danger">
                                        {props.sectionValueError && props.sectionValueError}
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3 col-md-12" controlId="formBasicPassword">
                                        <Form.Label> Select Syllabus</Form.Label>
                                        <Form.Select   name='subject_id' value={modalData?.syl_id}  >
                                            <option value='' disabled>--select-- </option>
                                            {syllabus && syllabus.map((sub) => (
                                                <option   value={sub.id}>{sub.syllabus}</option>
                                            ))}
                                        </Form.Select>
                                    {
                                        props.error?.sectionArrayId ? <small style={{color : 'red'}}>{props.error.sectionArrayId}</small> : ''
                                    }

                                    <Form.Text className="text-danger">
                                        {props.sectionValueError && props.sectionValueError}
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3 col-md-12" controlId="formBasicPassword">
                                    <Form.Label>No. of Questions</Form.Label>
                                    <Form.Control type="text" placeholder="What this template is about" value={modalData?.syllabus}  onChange={props.handleSection} />
                                    <Form.Text className="text-danger">
                                        {props.sectionValueError && props.sectionValueError}
                                    </Form.Text>
                                </Form.Group>
                                <Button className='col-12' onClick={props.addSection} variant="primary">Submit Section</Button>
                            </div>
                        </Box>
                    </Modal> */}
                    <EditSections handleSectionEdit={props.handleSectionEdit} openEditModal={props.openEditModal} setOpenEditModel={props.setOpenEditModel} closeEditSection={props.closeEditSection} selectedSection={props.selectedSection} />
                </div>
            </div>
        </>
    );

}

export default EditQuestionSets;