import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../API/api";
import LoadingSpinner from "./loading-spinner";
import { toast } from "react-toastify";

const UserDetailGeneral = ({ user }) => {
  const [loading, setLoading] = useState(true);

  const [totalStats, setTotalStats] = useState({});

  const getTotalStats = () => {
    setLoading(true);
    api
      .appUsersTotalStats(user.id)
      .then((res) => {
        if (res.data.success) {
          setTotalStats(res.data);
        } else {
          toast.error(res.data.message);
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getTotalStats();
  }, [user]);

  return (
    <>
      <Stack
        direction={"column"}
        spacing={2}
        mt={2}
        bgcolor={"#f0f0f0"}
        borderRadius={2}
        color={"#424242"}
        p={2}
      >
        <Typography variant="h6">General</Typography>
        <Stack direction={"row"} spacing={2}>
          <Typography variant="body1">Name</Typography>
          <Typography variant="body1">
            {user.f_name} {user.l_name}
          </Typography>
        </Stack>
      </Stack>

      <Stack
        direction={"column"}
        spacing={2}
        mt={2}
        bgcolor={"#f0f0f0"}
        borderRadius={2}
        color={"#424242"}
        p={2}
      >
        {loading && <LoadingSpinner />}
        {!loading && (
          <Stack direction={"column"} spacing={2}>
            <Typography variant="body1">
              Total Papers Attempted: {totalStats?.total_papers_attempted}
            </Typography>
            <Typography variant="body1">
              Total Questions Attempted: {totalStats?.total_attempted}
            </Typography>
            <Typography variant="body1">
              Total Right Answers: {totalStats?.total_correct}
            </Typography>
            <Typography variant="body1">
              Total Wrong Answers: {totalStats?.total_wrong}
            </Typography>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default UserDetailGeneral;
