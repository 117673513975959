import { Stack, IconButton, Typography } from "@mui/material";
import {
  FirstPage,
  LastPage,
  NavigateBefore,
  NavigateNext,
} from "@mui/icons-material";

const UserPurchacesPagination = ({ paginationOptions, onPageChange }) => {
  const { current_page, total, last_page, per_page } = paginationOptions;

  const handleFirstPage = () => onPageChange(1);
  const handlePreviousPage = () => onPageChange(current_page - 1);
  const handleNextPage = () => onPageChange(current_page + 1);
  const handleLastPage = () => onPageChange(last_page);

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="center"
    >
      <IconButton onClick={handleFirstPage} disabled={current_page === 1}>
        <FirstPage />
      </IconButton>
      <IconButton onClick={handlePreviousPage} disabled={current_page === 1}>
        <NavigateBefore />
      </IconButton>
      <Typography variant="body2">
        {current_page} of {last_page}
      </Typography>
      <IconButton
        onClick={handleNextPage}
        disabled={current_page === last_page}
      >
        <NavigateNext />
      </IconButton>
      <IconButton
        onClick={handleLastPage}
        disabled={current_page === last_page}
      >
        <LastPage />
      </IconButton>
    </Stack>
  );
};

export default UserPurchacesPagination;
