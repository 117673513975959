import React, { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from 'react-router-dom';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import Swal from 'sweetalert2';
import api from '../../API/api';
import AuthUser from '../../API/token';
const MenuButton = (props) => {

  const { user } = AuthUser();

  // console.log(props , 'button props');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const importQuestion = (questionForm) => {
    handleClose();
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Import it!'
    }).then((result) => {
      if (result.isConfirmed) {
        confirmImportQuestion(questionForm)
      }
      else {
        return;
      }
    })
  }

  const confirmImportQuestion = (questionForm) => {

    console.log(questionForm);
    var tags = [questionForm.w_ans1, questionForm.w_ans2, questionForm.w_ans3, questionForm.w_ans4];

    if (!tags.length) {
      Swal.fire({
        title: 'Opss',
        text: 'Add Some Wrong Answers before Import',
        icon: 'error'
      });
      return;
    }
    if (!questionForm.right_ans) {
      Swal.fire({
        title: 'Opss',
        text: 'Please Update Right Answer',
        icon: 'error'
      });
      return;
    }
    if (!questionForm.subject_id) {
      console.log("No Subject Id");
      Swal.fire({
        title: 'Opss',
        text: 'Please Update Subject',
        icon: 'error'
      });
      return;
    }
    if (!questionForm.syllabus_id) {
      console.log("No syllabus Id");
      Swal.fire({
        title: 'Opss',
        text: 'Please Update Syllabus',
        icon: 'error'
      });
      return;
    }


    api.EditExelQuestion(questionForm, tags, 'import',user?.id).then((res) => {
      console.log(questionForm, tags, 'import',user?.id, 'chekcss');
      return;
      if (res.data.status == 200) {
        props.getExelQuestion();
        Swal.fire({
          title: 'Imported',
          text: 'Question Data Updated And Imported',
          icon: 'success'
        });
      }
      if (res.data.status == 500) {
        Swal.fire({
          title: 'Not allow',
          text: 'This question is already Imported',
          icon: 'warning'
        });
      }
    })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: 'Opss',
          text: 'Something went wrong ! ',
          icon: 'error'
        });
      })


  }


  return (
    <>
      <div>
        <Button
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          size='small'
          onClick={(e) => handleClick(e, props.row)}
        >
          <ExpandCircleDownIcon />
        </Button>

        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {/* <MenuItem onClick={() => navigate(`/admin/EditExcelQuestions`, { state: props.row })} disableRipple>
            <EditIcon />
            Edit Question
          </MenuItem> */}
          <MenuItem onClick={() => importQuestion(props.row)} disableRipple>
            <SendIcon />
            Import to Questions
          </MenuItem>
        </StyledMenu>
      </div>

    </>
  )
}
export default MenuButton;





const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '89%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll'
};