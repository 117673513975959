import React, { forwardRef, useImperativeHandle } from 'react';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AuthUser from '../../API/token'
import DeleteIcon from '@mui/icons-material/Delete';

const htmlToFormattedText = require("html-to-formatted-text");

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


const MenuAction = forwardRef((props, ref) => {

  const { user } = AuthUser();
  const [anchorEl, setAnchorEl] = React.useState(null);


  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useImperativeHandle(ref, () => ({
    handleClose() {
      setAnchorEl(null);
    },
    handleClick(event) {
      setAnchorEl(event.currentTarget);
    },
  }));


  const handleApprove = () => {
    props.handleApprove(props.data)
    handleClose();
  }



  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>

      <div className="text-center">

        <Stack spacing={1} className='text-center'>
          <IconButton aria-label="more" aria-describedby={id} onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        </Stack>
      </div>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>

        {user?.role == 'SA' &&
          <MenuItem key={2} onClick={() => handleApprove()} >
            <FileCopyIcon />
            {props?.data.isApprove == 1 ? 'Make Draft' : 'Approve'}

          </MenuItem>
        }

        <MenuItem disabled={props?.data.type === 4 ? true : false} onClick={() => props.handleClickEdit(props.data)} >
          <EditIcon />Edit
        </MenuItem>
        <MenuItem onClick={() => props.handleDeleteModal(props.data)} >
          <DeleteIcon />Delete
        </MenuItem>

      </StyledMenu>
    </div>
  );
});

export default MenuAction;