import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form';
import { Paper } from '@mui/material';
import { Button } from '@mui/material';
import RichText from '../pannel/richtext';
import api from '../../API/api';
import Swal from 'sweetalert2';

function ExcelEditQusetions(props) {

    const [input, setInput] = useState('');
    const [tags, setTags] = useState([
        props.questionForm?.w_ans1, props.questionForm?.w_ans2, props.questionForm?.w_ans3, props.questionForm?.w_ans4
    ]);

    const onChange = (e) => {
        const { value } = e.target;
        setInput(value);
    };

    const onKeyDown = (e) => {
        const { key } = e;
        const trimmedInput = input.trim();

        if (key === 'Enter' && trimmedInput.length && !tags.includes(trimmedInput)) {
            e.preventDefault();
            setTags(prevState => [...prevState, trimmedInput]);
            setInput('');
        }
    };

    const handleRemoveAns = (tag) => {
        console.log(tag);
        let filterData = tags.filter(e =>
            e !== tag
        )
        setTags(filterData);
    }

    const handleSubmit = () => {
        saveQuestion(tags)
    }

    const saveQuestion = (wrongAns) => {
        api.EditExelQuestion(props.questionForm, wrongAns, 'draft').then((res) => {
            console.log(res);
            if (res.data.status == 200) {
                Swal.fire({
                    title: 'Updated',
                    text: 'Exel Question Updated Successfully ',
                    icon: 'success'
                });
            }
            else {
                Swal.fire({
                    title: 'Opss',
                    text: 'Something went wrong ! ',
                    icon: 'error'
                });
            }

        })
            .catch((err) => {
                console.log(err.response);
            })
    }

    const saveAndImport = () => {

        if (!tags.length) {
            Swal.fire({
                title: 'Opss',
                text: 'Add Some Wrong Answers before Import',
                icon: 'error'
            });
            return;
        }
        if (!props.questionForm.right_ans) {
            Swal.fire({
                title: 'Opss',
                text: 'Please Update Right Answer',
                icon: 'error'
            });
            return;
        }
        if (!props.questionForm.subject_id) {
            console.log("No Subject Id");
            Swal.fire({
                title: 'Opss',
                text: 'Please Select Subject',
                icon: 'error'
            });
            return;
        }
        if (!props.questionForm.syllabus_id) {
            console.log("No syllabus Id");
            Swal.fire({
                title: 'Opss',
                text: 'Please Select Syllabus',
                icon: 'error'
            });
            return;
        }

        api.EditExelQuestion(props.questionForm, tags, 'import').then((res) => {
            if (res.data.status == 200) {
                Swal.fire({
                    title: 'Imported',
                    text: 'Question Data Updated And Imported ',
                    icon: 'success'
                });
            }
            if (res.data.status == 500) {
                Swal.fire({
                    title: 'Not allow',
                    text: 'This question is already Imported',
                    icon: 'warning'
                });
            }
        })
            .catch((err) => {
                console.log("err", err.response);
                Swal.fire({
                    title: 'Opss',
                    text: 'Something went wrong ! ',
                    icon: 'error'
                });
            })
    }

    return (
        <>
            <div className="row">
                <Form.Group className="col-md-3 mb-3 mt-1" controlId="exampleForm.ControlInput1">
                    <Form.Label>Difficulties 2</Form.Label>
                    <Form.Select aria-label="Default select example" name='difficulties' onChange={props.handleForm} value={props.questionForm?.difficulties}>
                        <option value='' disabled>--select--  </option>
                        <option value='1'>Easy </option>
                        <option value='2'>Medium </option>
                        <option value='3'>Hard </option>
                    </Form.Select>
                </Form.Group>

                <Form.Group className="col-md-3 mb-3 mt-1" controlId="exampleForm.ControlInput1">
                    <Form.Label>Class</Form.Label>
                    <Form.Select aria-label="Default select example" name='class' onChange={props.handleForm} value={props.questionForm?.class}>
                    <option value=''>--select--  </option>
                        <option value='8'>Class 8 </option>
                        <option value='9'>Class 9 </option>
                        <option value='10'>Class 10 </option>
                        <option value='11'>Class 11 </option>
                        <option value='12'>Class 12 </option>
                        <option value='13'>UG</option>
                        <option value='14'>PG</option>
                    </Form.Select>
                </Form.Group>

                {/* {props.questionForm?.difficulties && */}
                <Form.Group className="col-md-3 mb-3 mt-1" controlId="exampleForm.ControlInput1">
                    <Form.Label> Select Subject</Form.Label>
                    <Form.Select name='subject_id' aria-label="Default select example" onChange={props.handleChangeSubject} value={props.questionForm?.subject_id}>
                        <option value=''>--select-- </option>
                        {props.subject && props.subject.map((sub) => (
                            <option value={sub.id}>{sub.subName}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
                {/* } */}

                {/* {props.questionForm?.subject_id && */}
                <Form.Group className="col-md-3 mb-3 mt-1" controlId="exampleForm.ControlInput1">
                    <Form.Label>Select Syllabus</Form.Label>
                    <Form.Select aria-label="Default select example" name='syllabus_id' onChange={props.handleForm} value={props.questionForm?.syllabus_id} >
                        <option value=''>--select-- </option>
                        {props.syllabus && props.syllabus.map((syl) => (
                            <option key={syl.id} value={syl.id}>{syl.syllabus}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
                {/* } */}

                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Enter Question</Form.Label>
                    <RichText name="question" value={props.questionForm?.question} handleForm={props.handleQuestion} />
                </Form.Group>

                {
                    props.questionForm &&
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Right Answer</Form.Label>
                        <RichText name="" value={props.questionForm?.right_ans} handleForm={props.handleRightAns} />
                    </Form.Group>
                }
                {props.questionForm &&
                    <Form.Group className="mb-5" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Answer Description</Form.Label>
                        <RichText name="" value={props.questionForm?.ans_desc} handleForm={props.handleAnsDesc} />
                    </Form.Group>
                }
                <Form.Group className="col-md-12 mb-5" controlId="exampleForm.ControlInput1">
                    <Form.Label>Wrong Answers ( <span className='text-danger'> Press Enter key for add more wrong answers, minimum 3 required </span> )</Form.Label>
                    <input
                        value={input}
                        className='form-control'
                        placeholder=""
                        onKeyDown={onKeyDown}
                        onChange={onChange}
                    />
                    {tags.map((tag, index) =>
                        <Paper>
                            <div className="tag" >
                                <div className='m-1 p-2'>
                                    {index + 1}) {tag}
                                    <span className='float-end'> <button className='btn btn-danger btn-sm' onClick={() => handleRemoveAns(tag)}> x </button></span>
                                </div>
                            </div>
                        </Paper>

                    )}
                </Form.Group>

                {/* } */}

                {tags &&
                    <div className='text-center'>
                        <button className='btn btn-sm btn-success me-3' onClick={handleSubmit}>Save Question</button>
                        <button className='btn btn-sm btn-success me-3' onClick={saveAndImport}>Submit And Import</button>
                    </div>
                }
            </div>

        </>
    );
}

export default ExcelEditQusetions;