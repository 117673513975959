import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button } from '@mui/material';
import MenuAll from '../pannel/dialogAll';
import { Chip } from '@mui/material';


function AppPackagesTable({ listPcgk, handleEdit, handleDisable }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  // const handleClick = (event, row) => {

  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  return (
    <TableContainer >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>sl no</TableCell>
            <TableCell>Name</TableCell>
            <TableCell align="center">Description</TableCell>
            <TableCell align="center">Number of Papers</TableCell>
            <TableCell align="center">Number of Syllabus</TableCell>
            <TableCell align="center">Package Amount</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listPcgk && listPcgk.map((row) => (
            <TableRow
              key={row.sl}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.sl}
              </TableCell>
              <TableCell component="th" scope="row" align="left">
                {row.name}
              </TableCell>
              <TableCell component="th" scope="row" align="center">
              <div dangerouslySetInnerHTML={{ __html: row.description }} />
                {/* {row.description} */}
              </TableCell>
              <TableCell align="right">{row.no_of_paper}</TableCell>
              <TableCell align="center">
                {row.no_of_syllabus}
              </TableCell>
              <TableCell align="center">
                {row.price}
              </TableCell>
              <TableCell align="center">
                {row.isActive === 1 ?
                  <Chip label="Active" color="success" />
                  :
                  <Chip label="Disable" color="error" />
                }
              </TableCell>
              <TableCell align="center">
                {/* <Button 
                    variant="contained"
                    size='small'
                    onClick={()=>handleEdit(row)}
                  >Edit</Button> */}
                <MenuAll
                  handleEdit={handleEdit}
                  data={row}
                  handleDisable={handleDisable}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default AppPackagesTable;