import React, { useState, useRef } from "react";
import Form from "react-bootstrap/Form";
// import { Paper, Modal } from "@mui/material";
// import RichText from "../pannel/richtext";
import Button from "react-bootstrap/Button";
// import Box from "@mui/material/Box";
// import LinearProgress from '@mui/material/LinearProgress';

// import EditSections from './editSection';
// import SectionAddTable from './sectionsTable';
import SyllabusTable from "./syllabusTable";

import DialogModal from "../modal/modal";
import CreateLivePaper from "./createLivePaper";

function PaperForm(props) {
  const [open, setOpen] = useState();

  const [questionSet, setQuestionSet] = useState(
    props.sectionQues ? props.sectionQues : 0
  );

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // change questions number handler
  const handleQuestionSet = (e) => {
    const value = Number(e.target.value);

    console.log("sagdskd", value, props.questionCount);

    if (value > props.questionCount) {
      return;
    }

    if (value <= 0) {
      setQuestionSet(0);
      return;
    }

    setQuestionSet(value);

    props.handleSectionQues(e);
  };

  console.log(props, "props on form ");
  return (
    <>
      <div className="row">
        <Form.Group className="col-md-9 mb-3">
          <Form.Label>Paper Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={props.template.name}
            onChange={props.handleTemplate}
            placeholder=""
          />
          {props.error?.name ? (
            <small style={{ color: "red" }}>{props.error.name}</small>
          ) : (
            ""
          )}
        </Form.Group>

        <Form.Group
          className="col-md-3 mb-3"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label> Exam Type {props.template.paper_type}</Form.Label>
          <Form.Select
            onChange={props.handleTemplate}
            name="paper_type"
            value={props.template.paper_type}
          >
            <option value="" selected disabled>
              --select--{" "}
            </option>
            <option value="free">Free (Normal Papers)</option>
            <option value="paid">Paid (Special Papers) </option>
            <option value="live">Live </option>
          </Form.Select>
          {props.error?.sectionArrayId ? (
            <small style={{ color: "red" }}>{props.error.sectionArrayId}</small>
          ) : (
            ""
          )}
        </Form.Group>

        {/* paper category */}

        <Form.Group
          className="col-md-3 mb-3"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>
            {" "}
            Paper Category {props.template.paper_category}
          </Form.Label>
          <Form.Select
            onChange={props.handleTemplate}
            name="paper_category"
            value={props.template.paper_category}
          >
            <option value="" selected disabled>
              --select--{" "}
            </option>
            <option value="1">All (Normal Papers)</option>
            <option value="2">
              Subject (Subject Papers with multiple syllabus){" "}
            </option>
            <option value="3">Syllabus (Syllabus only papers.) </option>
          </Form.Select>
          {props.error?.sectionArrayId ? (
            <small style={{ color: "red" }}>{props.error.sectionArrayId}</small>
          ) : (
            ""
          )}
        </Form.Group>

        {/* end paper category */}

        {props.template.paper_type === "paid" && (
          <Form.Group className="mb-3 col-md-3">
            <Form.Label>Paper Price (INR)</Form.Label>
            <Form.Control
              type="number"
              name="paper_price"
              value={props.template.paper_price}
              onChange={props.handleTemplate}
              placeholder="INR"
            />
            {props.error?.paper_price ? (
              <small style={{ color: "red" }}>{props.error.paper_price}</small>
            ) : (
              ""
            )}
          </Form.Group>
        )}

        {props.template.paper_type === "live" && (
          <Form.Group className="mb-3 col-md-3">
            <Form.Label>Live Test Date</Form.Label>
            <Form.Control
              type="date"
              name="live_test_date"
              value={props.template.live_test_date}
              onChange={props.handleTemplate}
            />
            {props.error?.live_test_date ? (
              <small style={{ color: "red" }}>
                {props.error.live_test_date}
              </small>
            ) : (
              ""
            )}
          </Form.Group>
        )}
        {props.template.paper_type === "live" && (
          <Form.Group className="mb-3 col-md-3">
            <Form.Label>Expire Date</Form.Label>
            <Form.Control
              type="date"
              name="expire_date"
              value={props.template.expire_date}
              onChange={props.handleTemplate}
            />
            {props.error?.expire_date ? (
              <small style={{ color: "red" }}>{props.error.expire_date}</small>
            ) : (
              ""
            )}
          </Form.Group>
        )}
        {props.template.paper_type === "live" && (
          <Form.Group className="mb-3 col-md-3">
            <Form.Label>Live Test Time</Form.Label>
            <Form.Control
              type="time"
              name="live_test_time"
              value={props.template.live_test_time}
              onChange={props.handleTemplate}
            />
            {props.error?.marks_per_question ? (
              <small style={{ color: "red" }}>
                {props.error.live_test_time}
              </small>
            ) : (
              ""
            )}
          </Form.Group>
        )}

        <Form.Group className="mb-3 col-md-3">
          <Form.Label>Total Questions</Form.Label>
          <Form.Control
            type="number"
            name="tot_questions"
            value={props.template.tot_questions}
            onChange={props.handleTemplate}
            placeholder=""
          />
          {props.error?.tot_questions ? (
            <small style={{ color: "red" }}>{props.error.tot_questions}</small>
          ) : (
            ""
          )}
        </Form.Group>
        <Form.Group className="mb-3 col-md-3">
          <Form.Label>Marks Per Question</Form.Label>
          <Form.Control
            type="number"
            name="marks_per_question"
            value={props.template.marks_per_question}
            onChange={props.handleTemplate}
            placeholder=""
          />
          {props.error?.marks_per_question ? (
            <small style={{ color: "red" }}>
              {props.error.marks_per_question}
            </small>
          ) : (
            ""
          )}
        </Form.Group>
        <Form.Group className="mb-3 col-md-3">
          <Form.Label>Negative Marks Per Question</Form.Label>
          <Form.Control
            type="number"
            name="negative_marks"
            value={props.template.negative_marks}
            onChange={props.handleTemplate}
            placeholder=""
          />
        </Form.Group>

        <Form.Group
          className="mb-3 col-md-3"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>Total Time (minutes)</Form.Label>
          <Form.Control
            type="number"
            name="total_time"
            value={props.template.total_time}
            onChange={props.handleTemplate}
            placeholder="Time in minutes"
          />
        </Form.Group>

        <Form.Group className="mb-3 col-md-12" controlId="formBasicPassword">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            name="description"
            value={props.template.description}
            onChange={props.handleTemplate}
            placeholder="What this Paper is about"
          />
          {props.error?.description ? (
            <small style={{ color: "red" }}>{props.error.description}</small>
          ) : (
            ""
          )}
        </Form.Group>

        <Form.Group
          className="col-md-3 mb-5 mt-3"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label> Select Subject</Form.Label>
          <Form.Select
            onChange={props.handleChangeSubject}
            name="subject_id"
            value={props.questionForm.subject_id}
          >
            <option value="" disabled>
              --select--{" "}
            </option>
            {props.subject &&
              props.subject.map((sub) => (
                <option value={sub.id}>{sub.subName}</option>
              ))}
          </Form.Select>
          {props.error?.sectionArrayId ? (
            <small style={{ color: "red" }}>{props.error.sectionArrayId}</small>
          ) : (
            ""
          )}
        </Form.Group>

        <Form.Group
          className="col-md-3 mb-5 mt-3"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>Select Syllabus</Form.Label>
          <Form.Select
            name="syllabus_id"
            onChange={props.handleChangeSyllabus}
            value={props.questionForm.syllabus}
          >
            <option value="" selected>
              --select--{" "}
            </option>
            {props.syllabus &&
              props.syllabus.map((syl) => (
                <option key={syl.id} value={syl.id}>
                  {syl.syllabus}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
        <Form.Group
          className="col-md-3 mb-5 mt-3"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>Questions from this section</Form.Label>
          <div className="row no-gutters">
            <div className="col-md-4">
              {/* <Form.Label className="text-sm">Available Questions</Form.Label> */}
              <Form.Control
                type="text"
                value={props.questionCount}
                // onChange={props.handleSectionQues}
                disabled
              />
              <Form.Label className="text-sm mt-1">
                Available Questions
              </Form.Label>
            </div>
            <div className="col-md-8">
              {/* <Form.Label className="text-sm">Set Questions</Form.Label> */}
              <Form.Control
                type="number"
                defaultValue={props.sectionQues}
                value={questionSet}
                onChange={handleQuestionSet}
              />
              <Form.Label className="text-sm mt-1">Set Questions</Form.Label>
            </div>
          </div>
          {/* <Form.Control
            type="text"
            defaultValue={props.sectionQues}
            onChange={props.handleSectionQues}
          /> */}
        </Form.Group>
        <Form.Group className="col-md-3 mb-5 mt-5">
          <Button
            onClick={props.handleSections}
            variant="primary"
            disabled={props.questionCount === 0 ? true : false}
          >
            + Add Sections
          </Button>
        </Form.Group>
        {props.sections.length > 0 && (
          <SyllabusTable
            tableData={props.sections}
            count={props?.count}
            handleSectionDelete={props.handleSectionDelete}
            hasEdit={props.hasProps}
            table={props.table}
            setTable={props.setTable}
            template={props.template}
          />
        )}
        {props.error?.count ? (
          <small style={{ color: "red" }}>{props.error.count}</small>
        ) : (
          ""
        )}

        <div className="text-center">
          {props.template.paper_type !== "live" ? (
            <Button
              className="btn btn-primary mt-5 btn-block"
              variant="success"
              onClick={(e) => {
                if (props.count > props.template.tot_questions) {
                  alert("Please remove questions in sections or check total questions it cannot be greater than subtotal");
                  return;
                }
                if (props.count < props.template.tot_questions) {
                  alert("Please add questions in sections or check total questions it cannot be greater than subtotal");
                  return;
                }

                props.handleSubmit();
              }}
              disabled={props.questionCount === 0 ? true : false}
            >
              Submit Paper
            </Button>
          ) : (
            <Button
              className="btn btn-primary mt-5 btn-block"
              variant="success"
              onClick={() => setOpen(true)}
            >
              Create live Paper
            </Button>
          )}
        </div>

        <DialogModal
          // title={<LinearProgress />}
          open={open}
          width="lg"
          handleCloseMove={() => setOpen(false)}
          form={
            <CreateLivePaper
              sections={props.sections}
              formdata={props.template}
              handleCloseMove={() => setOpen(false)}
            />
          }
        />
      </div>
    </>
  );
}

export default PaperForm;
