import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Divider } from '@mui/material';
const htmlToFormattedText = require("html-to-formatted-text");


export default function PopOver(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [data, setData] = React.useState(props.questions);

    // console.log(data && data,'propsprops');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>

            <Stack direction="row" spacing={1}>
                <IconButton aria-label="info" aria-describedby={id} onClick={handleClick}>
                    <InfoIcon />
                </IconButton>
            </Stack>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ p: 2 }}>
                    <div>
                        <spna className="text-small text-secondary">Added By: {data?.added_by.username}</spna>
                        <Divider />
                        <div className='text-danger m-2'>
                            <b>
                                Wrong Answers:
                            </b>
                        </div>
                        <div>
                            {data?.w_ans1 ? <p> <div dangerouslySetInnerHTML={{ __html: data?.w_ans1 }} /> </p> : ''}
                        </div>
                        <div>
                            {data?.w_ans2 ? <p> <div dangerouslySetInnerHTML={{ __html: data?.w_ans2 }} /> </p> : ''}
                        </div>
                        <div>
                            {data?.w_ans3 ? <p> <div dangerouslySetInnerHTML={{ __html: data?.w_ans3 }} /> </p> : ''}
                        </div>
                        <div>
                            {data?.w_ans4 ? <p> <div dangerouslySetInnerHTML={{ __html: data?.w_ans4 }} /> </p> : ''}
                        </div>
                    </div>

                    {data &&
                        <>
                            <div className='mt-3'>
                                <b>
                                    Description:
                                </b>
                            </div>
                            <div className='text-small'>
                                <p>
                                    <div dangerouslySetInnerHTML={{ __html: data?.ans_desc }} />
                                </p>

                            </div>
                        </>
                    }


                </Typography>
            </Popover>
        </div>
    );
}
