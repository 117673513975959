import {
  useMediaQuery,
  useTheme,
  Stack,
  Typography,
  Grid,
  Paper,
  Button,
  Box,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import UserCard from "./user-card";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../API/api";
import LoadingSpinner from "./loading-spinner";
import UserDetailTab from "./user-detail-tab";
import UserDetailMain from "./user-detail-main";

const AppUserInfo = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [app_user, setAppUser] = useState(null);

  const [loading, setLoading] = useState(true);

  const theme = useTheme();
  const isBelowMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const getAppUser = async () => {
    let localid = id;
    if (props.user) {
      localid = props.user.id;
    }
    api
      .getAppUser(localid)
      .then((res) => {
        console.log(res.data);
        setAppUser(res.data.user);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAppUser();
  }, [id, props.user]);

  return (
    <Stack
      direction="column"
      // spacing={2}
      flexGrow={1}
      minHeight="100%"
      // minHeight="85vh"
      sx={{
        borderRadius: "8px",
      }}
    >
      {!props.user && (
        <Button
          sx={{
            width: {
              md: "200px",
              paddingLeft: 2,
            },
            color: "blue",
          }}
          variant="ghost"
          onClick={() => navigate("/admin/app-users")}
        >
          Back to App Users
        </Button>
      )}

      {loading && <LoadingSpinner />}

      {!loading && (
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={2}
          sx={{ width: "100%", flexGrow: 1 }}
          padding={1}
        >
          <Paper elevation={3} sx={{ padding: 2, flex: 1 }}>
            <UserCard user={app_user} />
          </Paper>
          <UserDetailMain user={app_user} />
        </Stack>
      )}
    </Stack>
  );
};

export default AppUserInfo;
