import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Divider } from '@mui/material';
import { convertLatexToAsciiMath } from 'mathlive';


const htmlToFormattedText = require("html-to-formatted-text");
export default function InfoQues(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // console.log(props, 'under props meu prop');
    return (
        <div>

            <Stack direction="row" spacing={1}>
                <IconButton aria-label="info" aria-describedby={id} onClick={handleClick}>
                    <InfoIcon />
                </IconButton>
            </Stack>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ p: 2 }}>
                    <div>
                        <div>

                            <div className='text-secondary m-2'>
                                {props.ans.addedBy &&
                                    <>
                                        <AccountCircleIcon /> Added By: {props.ans.addedBy.username}
                                    </>
                                }
                            </div>

                        </div>
                        <Divider />
                        <div className=' m-2'>
                            <b>Subject Name :</b>{

                                props.ans.subject &&
                                props.ans.subject.subName
                            }
                        </div>
                        <div className=' m-2'>
                            <b>Syllabus Name</b> :{
                                 props.ans.syllabus &&
                                props.ans.syllabus.syllabus
                            }
                        </div>
                        <div className=' m-2'>
                            <b>
                                Wrong Answers:
                            </b>
                        </div>
                        <div>
                            <>
                                {props.ans.ans.map((el, index) => (
                                    <>
                                        {
                                            el.question_type === 2 ?
                                                <>
                                                    {!el.isRight &&
                                                        <img src={`${process.env.REACT_APP_BACKEND}answers/img/${el.answer}`} style={{ height: 50, width: 50, marginRight: 8 }} />
                                                    }
                                                </>
                                                :
                                                el.question_type === 1 ?
                                                    !el.isRight &&
                                                    <div key={index} className="text-dark text-small">
                                                        <i>  {index + 1}) &nbsp;
                                                            {htmlToFormattedText(el.answer)}
                                                        </i>
                                                    </div>
                                                    :
                                                    !el.isRight &&
                                                    <div key={index} className="text-dark text-small d-flex align-items-center">
                                                        {index + 1})
                                                        <math-field
                                                            readonly
                                                            style={{ border: 0 }}
                                                            key={index}
                                                        >
                                                            {el.answer}
                                                        </math-field>
                                                    </div>
                                        }

                                    </>
                                )
                                )}
                            </>
                        </div>
                    </div>

                    {props.ans.description &&
                        <>
                            <div className='mt-3'>
                                <b>
                                    Description:
                                </b>
                            </div>
                            <div className='text-small'>
                                {/* {htmlToFormattedText(props.ans.description)} */}
                                <div dangerouslySetInnerHTML={{ __html: props.ans.description }} />
                            </div>
                        </>
                    }
                </Typography>
            </Popover>
        </div>
    );
}
