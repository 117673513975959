import {
  Box,
  Button,
  Divider,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// style for modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 300,
  maxWidth: 500,
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const LivePaperUserInfo = ({ selectedData, open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Stack direction={"column"} spacing={2} sx={{ position: "relative" }}>
          <Box sx={{ position: "absolute", top: 0, right: 0 }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Stack direction={"row"} spacing={2}>
            <Typography variant="h5">User Info</Typography>
          </Stack>
          <Stack direction={"column"} spacing={2}>
            <Stack direction={"row"} spacing={2}>
              <Typography variant="body1">
                User ID: {selectedData?.user?.id}
              </Typography>
            </Stack>
            <Stack direction={"row"} spacing={2}>
              <Box
                sx={{
                  width: "150px",
                  height: "150px",
                  borderRadius: "50%",
                }}
              >
                {selectedData?.user?.profile_image ? (
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    src={selectedData?.user?.profile_image}
                    alt="profile"
                  />
                ) : (
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    src={"https://www.w3schools.com/w3images/avatar2.png"}
                    alt="profile"
                  />
                )}
              </Box>
              <Stack direction={"column"} spacing={0.5}>
                <Paper sx={{ padding: 2 }}>
                  <Typography variant="h6">
                    {selectedData?.user?.f_name} {selectedData?.user?.l_name}
                  </Typography>
                </Paper>
                <Paper sx={{ padding: 2 }}>
                  <Typography variant="body2">
                    {selectedData?.user?.email}
                  </Typography>
                  <Typography variant="body2">
                    {selectedData?.user?.phone_number}
                  </Typography>
                </Paper>
              </Stack>
            </Stack>
            <Divider />
            <Stack direction={"column"} spacing={2}>
              <Stack direction={"row"} spacing={2}>
                <Typography variant="h6">User Xp</Typography>
              </Stack>
              <Stack direction={"row"} spacing={2}>
                <Typography variant="body1">
                  Current Rank: {selectedData?.userXpDetails?.medal_level.name}
                </Typography>
              </Stack>
              <Stack direction={"column"} spacing={2} sx={{ width: "100%" }}>
                <Typography variant="body1">
                  Progress: {selectedData?.userXpDetails?.progress} / 100
                </Typography>
                <LinearProgress
                  sx={{ width: "100%" }}
                  variant="determinate"
                  value={selectedData?.userXpDetails?.progress}
                />
              </Stack>
            </Stack>
          </Stack>
          {/* <Box>{JSON.stringify(selectedData)}</Box> */}
        </Stack>
      </Box>
    </Modal>
  );
};

export default LivePaperUserInfo;
