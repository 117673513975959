import { Box, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import SubscriptionPaper from "./subscription-paper";
import SubscriptionPackage from "./subscription-package";
import SubscriptionBundle from "./subscription-bundle";

const UserDetailSubscriptionItem = ({ subscription }) => {
  const theme = useTheme();
  const isBelowLGScreen = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box
      key={subscription.id}
      sx={{
        flex: 1,
        maxWidth: isBelowLGScreen ? "100%" : "50%",
        minWidth: isBelowLGScreen ? "100%" : "50%",
        backgroundColor: "inherit",
        p: 1,
      }}
    >
      <Paper
        elevation={2}
        sx={{
          p: 2,
          backgroundColor: "white",
          maxHeight: "13rem",
          minHeight: "13rem",
          width: "100%",
          overflowY: "auto",
        }}
      >
        <Typography variant="body1">
          Item: {subscription.type}, Item ID: {subscription.id}, Expired:{" "}
          {subscription.isExpired
            ? "Yes"
            : subscription.isExpired === false || subscription.isExpired === 0
            ? "No"
            : "Not Bought"}
        </Typography>

        {subscription.type === "paper" && (
          <SubscriptionPaper subscription={subscription} />
        )}
        {subscription.type === "package" && (
          <SubscriptionPackage subscription={subscription} />
        )}
        {subscription.type === "bundle" && (
          <SubscriptionBundle subscription={subscription} />
        )}
      </Paper>
    </Box>
  );
};

export default UserDetailSubscriptionItem;
