import React, { useEffect, useState } from "react";
import api from "../../API/api";
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import InfoIcon from '@mui/icons-material/Info';
import Swal from 'sweetalert2';


export default function PaperBundle() {

    const [papers, setPapers] = useState();
    const [pricing, setPricing] = useState({
        discount: 0,
        discount_type: '-',
        newPrice: 0,
        name: '',
        description: ''


    })

    useEffect(() => {
        getPaidPapers();
    }, [])

    useEffect(() => {

        if (pricing.discount_type == '%') {

            var discount = papers?.filter((e) => e.isClicked === true).reduce((accumulator, currentValue) => parseInt(accumulator) + parseInt(currentValue.paper_price), 0) - (papers?.filter((e) => e.isClicked === true).reduce((accumulator, currentValue) => parseInt(accumulator) + parseInt(currentValue.paper_price), 0) * pricing.discount) / 100
        }
        else {
            var discount = papers?.filter((e) => e.isClicked === true).reduce((accumulator, currentValue) => parseInt(accumulator) + parseInt(currentValue.paper_price), 0) - pricing.discount
        }

        setPricing({
            ...pricing, newPrice: discount
        })
    }, [papers, pricing.discount, pricing.discount_type])


    const getPaidPapers = () => {
        api.getPapers('paid').then((res) => {
            setPapers(res.data.papers.map((e) => ({ ...e, isClicked: false, isOpen: false })));
            console.log(res);
        })
            .catch((err) => {
                console.log(err);
            })
    };

    const handleSelect = (data) => {

        setPapers(prevItems => {
            return prevItems.map(item => {
                if (item.id === data.id) {
                    return { ...item, isClicked: !item.isClicked };
                }
                return item;
            });
        });
    }

    const changePricing = (e) => {
        setPricing({
            ...pricing, [e.target.name]: e.target.value
        })
    }

    const handleSubmit = () => {
        if(!pricing.name){
            Swal.fire(
                'Error',
                'Bundle Name cannot be empty',
                'error'
            )
            return;
        }
        if(!pricing.description){
            Swal.fire(
                'Error',
                'Description cannot be empty',
                'error'
            )
            return;
        }
        if(papers?.filter((e) => e.isClicked === true).length == 0){
            Swal.fire(
                'Error',
                'Please add Papers',
                'error'
            )
            return;

        }

        console.log(papers?.filter((e) => e.isClicked === true));
        // console.log(pricing);
        var selectedPapers = papers?.filter((e) => e.isClicked === true);
        api.savePaperBundle(selectedPapers, pricing).then((res) => {
            console.log(res);
            if(res.data.status == 200){
                Swal.fire(
                    'Success',
                    'Bundle Added Successfully',
                    'success'
                )
            }


        })
        .catch((err)=>{
            console.log(err);
        })


    }



    return (
        <>
            <h4> Select Paper</h4>
            {papers && papers.map((p, index) => (
                <>
                    <div className="d-flex">
                        <div className="col-10">
                            <div className={`${!p.isClicked ? 'bg-light' : 'bg-primary text-white'} p-1 m-1 rounded d-flex justify-content-between`} onClick={() => handleSelect(p)}>
                                <div>
                                    {index + 1} |  {p.name}
                                </div>
                                <div>
                                    Price:  {p.paper_price} ₹
                                </div>
                            </div>
                        </div>


                        <div>
                            <Button
                                className="p-1 m-1 btn-secondary"
                                onClick={() => setPapers(prevItems => {
                                    return prevItems.map(item => {
                                        if (item.id === p.id) {
                                            return { ...item, isOpen: !item.isOpen };
                                        }
                                        return item;
                                    });
                                })}
                                aria-controls="example-collapse-text"
                                aria-expanded={p.isOpen}
                            ><InfoIcon /></Button>

                        </div>




                    </div>

                    <Collapse in={p.isOpen}>
                        <div id="example-collapse-text" className="border pb-3 col-10 m-3">
                            {p?.subjects.map((s, i) => (
                                <div className="m-2 ">
                                    {i + 1} ) {s.syllabus}
                                </div>
                            ))}
                        </div>
                    </Collapse>
                </>
            ))}

            <hr />



            <div className="m-4 pt-5">
                <div className="row">


                    <div className="col-6 border">
                        <div className="text-center p-2">
                            <b>Selected Papers </b>
                        </div>
                        {papers && papers.filter((e) => e.isClicked === true).map((p) => (
                            <>
                                <div className="d-flex justify-content-between text-success" style={{ paddingRight: '26px' }}>
                                    <div>
                                        {p.name}
                                    </div>
                                    <div>
                                        :  {p.paper_price} ₹
                                    </div>
                                </div>
                            </>

                        ))}
                        <hr />
                        <div className="d-flex justify-content-end text-primary" style={{ paddingRight: '26px' }}>
                            <div>
                                <strong>
                                    Actual Price :  {papers && papers.filter((e) => e.isClicked === true).reduce((accumulator, currentValue) => parseInt(accumulator) + parseInt(currentValue.paper_price), 0)} ₹
                                </strong>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end text-danger">
                            <div>
                                <>
                                    Discount (<select name="discount_type" id="" value={pricing.discount_type} onChange={changePricing} style={{
                                        border: '0px solid',
                                        width: '50px'
                                    }}>
                                        <option value="%">%</option>
                                        <option value="-">-</option>
                                    </select>) :  <input type="number" name="discount" value={pricing.discount} onChange={changePricing} id="" style={{
                                        border: '0px solid',
                                        width: '50px'
                                    }} />
                                </>
                                <hr />
                            </div>
                        </div>
                        <div className="d-flex justify-content-end text-success" style={{ paddingRight: '26px' }}>
                            <div>
                                <strong>
                                    New Price :  {pricing.newPrice}
                                </strong>
                                <hr />
                            </div>
                        </div>




                    </div>

                    <div className="col-6 border">

                    <div className="text-center p-2">
                            <b>Bundle Information </b>
                        </div>



                        <div className="m-2">

                            <div className="mb-3">
                                Bundle name:
                                <input className="form-control" type="text" name="name" value={pricing.name} onChange={changePricing} id="" />
                            </div>
                            <div className="mb-3">
                                Description:
                                <input className="form-control" type="text" name="description" value={pricing.description} onChange={changePricing} id="" />
                            </div>
                        </div>

                        <div className="text-center m-2">
                            <button className="btn btn-primary" onClick={handleSubmit}>Save Bundle</button>
                        </div>

                    </div>

                </div>
            </div>


        </>
    );
}
