import {
  Box,
  Paper,
  Stack,
  Typography,
  IconButton,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

// table related imports
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import api from "../../../../API/api";
import useBasicLoader from "../../../../hooks/useBasicLoader";
import SkeletonList from "./skeleton-list";

const LivePaperList = ({ changeSelectedLivePaper, selectedLivePaper }) => {
  // get a list of live papers
  const [livePapers, setLivePapers] = useState([]);
  const [paginateOptions, setPaginateOptions] = useState(null);

  // pagination states
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filters, setFilters] = useState({});

  // loading states
  const { loading, changeLoading } = useBasicLoader(true);

  const initializeLivePapers = (localPage, localRowsPerPage) => {
    changeLoading(true);
    api
      .listPaper({
        params: {
          page: localPage,
          total_rows: localRowsPerPage,
          paperType: "live",
          ...filters,
        },
      })
      .then((res) => {
        changeLoading(false);
        console.log(res, "response for live papers");
        if (res.data.papers.data) {
          setLivePapers(res.data.papers.data);

          changeSelectedLivePaper(res.data.papers.data[0]);

          // let tempObjects = [
          //   {
          //     id: 1,
          //     name: "Live Paper 1",
          //     description: "Description 1",
          //     createdAt: "2021-01-01",
          //   },
          //   {
          //     id: 2,
          //     name: "Live Paper 2",
          //     description: "Description 2",
          //     createdAt: "2021-01-02",
          //   },
          //   {
          //     id: 3,
          //     name: "Live Paper 3",
          //     description: "Description 3",
          //     createdAt: "2021-01-03",
          //   },
          //   {
          //     id: 4,
          //     name: "Live Paper 4",
          //     description: "Description 4",
          //     createdAt: "2021-01-04",
          //   },
          //   {
          //     id: 5,
          //     name: "Live Paper 5",
          //     description: "Description 5",
          //     createdAt: "2021-01-05",
          //   },
          //   {
          //     id: 6,
          //     name: "Live Paper 6",
          //     description: "Description 6",
          //     createdAt: "2021-01-06",
          //   },
          //   {
          //     id: 7,
          //     name: "Live Paper 7",
          //     description: "Description 7",
          //     createdAt: "2021-01-07",
          //   },
          //   {
          //     id: 8,
          //     name: "Live Paper 8",
          //     description: "Description 8",
          //     createdAt: "2021-01-08",
          //   },
          //   {
          //     id: 9,
          //     name: "Live Paper 9",
          //     description: "Description 9",
          //     createdAt: "2021-01-09",
          //   },
          //   {
          //     id: 10,
          //     name: "Live Paper 10",
          //     description: "Description 10",
          //     createdAt: "2021-01-10",
          //   },
          //   {
          //     id: 11,
          //     name: "Live Paper 11",
          //     description: "Description 11",
          //     createdAt: "2021-01-11",
          //   },
          //   {
          //     id: 12,
          //     name: "Live Paper 12",
          //     description: "Description 12",
          //     createdAt: "2021-01-12",
          //   },
          //   {
          //     id: 13,
          //     name: "Live Paper 13",
          //     description: "Description 13",
          //     createdAt: "2021-01-13",
          //   },
          //   {
          //     id: 14,
          //     name: "Live Paper 14",
          //     description: "Description 14",
          //     createdAt: "2021-01-14",
          //   },
          //   {
          //     id: 15,
          //     name: "Live Paper 15",
          //     description: "Description 15",
          //     createdAt: "2021-01-15",
          //   },
          //   {
          //     id: 16,
          //     name: "Live Paper 16",
          //     description: "Description 16",
          //     createdAt: "2021-01-16",
          //   },
          //   {
          //     id: 17,
          //     name: "Live Paper 17",
          //     description: "Description 17",
          //     createdAt: "2021-01-17",
          //   },
          //   {
          //     id: 18,
          //     name: "Live Paper 18",
          //     description: "Description 18",
          //     createdAt: "2021-01-18",
          //   },
          //   {
          //     id: 19,
          //     name: "Live Paper 19",
          //     description: "Description 19",
          //     createdAt: "2021-01-19",
          //   },
          //   {
          //     id: 20,
          //     name: "Live Paper 20",
          //     description: "Description 20",
          //     createdAt: "2021-01-20",
          //   },
          //   {
          //     id: 21,
          //     name: "Live Paper 21",
          //     description: "Description 21",
          //     createdAt: "2021-01-21",
          //   },
          //   {
          //     id: 22,
          //     name: "Live Paper 22",
          //     description: "Description 22",
          //     createdAt: "2021-01-22",
          //   },
          //   {
          //     id: 23,
          //     name: "Live Paper 23",
          //     description: "Description 23",
          //     createdAt: "2021-01-23",
          //   },
          //   {
          //     id: 24,
          //     name: "Live Paper 24",
          //     description: "Description 24",
          //     createdAt: "2021-01-24",
          //   },
          //   {
          //     id: 25,
          //     name: "Live Paper 25",
          //     description: "Description 25",
          //     createdAt: "2021-01-25",
          //   },
          //   {
          //     id: 26,
          //     name: "Live Paper 26",
          //     description: "Description 26",
          //     createdAt: "2021-01-26",
          //   },
          //   {
          //     id: 27,
          //     name: "Live Paper 27",
          //     description: "Description 27",
          //     createdAt: "2021-01-27",
          //   },
          //   {
          //     id: 28,
          //     name: "Live Paper 28",
          //     description: "Description 28",
          //     createdAt: "2021-01-28",
          //   },
          //   {
          //     id: 29,
          //     name: "Live Paper 29",
          //     description: "Description 29",
          //     createdAt: "2021-01-29",
          //   },
          //   {
          //     id: 30,
          //     name: "Live Paper 30",
          //     description: "Description 30",
          //     createdAt: "2021-01-30",
          //   },
          // ];

          // setLivePapers(tempObjects);
        } else {
          setLivePapers([]);
        }
        let localResponsePapers = res.data.papers;
        delete localResponsePapers.data;
        setPaginateOptions(localResponsePapers);
      })
      .catch((err) => {
        changeLoading(false);
        console.log(err);
      });
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeFilters = (key, value) => {
    setPage(1);
    setRowsPerPage(10);
    setFilters({ ...filters, [key]: value });
  };

  useEffect(() => {
    initializeLivePapers(page, rowsPerPage);
  }, [page, rowsPerPage]);

  useEffect(() => {
    initializeLivePapers(1, 10);
  }, [filters]);

  return (
    <Stack flex={1} direction={"column"} height="85vh">
      <Paper sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Typography sx={{ p: 2 }} variant="h5">
          Live Paper List
        </Typography>
        <Stack direction={"column"} spacing={2} px={2}>
          <TextField
            fullWidth
            label="Search Paper Name"
            variant="outlined"
            value={filters.paperName || ""}
            onChange={(e) => handleChangeFilters("paperName", e.target.value)}
          />
        </Stack>
        <TableContainer component={Box} sx={{ flexGrow: 1, overflowY: "auto" }}>
          <Table aria-label="simple table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Paper Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <SkeletonList rowsNum={rowsPerPage} />
              ) : (
                livePapers.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                    onClick={() => changeSelectedLivePaper(row)}
                    selected={selectedLivePaper === row}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            p: 2,
            borderTop: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <IconButton
            onClick={() => handleChangePage(page - 1)}
            disabled={page === 1}
          >
            <ChevronLeft />
          </IconButton>
          <Typography>
            Page {page} of {paginateOptions ? paginateOptions.last_page : 1}
          </Typography>
          <IconButton
            onClick={() => handleChangePage(page + 1)}
            disabled={page >= (paginateOptions ? paginateOptions.last_page : 1)}
          >
            <ChevronRight />
          </IconButton>
          <Select
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
            sx={{ height: 30, ml: 2 }}
          >
            {[5, 10, 25, 50].map((rows) => (
              <MenuItem key={rows} value={rows}>
                {rows} per page
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Paper>
    </Stack>
  );
};

export default LivePaperList;
