import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form';
import { Paper } from '@mui/material';
import { Button } from '@mui/material';
import RichText from '../pannel/richtext';
import 'mathlive';
import api from '../../API/api';
import Swal from 'sweetalert2';
import DialogModal from '../modal/modal';
import ChangeAns from './changeAns';
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';

function AddQuestionMaths(props) {
    const [input, setInput] = useState('');
    const [tags, setTags] = useState([]);
    const [show, setShow] = useState(false);
    const [modalData, setModalData] = useState();
    const [value , setValue] = useState();

    const [open, setOpen] = useState(false);

    const handleOpenModal = (preview) => {
        setOpen(true);
        setModalData(preview)
    }

    const handleCloseModal = () => {
        setOpen(false)
    }

    useEffect(() => {
        if (props.all) {
            let das = [];
            var a = props.all.ans.map(el => {
                if (el.isRight === 0) {
                    das.push(el.answer)
                }
            }
            );
            setTags(das);
        }
    }, [props.all])


    const showDesc = () => {
        setShow(!show);
    }

    const onChange = (e) => {
        const { value } = e.target;
        setInput(value);
    };

    const onKeyDown = (e) => {
        // console.log("ooo");
        const { key } = e;
        const trimmedInput = input.trim();

        if (key === 'Enter' && trimmedInput.length && !tags.includes(trimmedInput)) {
            console.log("w", e);
            e.preventDefault();
            setTags(prevState => [...prevState, trimmedInput]);
            setInput('');
        }
    };

    const handleRemoveAns = (tag) => {
        let filterData = tags.filter(e =>
            e !== tag
        )
        setTags(filterData);
    }

    const handleSubmit = () => {
        console.log(formValues);
        // return;
        props.handleSubmit(tags, formValues)


    }

    // useEffect(() => {
    //     if (props.type2 === 'excel') {
    //         setTags(current => [...current, props.questionForm.w_ans1, props.questionForm.w_ans2, props.questionForm.w_ans3, props.questionForm.w_ans4])
    //         return;
    //     }
    //     console.log(tags && tags, 'tags');
    // }, [props.questionForm.w_ans1])


    // handleDraft
    const handleDraft = (wrongAns) => {
        console.log("handleDraft", wrongAns);
        console.log("formValues", formValues);
        console.log("props.questionForm", props.questionForm);
        // return;
        api.EditExelQuestion(props.questionForm, wrongAns, formValues, 'draft').then((res) => {
            if (res.data.status == 200) {
                Swal.fire({
                    title: 'Updated',
                    text: 'Exel Question Updated Successfully ',
                    icon: 'success'
                });
            }
            else {
                Swal.fire({
                    title: 'Opss',
                    text: 'Something went wrong ! ',
                    icon: 'error'
                });
            }
        })
            .catch((err) => { console.log(err.response); })
    }

    // *****************

    useEffect(() => {
        if (props.type === 'edit') {
            let newarry = [];

            props.all?.ans.map((elem, index) => {
                console.log(elem);
                newarry.push({
                    type: elem.isRight,
                    preview: elem.answer,
                    ans: elem.answer,
                    ans_id: elem.ans_id,
                    q_id: elem.q_id
                })
            })
            setFormValues(newarry);
        }
    }, [props.all])


    const [formValues, setFormValues] = useState([
        {
            type: 1,
        },
        {
            type: 0,
        },
        {
            type: 0,
        },
        {
            type: 0,
        },
    ])

    let handleChange = (i, e) => {
        console.log(i, parseInt(e.target.value));
        let newFormValues = [...formValues];
        newFormValues[i]['type'] = parseInt(e.target.value);
        setFormValues(newFormValues);
    }

    let handleChangeImg = (i, e) => {
        console.log(i, e);
        let newFormValues = [...formValues];
        newFormValues[i]['id'] = i;
        newFormValues[i]['ans_id'] = '';
        newFormValues[i]['ans'] = e.target.files[0];
        // newFormValues[i][e.target.name] = e.target.files[0];
        newFormValues[i]['preview'] = URL.createObjectURL(e.target.files[0]);
        setFormValues(newFormValues);
    }

    let addFormFields = () => {
        setFormValues([...formValues, { type: 0 }])
    }

    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }


    useEffect(() => {
        props.handleQuestion(value)
    },[value])

    return (
        <>

            <div className="row">

                <Form.Group className="col-md-3 mb-4 mt-2">
                    <Form.Label>Difficulties</Form.Label>
                    <Form.Select name='difficulties'
                        onChange={props.handleForm} value={props.questionForm.difficulties}
                    >
                        <option value=''>--select--  </option>
                        <option value='1'>Easy </option>
                        <option value='2'>Medium </option>
                        <option value='3'>Hard </option>
                    </Form.Select>
                </Form.Group>

                <Form.Group className="col-md-3 mb-4 mt-2">
                    <Form.Label>Class</Form.Label>
                    <Form.Select name='class'
                        onChange={props.handleForm} value={props.questionForm.class}
                    >
                        <option value=''>--select--  </option>
                        <option value='8'>Class 8 </option>
                        <option value='9'>Class 9 </option>
                        <option value='10'>Class 10 </option>
                        <option value='11'>Class 11 </option>
                        <option value='12'>Class 12 </option>
                        <option value='13'>UG</option>
                        <option value='14'>PG</option>
                    </Form.Select>
                </Form.Group>

                <Form.Group className="col-md-3 mb-4 mt-2">
                    <Form.Label> Select Subject</Form.Label>

                    {/* {
        props.type === 'edit' ?
            <Form.Select name='subject_id'
                onChange={props.handleChangeSubject} value={props.questionForm.subject_id}
            >
                <option  value='' >--select-- </option>
                <option value={props.subject?.id}>{props.subject?.subName}</option>
            </Form.Select>
            : */}
                    <>
                        <Form.Select name='subject_id'
                            onChange={props.handleChangeSubject} value={props.questionForm.subject_id}
                        >
                            <option value=''>--select-- </option>
                            {props.subject?.map((sub, index) => (
                                <option key={index} value={sub.id}>{sub.subName}</option>
                            ))}
                        </Form.Select>
                    </>
                    {/* } */}

                </Form.Group>

                <Form.Group className="col-md-3 mb-4 mt-2">
                    <Form.Label>Select Syllabus</Form.Label>
                    <Form.Select name='syllabus_id'
                        onChange={props.handleForm} value={props.questionForm.syllabus_id}
                    >
                        <option value=''>--select-- </option>
                        {props.syllabus && props.syllabus.map((syl) => (
                            <option key={syl.id} value={syl.id}>{syl.syllabus}</option>
                        ))}
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-5">
                    <Form.Label>Enter Question</Form.Label>
                    {/* <RichText name="question"
                        handleForm={props.handleQuestion} value={props.questionForm?.question}
                    /> */}
                </Form.Group>
                <div className="App">
                    <math-field
                        onInput={evt => { setValue(evt.target.value) }}
                    >
                        {value}
                    </math-field>
                    {/* <math-field>Value: {value}</math-field> */}
                </div>


                {props.type !== 'edit' &&
                    <div className='col-12 row align-items-center'>
                        <Form.Group className="col-md-4 mb-5 mt-3">
                            <Form.Label>Select Answer Type</Form.Label>
                            <Form.Select name="ans_type" value={props.questionForm.ans_type} onChange={props.handleForm}>
                                <option value=""  >----Select Below----</option>
                                <option value="1" >Normal Text</option>
                                <option value="2" >Image Answers</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                }

                {
                    props.questionForm.ans_type == "1" ?
                        <>
                            <Form.Group className="mb-5">
                                <Form.Label>Right Answer</Form.Label>
                                <RichText name=""
                                    handleForm={props.handleRightAns} value={props.questionForm.right_ans}
                                />
                            </Form.Group>
                            <Form.Group className="col-md-12 mb-5">
                                <Form.Label>Wrong Answersss ( <span className='text-danger'> Press Enter key for add more wrong answers, minimum 3 required </span> )</Form.Label>
                                <input
                                    value={input}
                                    className='form-control'
                                    placeholder=""
                                    onKeyDown={onKeyDown}
                                    onChange={onChange}
                                />
                                {tags?.map((tag, index) =>
                                    <Paper>
                                        <div className="tag" >
                                            <div className='m-1 p-2'>
                                                {index + 1}) {tag}
                                                <span className='float-end'> <button className='btn btn-danger btn-sm' onClick={() => handleRemoveAns(tag)}> x </button></span>
                                            </div>
                                        </div>
                                    </Paper>

                                )}
                            </Form.Group>
                        </>
                        :
                        <>

                            {props.type !== 'edit' &&
                                <div className="col-10">
                                    {formValues.map((element, index) => (
                                        <div className="row py-2" key={index}>
                                            <div className="col-md-7">
                                                <Form.Label>Answer Image/Graphic</Form.Label>
                                                <input type="file" accept='.png, .jpg, .jpeg' className='form-control' name={`ans${index}`} onChange={e => handleChangeImg(index, e)} />
                                            </div>
                                            <div className="col-md-3">

                                                <Form.Label>Right/Wrong</Form.Label>
                                                <select className='form-control' name="type" value={element.type ? element.type : index === 0 ? 1 : 0} onChange={e => handleChange(index, e)}>
                                                    <option value="">Select</option>
                                                    <option value="1">Right</option>
                                                    <option value="0">Wrong</option>
                                                </select>
                                            </div>

                                            <div className="col-md-2">

                                                {
                                                    index ?
                                                        <><br />
                                                            <button type="button" className="btn btn-danger remove" onClick={() => removeFormFields(index)}>Remove</button>
                                                        </>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                    ))}

                                    <div className="button-section">
                                        <button className="btn btn-outline-secondary btn-sm mt-3" type="button" onClick={() => addFormFields()}>+ Add More Answers</button>
                                    </div>
                                </div>
                            }


                            <div className="row  justify-content-center">
                                {formValues.map((preview) => (
                                    <>
                                        {preview.preview &&
                                            <div className={`col-2`}>
                                                <div>
                                                    <div className='ans-edit'>
                                                        <button onClick={() => handleOpenModal(preview)} className='my-3 mx-0 hover-pencil  bg-none'  >
                                                            <ModeEditTwoToneIcon style={{ color: '#fff' }} />
                                                        </button>
                                                    </div>

                                                    <div className={`questionPreview border ${preview.type === 1 ? 'border-success' : 'border-danger'} `}>
                                                        <div>
                                                            {!preview.id ?
                                                                <img src={`${process.env.REACT_APP_BACKEND}answers/img/${preview.preview}`} className='col-12' alt="" />
                                                                :
                                                                <img src={preview.preview} className='col-12' alt="" />
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="text-center">
                                                        {preview.type === 1 ?
                                                            <span className='text-success'>Right Answer</span> : <span className='text-danger'>Wrong Answer</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        }
                                    </>
                                ))}
                            </div>


                            <DialogModal form={<ChangeAns data={modalData} handleCloseMove={handleCloseModal} />} open={open} handleOpenModal={handleOpenModal} handleCloseMove={handleCloseModal} />

                        </>
                }

                <Form.Group className="my-5">
                    <Form.Label>Answer Description</Form.Label>
                    <RichText name=""
                        handleForm={props.handleAnsDesc} value={props.questionForm.ans_desc}
                    />
                </Form.Group>

                {tags &&
                    <div className='text-center'>
                        {/* add */}
                        {
                            props.all &&
                            <button className='btn btn-success' onClick={handleSubmit}>Update Question</button>

                        }
                        {/* ex excel */}

                        {!props.all &&
                            <button className='btn btn-success me-2' onClick={handleSubmit}>Submit Question</button>
                        }

                        {/* edit excel */}

                        {props.type2 === 'excel' &&
                            <button className='btn btn-success' onClick={handleDraft}>Update and Import</button>
                        }
                    </div>
                }



            </div>
        </>
    );
}

export default AddQuestionMaths;