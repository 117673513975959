import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import api from "../../API/api";
import PopOver from "../pannel/popOver";
import MenuButton from "../pannel/menuExcel";
import Checkbox from "@mui/material/Checkbox";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function QuestionExcelTable(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [questions, setQuestions] = React.useState();
  const [menuData, setMenuData] = React.useState();
  const [backdroploader, setBackdropLoader] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event, row) => {
    setMenuData(row);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getExelQuestion = (status) => {
    setBackdropLoader(true)
    api
      .getExelQuestion(status)
      .then((res) => {
        setQuestions(
          res.data.data.map((data) => ({ ...data, isChecked: false }))
        );
        setBackdropLoader(false)
      })
      .catch((err) => {
        console.log(err);
        setBackdropLoader(false)
      });
  };

  React.useEffect(() => {
    getExelQuestion(null);
  }, []);

  const handleChecked = (id) => {
    console.log(id);
    setQuestions(
      questions.map((e) =>
        e.id === id ? { ...e, isChecked: !e.isChecked } : e
      )
    );
  };

  const handleMultiImport = () => {
    console.log(questions);
    var selected = questions.filter((e) =>
      e.isChecked === true && e
    )
    console.log(selected, 'get multiple selected questions');
    // return;
    api.multipleImportFromExel(selected).then((res) => {
      console.log("==>", res);
      window.location.reload();
    })
      .catch((err) => {
        console.log(err);
      })
  }


  const [active, setActive]= React.useState('pending');
  const handleSort = (type) => {
    console.log(type);
    if (type === 'imported') {
      getExelQuestion(1)
    }
    if (type === 'pending') {
      getExelQuestion(null)
    }
    setActive(type);

  }

  return (
    <>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdroploader}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="row d-flex justify-content-between">
        <div className="col">
          <button className='btn btn-secondary btn-sm' onClick={handleMultiImport}>Export to Questions</button> &nbsp;
        </div>
        <div className="col d-flex flex-row-reverse">
          <button className={`btn ${active === 'imported'? 'btn-sm btn-outline-success' : 'btn-outline-secondary btn-sm'} `} onClick={() => handleSort('imported')}>Imported</button> &nbsp;
          <button className={`btn ${active === 'pending'? 'btn-sm btn-outline-success' : 'btn-outline-secondary btn-sm'} `} onClick={() => handleSort('pending')}>Pending</button> &nbsp;
          <button className='btn btn-secondary btn-sm disabled'>Filter By </button> &nbsp;
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow className="bg-dark">
              {/* <TableCell></TableCell> */}
              <TableCell className="text-white">#</TableCell>
              <TableCell className="text-white">Questions</TableCell>
              <TableCell className="text-white">Right Ans</TableCell>
              <TableCell className="text-white">Wrong Ans</TableCell>
              <TableCell className="text-white">Subject</TableCell>
              <TableCell className="text-white">Syllabus</TableCell>
              <TableCell className="text-white" align="center">
                Status
              </TableCell>
              <TableCell className="text-white" align="center">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questions?.map((row, index) => (
              <>
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Checkbox
                      disabled={row.status ? true : false}
                      checked={row.isChecked ? true : false}
                      onClick={() => handleChecked(row.id)}
                    />
                  </TableCell>
                  <TableCell>
                    <div dangerouslySetInnerHTML={{ __html: row.question }} />
                  </TableCell>
                  <TableCell>
                    <div dangerouslySetInnerHTML={{ __html: row.right_ans }} />
                  </TableCell>
                  <TableCell align="center">
                    <PopOver questions={row} />
                  </TableCell>
                  <TableCell align="center">{row.subject?.subName}</TableCell>
                  <TableCell align="center">{row.syllabus?.syllabus}</TableCell>
                  <TableCell align="center">
                    {!row.syllabus && (
                      <span className="bg-danger text-white px-1 rounded text-small">
                        Update Required
                      </span>
                    )}

                    {row.status == "0" && (
                      <span className="bg-warning text-white px-2 rounded text-small">
                        Updated
                      </span>
                    )}
                    {row.status == "1" && (
                      <span className="bg-success text-white px-2 rounded text-small">
                        Imported
                      </span>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <MenuButton
                      open={open}
                      handleClick={handleClick}
                      anchorEl={anchorEl}
                      handleClose={handleClose}
                      row={row}
                      getExelQuestion={getExelQuestion}
                    />
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
export default QuestionExcelTable;
