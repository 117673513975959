import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export const PaperAppUserSubscriptions = ({
  loading,
  userSubscriptions,
  ...props
}) => {
  console.log("what is in here", userSubscriptions);
  return (
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Sl No.</TableCell>
          <TableCell>Paper Name</TableCell>
          <TableCell align="center">Paper Description</TableCell>
          {/* <TableCell align="center">Last Name</TableCell>
        <TableCell align="center">Subscribtion Count</TableCell> */}
          {/* <TableCell align="center">Actions</TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        {!loading &&
          userSubscriptions &&
          userSubscriptions.data.length > 0 &&
          userSubscriptions.data.map(
            (row, index) =>
              row.paper && (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.paper.name}
                  </TableCell>
                  <TableCell align="center">{row.paper.description}</TableCell>
                  {/* <TableCell align="center">{row.lastName}</TableCell>
              <TableCell align="center">
                {row.subscriptions_count}
              </TableCell> */}
                </TableRow>
              )
          )}
      </TableBody>
    </Table>
  );
};

export const PackageAppUserSubscriptions = ({
  loading,
  userSubscriptions,
  ...props
}) => {
  return (
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Sl No.</TableCell>
          <TableCell>Package Name</TableCell>
          <TableCell align="center">Package Description</TableCell>
          {/* <TableCell align="center">Last Name</TableCell>
          <TableCell align="center">Subscribtion Count</TableCell> */}
          {/* <TableCell align="center">Actions</TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        {!loading &&
          userSubscriptions &&
          userSubscriptions.data.length > 0 &&
          userSubscriptions.data.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.package.name}
              </TableCell>
              <TableCell align="center">{row.package.description}</TableCell>
              {/* <TableCell align="center">{row.lastName}</TableCell>
                <TableCell align="center">
                  {row.subscriptions_count}
                </TableCell> */}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};
