import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
// import DialogDynamic from '../pannel/dialog';
// import { Button } from '@mui/material';
// import CheckboxesPrivilege from '../pannel/checkBoxesPrivilege';

// api
import api from "../../API/api";
import TablePagination from "@mui/material/TablePagination";
import API from "../../API/apiConfig";
import { IconButton, Menu, Stack, MenuItem, Box } from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import useBasicLoader from "../../hooks/useBasicLoader";
import AppUserSubcriberDetail from "./components/AppUserSubscriberDetail";

function AppUserSubscribers(props) {
  // state related to pagination
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [appUserSubscribers, setAppUserSubscribers] = useState(null);
  const { loading, changeLoading } = useBasicLoader();

  const [filters, setFilters] = useState({
    firstName: "",
    lastName: "",
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const open = Boolean(anchorEl);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
    setPerPage(parseInt(event.target.value, 10));
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    // don't change the selected value
    // on edit button or any other
    // there is a side effect when sub menu is clicked
    setSelectedValue(data);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickDetail = (data) => {
    // setSelectedValue(data);
    setOpenModal(true);
  };

  const getAppuserSubscribers = () => {
    changeLoading(true);

    api
      .getSubscriptionsForAllAppUsers({
        page: page,
        per_page: perPage,
        filters,
      })
      .then((res) => {
        if (res.data.success) {
          setAppUserSubscribers(res.data);
        }

        console.log(res.data);
        changeLoading(false);
      })
      .catch((err) => {
        changeLoading(false);
      });
  };

  useEffect(() => {
    getAppuserSubscribers(page, perPage, filters);
  }, [page, perPage, filters]);

  useEffect(() => {
    getAppuserSubscribers(page, perPage, filters);
  }, []);

  //   MODAL RELATED
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <div>
      <h2>AppUser Subscribers</h2>
      <section>
        {/* <div>{selectedValue ? selectedValue.email : null}</div> */}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sl No.</TableCell>
                <TableCell>Email</TableCell>
                <TableCell align="center">First Name</TableCell>
                <TableCell align="center">Last Name</TableCell>
                <TableCell align="center">Subscribtion Count</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                appUserSubscribers &&
                appUserSubscribers.app_users.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.email}
                    </TableCell>
                    <TableCell align="center">{row.firstName}</TableCell>
                    <TableCell align="center">{row.lastName}</TableCell>
                    <TableCell align="center">
                      {row.subscriptions_count}
                    </TableCell>

                    <TableCell align="right">
                      <Stack spacing={1} className="text-center">
                        <IconButton
                          aria-label="more"
                          onClick={(e) => handleClick(e, row)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Stack>

                      <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            // console.log("rjasasd", row);
                            handleClickDetail(row);
                          }}
                          sx={{
                            padding: 0,
                          }}
                        >
                          <Box
                            sx={{
                              minWidth: "100%",
                              padding: "0.3rem 1rem",
                            }}
                          >
                            Details
                          </Box>
                        </MenuItem>
                        {/* MODAL */}
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={appUserSubscribers?.total}
          rowsPerPage={appUserSubscribers?.per_page}
          page={appUserSubscribers?.current_page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <AppUserSubcriberDetail
          open={openModal}
          handleClose={handleCloseModal}
          handleOpen={handleOpenModal}
          selectedValue={selectedValue}
        />
      </section>
    </div>
  );
}
export default AppUserSubscribers;
