import React, { useEffect, useState } from 'react'
import { Button, Paper } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import api from '../../API/api';
import AppPackagesTable from "../table/appPackages"
import DialogModal from '../modal/modal';
import { TextField } from '@mui/material';
import Swal from 'sweetalert2';
import { LinearProgress } from '@mui/material';
import RichText from '../pannel/richtext'
import PaperBundle from './paperBundle';

import Bundles from '../table/bundle';

const AppPackages = (props) => {
    const [backdroploader, setBackdropLoader] = useState(false);
    const [loader, setLoader] = useState(false);
    const [desc , setDesc] = useState('')
    const [packageData, setPackageData] = useState({
        name: '',
        description: '',
        no_of_paper: '',
        no_of_syllabus: '',
        price: ''
    });
    const handleDesc = e => setDesc(e)
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = useState(false);
    const [listPcgk, setListPcgk] = useState();

    const [show, setShow]= useState('packages');

    const handleOpen = () => {
        setOpen(true); setPackageData({
            name: '',
            description: '',
            no_of_paper: '',
            no_of_syllabus: '',
            price: ''
        })
    };

    const handleClose = () => {
        setOpen(false);
    }

    const handleInput = (e) => {
        if (e) {
            setPackageData({
                ...packageData,
                [e.target.name]: e.target.value
            })
        }
    }

    useEffect(() => {
        setPackageData({
            ...packageData,
            description : desc && desc
        })
    },[desc])

    const handleEdit = (e) => {
        setPackageData({
            name: e.name,
            description: e.description,
            no_of_paper: e.no_of_paper,
            no_of_syllabus: e.no_of_syllabus,
            price: e.price,
            id: e.sl
        })
        setOpen(true);
    }

    const handleDisable = (e) => {

        api.packageStatus(e.sl).then(res => {
            console.log(res);
            if (res.status === 200) {
                Swal.fire(
                    'Success',
                    'Status Added',
                    'success'
                )
                getData()
            }
        }).catch(err => {
            console.log(err);
            Swal.fire(
                'Error',
                'Something went wrong',
                'error'
            )
        })
    }

    const submitValue = () => {
        // console.log(packageData,desc,'packageData');
        // return;
        if (packageData.name.trim().length === 0) {
            Swal.fire(
                'Error',
                'Name cannot be empty',
                'error'
            )
            return;
        }
        if (packageData.description.trim().length === 0) {
            Swal.fire(
                'Error',
                'Description cannot be empty',
                'error'
            )
            return;
        }
        if (packageData.no_of_paper === '') {
            Swal.fire(
                'Error',
                'Paper Number cannot be empty',
                'error'
            )
            return;
        }
        if (packageData.no_of_syllabus === '') {
            Swal.fire(
                'Error',
                'Syllabus Number cannot be empty',
                'error'
            )
            return;
        }
        if (packageData.price === '') {
            Swal.fire(
                'Error',
                'Price Number cannot be empty',
                'error'
            )
            return;
        }
        api.addPackages(packageData).then(res => {
            console.log(res);
            if (res.data.status) {
                Swal.fire(
                    'Success',
                    'Package Added Succesfully',
                    'success'
                )
                getData()
            }
        }).catch(err => {
            console.log(err);
        })
    }


    const getData = () => {
        setBackdropLoader(true)
        api.getPackage().then(res => {
            // console.log(res);
            setListPcgk(res.data.packageList)
            setLoader(false);
            setBackdropLoader(false)
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        setLoader(true);
        getData()
    }, [])

    
    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdroploader}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <section>
                <div className='row'>
                    <Paper>
                        <div className='my-2 d-flex justify-content-between'>
                            <div>
                                <h3>Package Master </h3>
                            </div>
                            <div>
                                <Button onClick={()=> setOpen2(true)} variant="contained" style={{ 'float': 'left' }} size="small"> + Add Paper Bundle</Button>
                                &nbsp;
                                <Button onClick={handleOpen} variant="contained" style={{ 'float': 'right' }} size="small"> + Add package</Button>
                            </div>
                        </div>
                    </Paper>
                    <DialogModal
                        open={open} setOpen={setOpen} handleCloseMove={handleClose}
                        width="lg"
                        form={<Master handleInput={handleInput} submitValue={submitValue}
                            packageData={packageData} handleCloseMove={handleClose}  handleDesc={handleDesc}/>}
                    />
                    <DialogModal
                        open={open2} setOpen={2} handleCloseMove={()=>setOpen2(false)}
                        width="lg"
                        form={<PaperBundle />
                        }
                    />
                </div>

                <div>
                    {
                        loader &&
                        <LinearProgress color="secondary" />
                    }
                    <div className="row border rounded">
                        <div className='m-2'>
                            <button className={`${show === 'packages' && 'active'} btn btn-outline-success btn-sm`} onClick={()=> setShow('packages')}>Packages</button> &nbsp;
                            <button className={`${show === 'bundles' && 'active'} btn btn-outline-success btn-sm`} onClick={()=> setShow('bundles')}>Bundles</button>
                        </div>
                        {show === 'bundles' ? 
                        <div className="col-12">
                            <div style={{ margin: 5 }}>
                                <Bundles 
                                />
                            </div>
                        </div>
                        :
                        <div className="col-12">
                            <div style={{ margin: 5 }}>
                                <AppPackagesTable
                                    listPcgk={listPcgk}
                                    handleEdit={handleEdit}
                                    handleDisable={handleDisable}
                                />
                            </div>
                        </div>
}
                    </div>
                </div>


            </section>
        </>
    )
}

const Master = ({ handleInput, submitValue, packageData,handleDesc }) => {
    // console.log(packageData, 'value');
    return (
        <div
            className='w-100'
        >
            <div
                className='row'
            >
                {/* <TextField id="filled-basic" label="Description" variant="filled"
                    onChange={(e) => handleInput(e)} name="description"
                    value={packageData?.description}
                /> */}
                <p>Package Master Upload</p>
                <div
                    className='row'
                >
                    <div className='col-lg-6'>
                        <TextField id="filled-basic" label="Name" variant="filled"
                            onChange={(e) => handleInput(e)} name="name"
                            value={packageData?.name} className='w-100'
                        />
                    </div>
                    <div className='col-lg-6'>
                        <TextField id="filled-basic" label="Package Amount ₹" variant="filled"
                            onChange={(e) => handleInput(e)} name="price"
                            value={packageData?.price} className='w-100'
                        />
                    </div>
                    <div className='col-lg-6'>
                        <TextField id="filled-basic" label="No. of Paper user  will get" variant="filled"
                            onChange={(e) => handleInput(e)} name="no_of_paper"
                            value={packageData?.no_of_paper} className='w-100'
                        />
                    </div>
                    <div className='col-lg-6'>
                        <TextField id="filled-basic" label="No.  of Syllabus user  will get" variant="filled"
                            onChange={(e) => handleInput(e)} name="no_of_syllabus"
                            value={packageData?.no_of_syllabus} className='w-100'
                        />
                    </div>
                </div>
                <div
                    className='my-4'
                >
                    <b>Package Description</b>
                    <RichText name="description"
                        value={packageData.description}  
                        handleForm={(e) => handleDesc(e)} 
                    />
                </div>
                <Button
                    variant="contained"
                    onClick={submitValue}
                >
                    Submit
                </Button>
            </div>
        </div>
    )
}


export default AppPackages;