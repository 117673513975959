import { Box, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";

const UserDetailPurchaseItem = ({ purchase }) => {
  const theme = useTheme();
  const isBelowLGScreen = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box
      key={purchase.id}
      sx={{
        flex: 1,
        maxWidth: isBelowLGScreen ? "100%" : "50%",
        minWidth: isBelowLGScreen ? "100%" : "50%",
        backgroundColor: "inherit",
        p: 1,
      }}
    >
      <Paper
        elevation={2}
        sx={{
          p: 2,
          backgroundColor: "white",
          maxHeight: "13rem",
          minHeight: "13rem",
          width: "100%",
          overflowY: "auto",
        }}
      >
        <Typography variant="body1">
          Item: {purchase.item_type}, Item ID: {purchase.item_id}
        </Typography>
        <Typography variant="h6">Item Name: {purchase.name}</Typography>
        <Typography variant="body1">Price: {purchase.price}</Typography>
        <Typography variant="body1">
          Purchased At:{" "}
          {new Date(purchase.created_at).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </Typography>
        <Typography variant="body1">
          Transaction ID: {purchase.transaction_uuid}
        </Typography>
      </Paper>
    </Box>
  );
};

export default UserDetailPurchaseItem;
