import React, { useState, useMemo } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import CustomTableStatic from "../../table/CustomTableStatic";
import CloseIcon from "@mui/icons-material/Close";

const InvalidUsersModalTable = ({ open, handleClose, handleOpen, data }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterText, setFilterText] = useState("");

  /**
   * @type {import('../../table/custom_table__jsdoc_types').TableHeadItems}
   */
  const tableHeads = useMemo(
    () => [
      { id: "id", label: "ID", editable: false },
      { id: "name", label: "Name" },
      { id: "email", label: "Email" },
      { id: "phone_number", label: "Phone Number" },
      { id: "reason", label: "Invalid Reason", editable: false },
    ],
    []
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = useMemo(() => {
    return data.filter((item) => {
      let fullName = "";
      if (item.f_name && item.l_name) {
        fullName = `${item.f_name} ${item.l_name}`.toLowerCase();
      } else if (item.f_name) {
        fullName = item.f_name.toLowerCase();
      } else if (item.l_name) {
        fullName = item.l_name.toLowerCase();
      }

      let email = "";
      if (item.email) {
        email = item.email.toLowerCase();
      }
      const searchTerm = filterText.toLowerCase();
      return fullName.includes(searchTerm) || email.includes(searchTerm);
    });
  }, [data, filterText]);

  const paginatedData = useMemo(() => {
    const startIndex = page * rowsPerPage;
    let rawData = filteredData.slice(startIndex, startIndex + rowsPerPage);

    rawData = rawData.map((item) => {
      return {
        id: item.id,
        name: `${item.f_name} ${item.l_name}`,
        email: item.email ?? "N/A",
        phone_number: item.phone_number ?? "N/A",
        reason: item.error,
      };
    });

    return rawData;
  }, [filteredData, page, rowsPerPage]);

  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
    setPage(0); // Reset to first page when filter changes
  };

  return (
    <div>
      <Button onClick={handleOpen}>Open Invalid Users Modal</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="invalid-users-modal-title"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: "1000px",
            height: "80vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography
              id="invalid-users-modal-title"
              variant="h6"
              component="h2"
            >
              Invalid Users
            </Typography>
            <IconButton onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
            label="Filter by Name or Email"
            variant="outlined"
            value={filterText}
            onChange={handleFilterChange}
            sx={{ mb: 2 }}
          />
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <CustomTableStatic
              tableHeads={tableHeads}
              tableRows={paginatedData}
              tablePagination={{
                total: filteredData.length,
                per_page: rowsPerPage,
                page: page,
              }}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              loading={false}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default InvalidUsersModalTable;
