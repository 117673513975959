import * as React from "react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import IndividualUserSubscriberTable from "./IndividualUserSubscriberTable";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  minHeight: "80vh",
  minWidth: "80vw",
  maxHeight: "80vh",
  maxWidth: "80vw",
  overflowY: "auto",
};

function CustomTabPanel(props) {
  const { children, value, index, user, subscriptionType, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <IndividualUserSubscriberTable
            user={user}
            subscriptionType={subscriptionType}
          />
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AppUserSubcriberDetail({
  open,
  handleClose,
  handleOpen,
  selectedValue,
}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  console.log(selectedValue);
  return (
    <div>
      {selectedValue && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/*  */}
            <Box>
              <Typography variant="h4">
                {selectedValue.firstName} {selectedValue.lastName}
              </Typography>
            </Box>
            <hr />
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Papers" {...a11yProps(0)} />
                  <Tab label="Packages" {...a11yProps(1)} />
                  {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                </Tabs>
              </Box>
              <CustomTabPanel
                value={value}
                index={0}
                user={selectedValue}
                subscriptionType="paper"
              >
                Item One
              </CustomTabPanel>
              <CustomTabPanel
                value={value}
                index={1}
                user={selectedValue}
                subscriptionType="package"
              >
                Item Two
              </CustomTabPanel>
              {/* <CustomTabPanel value={value} index={2}>
                Item Three
              </CustomTabPanel> */}
            </Box>
          </Box>
        </Modal>
      )}
    </div>
  );
}
