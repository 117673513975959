import React, { useState, useEffect } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import api from '../API/api';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const TestPage = () => {

    const [question, showQuestion] = useState([]);

    const [loading, setLoading] = useState(true);


    useEffect(() => {
        viewQuestions()
    }, [])

    const viewQuestions = () => {
        api.getQuestion()
            .then((res) => {
                console.log(res);
                showQuestion(res.data.question);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err.response);
            })
    }

    const Loading = () => {
        return (
            <>
                <div className='container p-5'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="p-4 row justify-content-center">
                                <div className='col-md-6'>
                                    <Skeleton height={35} count={1} style={{ marginBottom: 6 }} />
                                </div>
                                <div className="col-md-8 my-2">
                                    <Skeleton height={30} count={4} style={{ marginBottom: 6 }} />
                                </div>
                                <div className='col-md-6'>
                                    <Skeleton height={35}  count={1} style={{ marginBottom: 6 }} />
                                </div>
                                <div className="col-md-8 my-2">
                                    <Skeleton height={30}  count={4} style={{ marginBottom: 6 }} />
                                </div>
                                <div className='col-md-6'>
                                    <Skeleton height={35}  count={1} style={{ marginBottom: 6 }} />
                                </div>
                                <div className="col-md-8 my-2">
                                    <Skeleton height={30}  count={4} style={{ marginBottom: 6 }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }


    return (
        <>
            {loading ? <Loading />
                :
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className='test bg-white p-5'>
                            <FormControl>
                                {question.map((value) => (
                                    <>
                                        <FormLabel id="demo-radio-buttons-group-label">
                                            <strong>{value.question}</strong>
                                        </FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            name="radio-buttons-group"
                                            className='my-3'
                                        >
                                            {value.ans.map((answer) => (

                                                <FormControlLabel value={answer.ans_id} control={<Radio />} label={answer.answer} />
                                            ))}
                                        </RadioGroup>

                                    </>
                                ))}
                            </FormControl>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default TestPage;