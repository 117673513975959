import React, { useState, useEffect, useRef } from 'react'
import Form from 'react-bootstrap/Form';
import { Paper, Select, FormControl, InputLabel, MenuItem } from '@mui/material';
// import { Button } from '@mui/material';
import RichText from '../pannel/richtext';
import api from '../../API/api';
import Swal from 'sweetalert2';
import DialogModal from '../modal/modal';
import ChangeAns from './changeAns';
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import 'mathlive';
import Comprehension from './comprehension';


function NewFormQuestion(props) {
    const [input, setInput] = useState('');
    const [tags, setTags] = useState([]);
    const [show, setShow] = useState(false);
    const [modalData, setModalData] = useState();
    const [value, setValue] = useState();
    const [isMathImage, setIsMathImage] = useState(1)
    const [open, setOpen] = useState(false);
    const [standard, setStandard] = useState("1");
    const [mathAns, setMathAns] = useState({
        // right_ans   : '',
        w_ans1: '',
        w_ans2: '',
        w_ans3: ''
    });

    useEffect(() => {
        setMathAns({
            w_ans1: tags[0],
            w_ans2: tags[1],
            w_ans3: tags[2]
        })

    }, [tags.length]);

    let handleMathQues = (evt) => {
        setValue(evt.target.value)
        props.handleQuestion(evt.target.value)
    }

    useEffect(() => {
        if (props.questionForm.ans_type == "3") {
            setTags([mathAns.w_ans1, mathAns.w_ans2, mathAns.w_ans3])
        }
    }, [mathAns]);

    const handleOpenModal = (preview) => {
        setOpen(true);
        setModalData(preview)
    }

    const handleCloseModal = () => {
        setOpen(false)
    }

    useEffect(() => {
        if (props.all) {
            let das = [];
            var a = props.all.ans.map(el => {
                if (el.isRight === 0) {
                    das.push(el.answer)
                }
            }
            );
            setTags(das);
            console.log("dass==>>", das);
        }
    }, [props.all])


    const showDesc = () => {
        setShow(!show);
    }

    const onChange = (e) => {
        const { value } = e.target;
        setInput(value);
    };

    const onKeyDown = (e) => {
        // console.log("ooo");
        const { key } = e;
        const trimmedInput = input.trim();

        if (key === 'Enter' && trimmedInput.length && !tags.includes(trimmedInput)) {
            console.log("working", e);
            e.preventDefault();
            setTags(prevState => [...prevState, trimmedInput]);
            setInput('');
        }
    };

    const handleRemoveAns = (tag) => {
        let filterData = tags.filter(e =>
            e !== tag
        )
        setTags(filterData);
    }

    const handleSubmit = () => {
        // console.log(formValues, tags);
        console.log("tags==>>", tags, formValues);
        // return ;
        props.handleSubmit(tags && tags, formValues)


    }

    useEffect(() => {
        if (props.type2 === 'excel') {
            setTags(current => [...current, props.questionForm.w_ans1, props.questionForm.w_ans2, props.questionForm.w_ans3, props.questionForm.w_ans4])
            return;
        }
        console.log(tags && tags, 'tags');
    }, [props.questionForm.w_ans1])


    // handleDraft
    const handleDraft = (wrongAns) => {
        console.log("handleDraft", wrongAns);
        console.log("formValues", formValues);
        console.log("props.questionForm", props.questionForm);
        // return;
        api.EditExelQuestion(props.questionForm, wrongAns, formValues, 'draft').then((res) => {
            if (res.data.status == 200) {
                Swal.fire({
                    title: 'Updated',
                    text: 'Exel Question Updated Successfully ',
                    icon: 'success'
                });
            }
            else {
                Swal.fire({
                    title: 'Opss',
                    text: 'Something went wrong ! ',
                    icon: 'error'
                });
            }
        })
            .catch((err) => { console.log(err.response); })
    }

    // *****************

    useEffect(() => {
        if (props.type === 'edit') {
            let newarry = [];

            props.all?.ans.map((elem, index) => {
                console.log(elem);
                newarry.push({
                    type: elem.isRight,
                    preview: elem.answer,
                    ans: elem.answer,
                    ans_id: elem.ans_id,
                    q_id: elem.q_id
                })
            })
            setFormValues(newarry);
        }
    }, [props.all])


    const [formValues, setFormValues] = useState([
        {
            type: 1,
        },
        {
            type: 0,
        },
        {
            type: 0,
        },
        {
            type: 0,
        },
    ])

    let handleChange = (i, e) => {
        console.log(i, parseInt(e.target.value));
        let newFormValues = [...formValues];
        newFormValues[i]['type'] = parseInt(e.target.value);
        setFormValues(newFormValues);
    }

    let handleChangeImg = (i, e) => {
        console.log("i=>", i);
        console.log("e=>", e.target.files[0]);
        console.log("e2=>", URL.createObjectURL(e.target.files[0]));
        // console.log(i, e);
        let newFormValues = [...formValues];
        newFormValues[i]['id'] = i;
        newFormValues[i]['ans_id'] = '';
        newFormValues[i]['ans'] = e.target.files[0];
        // newFormValues[i][e.target.name] = e.target.files[0];
        newFormValues[i]['preview'] = URL.createObjectURL(e.target.files[0]);
        setFormValues(newFormValues);
    }

    let addFormFields = () => {
        setFormValues([...formValues, { type: 0 }])
    }

    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }

    const handleChangeMathAnsType = (reqst) => {
        if (reqst === 1) {
            setIsMathImage(1)
        } else {
            setIsMathImage(0)
        }
    }


    useEffect(() => {
        // console.log(tags,'  tagss');
        props.handleQuestion(value)
    }, [value])

    return (
        <>
            <div className='col-12 mb-2'>
                <div className='d-flex justify-content-end'>
                    <div className='mx-2'>
                        Standard
                    </div>
                    <div className='mx-2'>
                        <select name="standard" id="" value={standard} onChange={(e) => setStandard(e.target.value)}>
                            <option value="1">Normal</option>
                            <option value="2">Comprehension</option>
                            <option value="3">Group</option>
                        </select>
                    </div>
                </div>
            </div>

            <hr />
            {standard === "1" &&
                <div className="row">

                    <Form.Group className="col-md-3 mb-4 mt-2">
                        <Form.Label>Difficulties </Form.Label>
                        <Form.Select name='difficulties'
                            onChange={props.handleForm} value={props.questionForm.difficulties}
                        >
                            <option value=''>--select--  </option>
                            <option value='1'>Easy </option>
                            <option value='2'>Medium </option>
                            <option value='3'>Hard </option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="col-md-3 mb-4 mt-2">
                        <Form.Label>Class</Form.Label>
                        <Form.Select name='class'
                            onChange={props.handleForm} value={props.questionForm.class}
                        >
                            <option value=''>--select--  </option>
                            <option value='8'>Class 8 </option>
                            <option value='9'>Class 9 </option>
                            <option value='10'>Class 10 </option>
                            <option value='11'>Class 11 </option>
                            <option value='12'>Class 12 </option>
                            <option value='13'>UG</option>
                            <option value='14'>PG</option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="col-md-3 mb-4 mt-2">
                        <Form.Label> Select Subject </Form.Label>

                        {/* {
                        props.type === 'edit' ?
                            <Form.Select name='subject_id'
                                onChange={props.handleChangeSubject} value={props.questionForm.subject_id}
                            >
                                <option  value='' >--select-- </option>
                                <option value={props.subject?.id}>{props.subject?.subName}</option>
                            </Form.Select>
                            : */}
                        <>
                            <Form.Select name='subject_id'
                                onChange={props.handleChangeSubject} value={props.questionForm.subject_id}
                            >
                                <option value=''>--select-- </option>
                                {props.subject?.map((sub, index) => (
                                    <option key={index} value={sub.id}>{sub.subName}</option>
                                ))}
                            </Form.Select>
                        </>
                        {/* } */}

                    </Form.Group>

                    <Form.Group className="col-md-3 mb-4 mt-2">
                        <Form.Label>Select Syllabus</Form.Label>
                        <Form.Select name='syllabus_id'
                            onChange={props.handleForm} value={props.questionForm.syllabus_id}
                        >
                            <option value=''>--select-- </option>
                            {props.syllabus && props.syllabus.map((syl) => (
                                <option key={syl.id} value={syl.id}>{syl.syllabus}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    {props.type !== 'edit' &&
                        <div className='col-12 row align-items-center'>
                            <Form.Group className="col-md-4 mb-5 mt-3">
                                <Form.Label>Select Answer Type</Form.Label>
                                <Form.Select name="ans_type" value={props.questionForm.ans_type} onChange={props.handleForm}>
                                    <option value=""  >----Select Below----</option>
                                    <option value="1" >Normal Text</option>
                                    <option value="2" >Image Answers</option>
                                    <option value="3" >Mathematics</option>
                                </Form.Select>
                            </Form.Group>
                        </div>
                    }

                    {
                        props.questionForm.ans_type == "3" ?
                            <>
                                <Form.Group className="mb-2" >
                                    <Form.Label>Enter Question</Form.Label>
                                </Form.Group>
                                <math-field
                                    onInput={handleMathQues}
                                // onInput={evt => { setValue(evt.target.value) }}
                                // onKeyDown={onKeyDown}
                                // ref={mf}
                                >
                                    {/* {value} */}
                                    {props.questionForm?.question}
                                </math-field>
                            </>
                            :
                            <Form.Group className="mb-5">
                                <Form.Label>Enter Question {props.questionForm.ans_type} </Form.Label>
                                <RichText name="question"
                                    handleForm={props.handleQuestion} value={props.questionForm?.question}
                                />
                            </Form.Group>
                    }



                    {
                        props.questionForm.ans_type == "1" &&
                        <>
                            <Form.Group className="mb-5">
                                <Form.Label>Right Answer </Form.Label>
                                <RichText name=""
                                    handleForm={props.handleRightAns} value={props.questionForm.right_ans}
                                />
                            </Form.Group>
                            <Form.Group className="col-md-12 mb-5">
                                <Form.Label>Wrong Answersss ( <span className='text-danger'> Press Enter key for add more wrong answers, minimum 3 required </span> )</Form.Label>
                                <input
                                    value={input}
                                    className='form-control'
                                    placeholder=""
                                    onKeyDown={onKeyDown}
                                    onChange={onChange}
                                />
                                {tags?.map((tag, index) =>
                                    <Paper>
                                        <div className="tag" >
                                            <div className='m-1 p-2'>
                                                {index + 1}) {tag}
                                                <span className='float-end'> <button className='btn btn-danger btn-sm' onClick={() => handleRemoveAns(tag)}> x </button></span>
                                            </div>
                                        </div>
                                    </Paper>

                                )}
                            </Form.Group>
                        </>
                    }
                    {
                        props.questionForm.ans_type == "2" &&
                        <>
                            {props.type !== 'edit' &&
                                <div className="col-10">
                                    {formValues.map((element, index) => (
                                        <div className="row py-2" key={index}>
                                            <div className="col-md-7">
                                                <Form.Label>Answer Image/Graphic</Form.Label>
                                                <input type="file" accept='.png, .jpg, .jpeg' className='form-control' name={`ans${index}`} onChange={e => handleChangeImg(index, e)} />
                                            </div>
                                            <div className="col-md-3">

                                                <Form.Label>Right/Wrong</Form.Label>
                                                <select className='form-control' name="type" value={element.type ? element.type : index === 0 ? 1 : 0} onChange={e => handleChange(index, e)}>
                                                    <option value="">Select</option>
                                                    <option value="1">Right</option>
                                                    <option value="0">Wrong</option>
                                                </select>
                                            </div>

                                            <div className="col-md-2">

                                                {
                                                    index ?
                                                        <><br />
                                                            <button type="button" className="btn btn-danger remove" onClick={() => removeFormFields(index)}>Remove</button>
                                                        </>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                    ))}

                                    <div className="button-section">
                                        <button className="btn btn-outline-secondary btn-sm mt-3" type="button" onClick={() => addFormFields()}>+ Add More Answers</button>
                                    </div>
                                </div>
                            }


                            <div className="row  justify-content-center">
                                {formValues.map((preview) => (
                                    <>
                                        {preview.preview &&
                                            <div className={`col-2`}>
                                                <div>
                                                    <div className='ans-edit'>
                                                        <button onClick={() => handleOpenModal(preview)} className='my-3 mx-0 hover-pencil  bg-none'  >
                                                            <ModeEditTwoToneIcon style={{ color: '#fff' }} />
                                                        </button>
                                                    </div>

                                                    <div className={`questionPreview border ${preview.type === 1 ? 'border-success' : 'border-danger'} `}>
                                                        <div>
                                                            {!preview.id && preview.id !== 0 ?
                                                                <>
                                                                    <img src={`${process.env.REACT_APP_BACKEND}answers/img/${preview.preview}`} className='col-12' alt="" />
                                                                </>
                                                                :
                                                                <>
                                                                    <img src={preview.preview} className='col-12' alt="" />
                                                                </>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="text-center">
                                                        {preview.type === 1 ?
                                                            <span className='text-success'>Right Answer</span> : <span className='text-danger'>Wrong Answer</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        }
                                    </>
                                ))}
                            </div>


                            <DialogModal form={<ChangeAns data={modalData} handleCloseMove={handleCloseModal} />} open={open} handleOpenModal={handleOpenModal} handleCloseMove={handleCloseModal} />

                        </>
                    }
                    {props.questionForm.ans_type == "3" &&

                        <>
                            {/* maths answers */}
                            <Form.Group className="mb-2">
                                <Form.Label>Right Answer</Form.Label>
                                <math-field
                                    // onInput={evt => { setMathAns({
                                    //     ...mathAns,
                                    //     right_ans:evt.target.value
                                    // }) }}
                                    onInput={props.handleRightAns}
                                // value={props.questionForm.right_ans}
                                >
                                    {props.questionForm.right_ans}
                                </math-field>
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Label>Wrong Answer one</Form.Label>
                                <math-field

                                    onInput={evt => {
                                        setMathAns({
                                            ...mathAns,
                                            w_ans1: evt.target.value
                                        })
                                    }}

                                // onInput={evt => { setTags([
                                //     ...tags, evt.target.value
                                // ]) }}

                                >
                                    {/* {tags[0]} */}
                                    {mathAns.w_ans1}
                                </math-field>
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Label>Wrong Answer two</Form.Label>
                                <math-field
                                    onInput={evt => {
                                        setMathAns({
                                            ...mathAns,
                                            w_ans2: evt.target.value
                                        })
                                    }}

                                >
                                    {mathAns.w_ans2}
                                </math-field>
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Label>Wrong Answer three</Form.Label>
                                <math-field

                                    onInput={evt => {
                                        setMathAns({
                                            ...mathAns,
                                            w_ans3: evt.target.value
                                        })
                                    }}

                                >
                                    {mathAns.w_ans3}
                                </math-field>
                            </Form.Group>
                        </>
                    }

                    {/* <Form.Group className="my-5">
                        <Form.Label>Answer Description</Form.Label>
                        <RichText name=""
                            handleForm={props.handleAnsDesc} value={props.questionForm.ans_desc}
                        />
                    </Form.Group> */}
                    {/* // to check whether ans desc has image or text */}
                    <div
                        style={{ width: '100%' }}
                    >
                        {
                            props.questionForm.ans_type == "3" &&
                            <div  style={{ marginLeft : 'auto', textAlign : 'right'}}>
                                <FormControl  style={{minWidth: 150,}}>
                                    <InputLabel id="demo-simple-select-label">Has Image or Text {props.questionForm.desc_type}</InputLabel>
                                    <Select
                                        value={props.questionForm.desc_type}
                                        label="Has Image or Text"
                                        onChange={props.handleForm}
                                        name='desc_type'
                                    >
                                        <MenuItem value={1}>Math Text</MenuItem>
                                        <MenuItem value={0}>Image</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        }
                    </div>
                    {
                        props.questionForm.desc_type === 1 ?
                            <Form.Group className="mb-2">
                                <Form.Label>Answer Description</Form.Label>
                                <math-field
                                    onInput={evt => {
                                        props.handleAnsDesc(evt.target.value)
                                    }}
                                >
                                    {props.questionForm.ans_desc}
                                </math-field>
                            </Form.Group>
                            :
                            <Form.Group className="my-5">
                                <Form.Label>Answer Description</Form.Label>
                                <RichText name=""
                                    handleForm={props.handleAnsDesc} value={props.questionForm.ans_desc}
                                />
                            </Form.Group>
                    }


                    {tags &&
                        <div className='text-center'>
                            {/* add */}
                            {
                                props.all &&
                                <button className='btn btn-success' onClick={handleSubmit}>Update Question</button>

                            }
                            {/* ex excel */}

                            {!props.all &&
                                <button className='btn btn-success me-2' onClick={handleSubmit}>Submit Question</button>
                            }

                            {/* edit excel */}

                            {props.type2 === 'excel' &&
                                <button className='btn btn-success' onClick={handleDraft}>Update and Import</button>
                            }
                        </div>
                    }
                </div>
            }

            {standard === "2" &&
                <>
                    <Comprehension subjects={props.subject} syllabus={props.syllabus} handleChangeSubject={props.handleChangeSubject} />
                </>
            }
        </>
    );
}

export default NewFormQuestion;