import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
// import DialogDynamic from '../pannel/dialog';
// import { Button } from '@mui/material';
// import CheckboxesPrivilege from '../pannel/checkBoxesPrivilege';

// api
import api from "../../API/api";
import TablePagination from "@mui/material/TablePagination";
import API from "../../API/apiConfig";
import { IconButton, Menu, Stack, MenuItem } from "@mui/material";
import NewsLetterSubscribersEditModal from "./component/newsletterSubscriberEditModal";
import MoreVertIcon from "@mui/icons-material/MoreVert";

function NewsletterSubscribersTable(props) {
  // state related to pagination
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [newsletterSubscribers, setNewsletterSubscribers] = useState([]);

  const [filters, setFilters] = useState({
    firstName: "",
    lastName: "",
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const open = Boolean(anchorEl);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
    setPerPage(parseInt(event.target.value, 10));
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedValue(data);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickEdit = (data) => {
    setSelectedValue(data);
  };

  const getSubscribers = () => {
    API.post("public/newsletter/subscribers", {
      page: page,
      per_page: perPage,
      filters: filters,
    }).then((response) => {
      const data = response.data;
      console.log(data, "for the glory");
      if (data.success) {
        // so from the backend the the data is sent as data so
        // ???
        setNewsletterSubscribers(data.data); // epic naming
      }

      console.log("subscribers", data);
    });
  };

  useEffect(() => {
    getSubscribers(page, perPage, filters);
  }, [page, perPage, filters]);

  useEffect(() => {
    getSubscribers(page, perPage, filters);
  }, []);
  return (
    <div>
      <h2>Newsletter Subscribers</h2>
      <section>
        <div>
          {
            // selectedValue ? selectedValue.email : null
          }
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Sl No.</TableCell>
                <TableCell>Email</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Mobile</TableCell>
                <TableCell align="center">State</TableCell>
                <TableCell align="center">City</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newsletterSubscribers && newsletterSubscribers.newsletters &&
                newsletterSubscribers.newsletters.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.email}
                    </TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.mobile}</TableCell>
                    <TableCell align="center">{row.state}</TableCell>
                    <TableCell align="center">{row.city}</TableCell>

                    <TableCell align="right">
                      <Stack spacing={1} className="text-center">
                        <IconButton
                          aria-label="more"
                          onClick={(e) => handleClick(e, row)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Stack>

                      <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            // console.log("rjasasd", row);
                            // handleClickEdit(row);
                          }}
                          sx={{
                            padding: 0,
                          }}
                        >
                          <NewsLetterSubscribersEditModal
                            subscriberDetails={selectedValue}
                          />
                        </MenuItem>
                        <MenuItem onClick={() => {}}>Delete</MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={newsletterSubscribers?.total}
          rowsPerPage={newsletterSubscribers?.per_page}
          page={newsletterSubscribers?.current_page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </section>
    </div>
  );
}
export default NewsletterSubscribersTable;
