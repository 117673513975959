import { Box, Paper, Stack, Typography, Modal } from "@mui/material";
import useBasicLoader from "../../../../hooks/useBasicLoader";

// table related imports
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import SkeletonList from "./skeleton-list";
import api from "../../../../API/api";
import useMUIModalState from "../../../../hooks/useMUIModalState";

import LivePaperUserInfo from "./live-paper.userinfo";

const LivePaperLeaderboard = ({ selectedLivePaper }) => {
  // state for leaderboard users
  const [users, setUsers] = useState([]);
  const [selectedData, setSelectedData] = useState(null);

  // loading state for loading users
  const { loading, changeLoading } = useBasicLoader(true);

  // modal states
  const { open, handleClose, handleOpen, handleSelectedValue } =
    useMUIModalState();

  const getLeaderboardUsers = () => {
    changeLoading(true);
    api
      .listLivePaperUsers({
        params: {
          paper_id: selectedLivePaper.id,
        },
      })
      .then((res) => {
        console.log(res, "waht does the repsonse says");
        setUsers(res.data.data);
        changeLoading(false);
      });
  };

  const handleUserRowClick = (user) => {
    setSelectedData(user);
    handleSelectedValue(user);
    handleOpen();
  };

  useEffect(() => {
    if (selectedLivePaper) {
      getLeaderboardUsers();
    }
  }, [selectedLivePaper]);

  return (
    <Stack flex={1} direction={"column"} height="85vh">
      <Paper sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Typography sx={{ px: 2, pt: 2 }} variant="h5">
          Leaderboard For
        </Typography>
        <Typography sx={{ px: 2, pb: 1 }} variant="h6">
          {selectedLivePaper ? selectedLivePaper.name : "No Paper Selected"}
        </Typography>
        <TableContainer component={Box} sx={{ flexGrow: 1, overflowY: "auto" }}>
          <Table aria-label="simple table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Sl.</TableCell>
                <TableCell>User Name</TableCell>
                <TableCell>Score</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <SkeletonList rowsNum={10} rowCol={3} />
              ) : (
                users.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                    onClick={() => handleUserRowClick(row)}
                    selected={selectedData === row}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell component="th" scope="row">
                      {row.user.f_name} {row.user.l_name}
                    </TableCell>
                    <TableCell>
                      {row.total_right_answers} / {row.paper.tot_questions}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <LivePaperUserInfo
        selectedData={selectedData}
        open={open}
        handleClose={handleClose}
      />
    </Stack>
  );
};

export default LivePaperLeaderboard;
