import React, { useEffect, useState } from 'react'
import AddQuestion from '../form/addQuestion';
import { Button } from '@mui/material';
import { Paper } from '@mui/material';
import api from '../../API/api';
import Swal from 'sweetalert2';
import LinearWithValueLabel from '../pannel/progressbar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useParams, useLocation } from "react-router-dom"
import PreviewQuestion from '../pannel/previewQuestion';
import EditQuestions from '../question/editQuestions';
import ExcelEditQusetions from '../form/ExcelEdit';
import NewFormQuestion from '../form/newformQuestion';


const EditExcelQuestions = (props) => {
    const navigation = useNavigate();
    const location = useLocation();
    console.log('location datat22', location);


    const prams = location.state;
    const sub_id = prams;
    const sub_name = prams;
    const syl_id = prams.syllabus_id;
    console.log('prams props 2', prams);


    const [subject, setSubject] = useState();
    const [syllabus, setSyllabus] = useState();
    const [preview, setPreview] = useState(false);

    const [backdroploader, setBackdropLoader] = React.useState(false);
    const handleClose = () => {
        setBackdropLoader(false);
    };
    const handleOpen = () => {
        setBackdropLoader(true);
    };
    // *************
    const [wrongAns, setWrongAns] = useState([]);
    const [question, setQuestion] = useState();
    const [rightans, setRightans] = useState('');
    const [ansDesc, setAnsDesc] = useState('');
    const [questionForm, setQuestionForm] = useState({
        id: location.state.id,
        ans_desc: location.state.ans_desc,
        difficulties: location.state.difficulties,
        class: location.state.class,
        question: location.state.question,
        right_ans: location.state.right_ans,
        subject_id: location.state.subject_id,
        syllabus_id: location.state.syllabus_id,
        subName: location.state.subject.subName,
        w_ans1: location.state.w_ans1,
        w_ans2: location.state.w_ans2,
        w_ans3: location.state.w_ans3,
        w_ans4: location.state.w_ans4,
        status: location.state.status,
        added_by: location.state.added_by,
    })

    const calcelPreview = () => {setPreview(false);}

    const handleForm = (e) => {
        // console.log(e);
        if (e.target) {
            // console.log(e.target.value);
            setQuestionForm({
                ...questionForm,
                [e.target.name]: e.target.value
            })
        }

    }

    const handleQuestion = (e) => {setQuestion(e)}
    const handleRightAns = (e) => {setRightans(e)}
    const handleAnsDesc = (e) => {setAnsDesc(e)}


    useEffect(() => {
        getSubject();
    }, [prams])

    useEffect(() => {
        setQuestionForm({
            ...questionForm,
            question: question
        })
    }, [question]);

    useEffect(() => {
        setQuestionForm({
            ...questionForm,
            right_ans: rightans
        })
    }, [rightans]);

    useEffect(() => {
        setQuestionForm({
            ...questionForm,
            ans_desc: ansDesc
        })
    }, [ansDesc]);

    useEffect(() => {
        if (location.state.subject_id) {
            getSyllabusBySub(location.state.subject_id);
        }
    }, []);


    const getSubject = () => {
        setBackdropLoader(true);
        api.getSubject()
            .then((res) => {
                setBackdropLoader(false);
                console.log(res);
                setSubject(res.data.subject)
                setBackdropLoader(false);
            })
            .catch((err) => {
                console.log(err.response);
            })
    }

    const handleChangeSubject = (e) => {
        console.log("subject=>", e.target.value);
        getSyllabusBySub(e.target.value);
        setQuestionForm({
            ...questionForm,
            [e.target.name]: e.target.value
        })
    }

    const getSyllabusBySub = (id) => {
        setBackdropLoader(true);
        api.getSyllabusBySub(id)
            .then((res) => {
                setBackdropLoader(false);
                console.log("syl", res);
                setSyllabus(res.data.syllabus);
            })
            .catch((err) => {
                console.log(err.response);
            })
    }
    const getTheSyl = (id) => {
        console.log("qq=>", questionForm);

        setBackdropLoader(true);
        api.getTheSyl(id).then((res) => {
            console.log(res.data, 'check res')
            setSyllabus(res.data.syllabus);
            setQuestionForm({
                ...questionForm,
                'syllabus_id': id,
                'subject_id': sub_id
            })
            setBackdropLoader(false);
        })
            .catch((err) => {
                console.log(err.response);
            })
    }
    
    const saveAndImport = (tags) => {

        console.log(questionForm, tags, 'check tags');
        // return;
        if (!tags.length) {
            Swal.fire({
                title: 'Opss',
                text: 'Add Some Wrong Answers before Import',
                icon: 'error'
            });
            return;
        }
        if (!questionForm.right_ans) {
            Swal.fire({
                title: 'Opss',
                text: 'Please Update Right Answer',
                icon: 'error'
            });
            return;
        }
        if (!questionForm.subject_id) {
            console.log("No Subject Id");
            Swal.fire({
                title: 'Opss',
                text: 'Please Select Subject',
                icon: 'error'
            });
            return;
        }
        if (!questionForm.syllabus_id) {
            console.log("No syllabus Id");
            Swal.fire({
                title: 'Opss',
                text: 'Please Select Syllabus',
                icon: 'error'
            });
            return;
        }

        api.EditExelQuestion(questionForm, tags, 'import').then((res) => {
            if (res.data.status == 200) {
                Swal.fire({
                    title: 'Imported',
                    text: 'Question Data Updated And Imported ',
                    icon: 'success'
                });
            }
            if (res.data.status == 500) {
                Swal.fire({
                    title: 'Not allow',
                    text: 'This question is already Imported',
                    icon: 'warning'
                });
            }
        })
            .catch((err) => {
                console.log("err", err.response);
                Swal.fire({
                    title: 'Opss',
                    text: 'Something went wrong ! ',
                    icon: 'error'
                });
            })
    }


    return (
        <>

            <PreviewQuestion open={preview} calcelPreview={calcelPreview} questionForm={questionForm} wrongAns={wrongAns} />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdroploader}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <section>
                <div className='row'>

                    <div>
                        <button className='btn btn-success btn-sm' onClick={() => navigation(-1)}> Back </button>
                    </div>
                    <Paper>
                        <div className='my-3'>
                        </div>

                        <div>
                            <div className="row">
                                <div className="col-12">
                                    <h3 className='text-center'>Edit Questions</h3>
                                </div>
                            </div>
                        </div>
                    </Paper>
                </div>

                <div>
                    <div className="row">
                        <div className="col-12">
                            {/* <ExcelEditQusetions questionForm={questionForm} handleForm={handleForm} handleQuestion={handleQuestion} handleRightAns={handleRightAns} handleAnsDesc={handleAnsDesc} subject={subject} handleChangeSubject={handleChangeSubject} syllabus={syllabus} /> */}
                            <NewFormQuestion
                                // questionForm={questionForm} handleForm={handleForm} handleQuestion={handleQuestion} handleRightAns={handleRightAns} handleAnsDesc={handleAnsDesc} subject={subject} handleChangeSubject={handleChangeSubject} syllabus={syllabus}

                                type={'edit1'} type2={'excel'} subject={subject}
                                syllabus={syllabus && syllabus}
                                questionForm={questionForm} handleForm={handleForm} handleChangeSubject={handleChangeSubject} handleRightAns={handleRightAns}
                                syllbusId={questionForm.syllabus_id}
                                handleQuestion={handleQuestion} handleAnsDesc={handleAnsDesc}
                                rightans={rightans} wrongAns={wrongAns} setQuestionForm={setQuestionForm} handleSubmit={saveAndImport}
                            />
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default EditExcelQuestions;