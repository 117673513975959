import React, { useState } from "react";

const useBasicLoader = (defaultState = false) => {
  const [loading, setLoading] = useState(defaultState);
  const changeLoading = (value) => {
    setLoading(value);
  };
  return { loading, changeLoading };
};

export default useBasicLoader;
