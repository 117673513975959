import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
// import DialogDynamic from '../pannel/dialog';
// import { Button } from '@mui/material';
// import CheckboxesPrivilege from '../pannel/checkBoxesPrivilege';

// api
import api from "../../API/api";
import TablePagination from "@mui/material/TablePagination";

function AppUsersTable(props) {
  // state related to pagination
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState(null);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [appUsers, setAppUsers] = useState();

  const [filters, setFilters] = useState({
    firstName: "",
    lastName: "",
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
    setPerPage(parseInt(event.target.value, 10));
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  useEffect(() => {
    getAllAppUsers(page, perPage, filters);
  }, [page, perPage, filters]);

  const getAllAppUsers = () => {
    props.setBackdropLoader(true);
    api
      .appUsersPaginate(page, perPage, filters)
      .then((res) => {
        setAppUsers(res.data);
        props.setBackdropLoader(false);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  return (
    <>
      <div className="row py-4">
        <div className="col-md-2">
          <TextField
            id="outlined-basic"
            label="First Name"
            variant="outlined"
            onChange={(e) => {
              setFilters((prev) => {
                return { ...prev, firstName: e.target.value };
              });
            }}
          />
        </div>
        <div className="col-md-2">
          <TextField
            id="outlined-basic"
            label="Last Name"
            variant="outlined"
            onChange={(e) => {
              setFilters((prev) => {
                return { ...prev, firstName: e.target.value };
              });
            }}
          />
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell align="right">Login Type</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Profile Image</TableCell>
              <TableCell align="right">Mobile</TableCell>
              <TableCell align="right">Pincode</TableCell>
              <TableCell align="right">Privileges</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appUsers &&
              appUsers.data &&
              appUsers.data.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "&:hover": {
                      backgroundColor: "rgba(25,118,210,0.2)",
                    },
                    cursor: "pointer",
                  }}
                  onClick={() => props.handleUserClick(row)}
                >
                  <TableCell component="th" scope="row">
                    {row.f_name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.l_name}
                  </TableCell>
                  <TableCell align="right">
                    {row.login_type === 1
                      ? "Normal"
                      : row.login_type === 2
                      ? "Google id"
                      : "Apple Id"}
                  </TableCell>
                  <TableCell align="right">{row.email}</TableCell>
                  <TableCell align="right">
                    {row.profile_image !== null ? (
                      <img
                        src={`${row.profile_image}`}
                        style={{ height: 100, width: 100 }}
                      />
                    ) : (
                      "No image Uploaded"
                    )}
                  </TableCell>
                  <TableCell>{row.phone_number}</TableCell>
                  <TableCell>{row.pincode}</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={appUsers?.total}
          rowsPerPage={appUsers?.per_page}
          page={appUsers?.current_page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
export default AppUsersTable;
