import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { tableCellClasses } from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import api from '../../API/api';
import { Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditSetsMenu from '../qtemplate/editSetsMenu';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#709dc9',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   '&:nth-of-type(odd)': {
//     backgroundColor: theme.palette.action.hover,
//   },
//   // hide last border
//   '&:last-child td, &:last-child th': {
//     border: 0,
//   },
// }));
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};




export default function TemplatesList() {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [templates, setTemplates] = React.useState();
  // const [open, setOpen] = React.useState(false);

  const [objectsList, setObjectsList] = React.useState([])


  const handleClick = (item) => {
    console.log(item.id);
    setObjectsList(
      objectsList.map(e => e.id === item.id ? ({ ...e, isOpen: !e.isOpen }) : (e))
    )
  };


  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - objectsList.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const columns = [
    { id: 'Name', label: 'Name', minWidth: 120 },
    { id: 'Description', label: 'Description', minWidth: 170 },
    { id: 'Marks per Question', label: 'Marks per Question' },
    { id: 'Negative Marks', label: 'Negative Marks'},
    {
      id: 'Total Questions',
      label: 'Total Questions',
      
      // align: 'right',
      format: (value) => value.toLocaleString('en-US'),
    },
    { label: 'Sections'},
    { label: 'Set Question'},
  ];

  const getTemplateList = () => {

    api.getTemplateList().then((res) => {
      console.log(res);
      setTemplates(res.data.templates);
      setObjectsList(
        res.data.templates.map(e => ({ ...e, isOpen: false }))
      );
    }).catch((err) => {
      console.log(err.response);
    })
  }

  React.useEffect(() => {
    getTemplateList();
  }, [])

  return (
    <>
      <div className='row'>
        {/* <Paper> */}
        <div className='my-3'>
          {/* <Button variant="contained" style={{ 'float': 'right' }} size="small">View Questions</Button> */}
        </div>
        <div>
          <div className="row">
            <div className="col-12">
              <h3 className='text-center'>List of Templates</h3>
            </div>
          </div>
        </div>
        {/* </Paper> */}
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {objectsList?.map((row) => (
              <>
                <TableRow key={row.id}>
                  <TableCell style={{ width: 100 }} align="left">
                    {row.name}
                  </TableCell>
                  <TableCell align="left">
                    {row.description}
                  </TableCell>
                  <TableCell  align="left">
                    {row.marks_per_question}
                  </TableCell>
                  <TableCell align="left">
                    {row.negative_marks}
                  </TableCell>
                  <TableCell align="left">
                    {row.tot_questions}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleClick(row)}
                    >
                      <small>  {row.isOpen ? <> <KeyboardArrowUpIcon /> close </> : <> open  <KeyboardArrowDownIcon /> </>}</small>
                    </IconButton>
                  </TableCell>
                  <TableCell align="left">
                  <EditSetsMenu  row={row}/>
                    {/* <Button variant='contained' size='small' onClick={()=>navigation(`/admin/setQuestionPaper`, {state: row})} >Set Question</Button> */}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={row.isOpen} timeout="auto" unmountOnExit>
                      <Box sx={{ margin: 1 }}>
                        <Typography variant="h6" gutterBottom component="div">
                          Sections
                        </Typography>
                        <Table size="small" aria-label="purchases">
                          <TableHead>
                            <TableRow>
                              <TableCell>Subject Name</TableCell>
                              <TableCell>Syllabus</TableCell>
                              <TableCell >Number of Questions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row.sections.map((sections, index) => (
                              <TableRow key={index}>
                                <TableCell >
                                  {sections?.subName}
                                </TableCell>
                                <TableCell>{sections?.syllabus}</TableCell>
                                <TableCell >{sections?.sectionQuestions}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={objectsList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              //   ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}