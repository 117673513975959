import { useState } from "react";

const useFormState = (defaultValue) => {
  const [formState, setFormState] = useState({ ...defaultValue });

  const setFormValues = (key, value) => {
    setFormState((prev) => ({ ...prev, [key]: value }));
  };

  return { formState, setFormState, setFormValues };
};

export default useFormState;
