import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const imageMimeType = /image\/(png|jpg|jpeg)/i;




const SubjectModal = (props) => {

    function handleImage(e) {
        const file = e.target.files[0];
        if (!file.type.match(imageMimeType)) {
            alert("Image mime type is not valid");
            return;
        }
        props.setFile(file);
    }

    useEffect(() => {
        let fileReader, isCancel = false;
        if (props.file) {
            fileReader = new FileReader();
            fileReader.onload = e => {
                const { result } = e.target;
                if (result && !isCancel) {
                    props.setFileDataURL(result);
                }
            }
            fileReader.readAsDataURL(props.file);
        }
        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        }
    }, [props.file])

    return (
        <>
            <Modal
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1 },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField id="outlined-multiline-flexible" label="Subject Name *" size="small" className='form-control' variant='standard' name='subName' onChange={props.handleInput} value={props.subjectData.subName} />

                        <TextField id="outlined-multiline-flexible" label="Class (Optional) " size="small" className='form-control' variant='standard' name='class' onChange={props.handleInput} value={props.subjectData.class} />

                        <TextField id="outlined-multiline-flexible" label="Details (Optional)" size="small" className='form-control' variant='standard' name='details' onChange={props.handleInput} value={props.subjectData.details} />

                        <TextField id="outlined-multiline-flexible" label="Cover Image" size="small" className='form-control' variant='standard' name='image' onChange={handleImage} type='file' />
                        <>
                            {props.fileDataURL ?
                                <>
                                    <p className="img-preview-wrapper"> New Image </p>
                                    <img src={props.fileDataURL} alt="preview" height='90px' />
                                </>
                                : null
                            }

                            {
                                props.editPreview ?
                                    <>
                                        <p className="img-preview-wrapper">Preview Image</p>
                                        <img src={`${process.env.REACT_APP_BACKEND}subject/${props.editPreview}`} alt="preview" height='90px' />
                                    </>
                                    : null
                            }
                        </>

                        {/* <FormGroup value={props.subName}>
                            <InputLabel>Select Exam Name</InputLabel>

                            {props.nameData.map((exam) => (
                                <FormControlLabel control={<Checkbox />} onChange={props.handleSubject} label={exam.examName} value={exam.exam_id}
                                />
                            ))}
                        </FormGroup> */}
                    </Box>

                    <div className="text-center">
                        <Button variant="contained" className='my-3' onClick={props.handleSubmit}>Submit</Button>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default SubjectModal;