import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import api from '../../API/api';
import { array } from 'prop-types';

export default function CheckboxesPrivilege(props) {
  const [state, setState] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });
  const [isCheckedList, setIsCheckedList] = useState([]);
  const [testing, setTesting] = useState(0);
  const [checkedArray , setcheckedArray] = useState([]);


  const { gilad, jason, antoine } = state;
  const error = [gilad, jason, antoine].filter((v) => v).length !== 2;

  const [privilege, setPrivilege] = useState();
  const [allSubject, setAllSubject] = useState();

  useEffect(() => {
    getSubjectPrivilege();
  }, []);

  const getSubjectPrivilege = () => {
    api.getSubjectPrivilege(props.data.id)
      .then((res) => {
        console.log("privilege data", res);
        setPrivilege(res.data.privilege)
        setAllSubject(res.data.allSubject)
        let newArray = [];
        for (let i = 0; i < res.data.allSubject.length; i++) {
          let ismatch = false;
          for (let j = 0; j < res.data.privilege?.length; j++) {
            if (res.data.allSubject[i].id == res.data.privilege[j].id) {
              ismatch = true;
              res.data.allSubject[i].checked = true;
              // res.data.allSubject[i].subject_id = res.data.privilege[j].subject_id;
              newArray.push(res.data.allSubject[i])
              break;
            }
          }
          if (!ismatch) {
            res.data.allSubject[i].checked = false;
            newArray.push(res.data.allSubject[i])
          }
        setIsCheckedList(newArray)
        }
      })
      .catch((err) => {
        console.log(err.response);
      })
  }
  
  const handleChange = (event, isChecked) => {
    setIsCheckedList(
      isCheckedList.map(obj => {
        if (obj.id == event.target.value) {
          if (obj.checked == false) {
            return {...obj, checked : true}
          }
          if (obj.checked == true) {
            return {...obj, checked : false}
          }
        } else {
          return obj;
        }
      })
    );
  };
  
  
  useEffect(() => {
    console.log(isCheckedList  ,'isCheckedList');
  },[isCheckedList])

  return (
    <Box sx={{ display: 'flex' }}>
      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormLabel component="legend">Assign responsibility</FormLabel>
            <FormGroup>
              {isCheckedList?.map((sub, index) =>
              (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox key={index} value={sub.id} checked={sub.checked} onChange={handleChange} />
                    }
                    label={sub.subName}
                  />
                </>
              ))}
            </FormGroup>
        <FormHelperText>Be careful -- {props.data.id}</FormHelperText>
      </FormControl>
    </Box>
  );
}
