import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import api from '../../API/api';
import { Button, Modal } from '@mui/material';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useNavigate, useParams } from "react-router-dom"
import QuestionAllTable from '../table/questionAllTable';
import AuthUser from '../../API/token';
import EditQuestionPage from '../Pages/editQuestionPage';


const htmlToFormattedText = require("html-to-formatted-text");



export default function DenseTable() {
    const { user } = AuthUser();


    const navigation = useNavigate();

    const id = useParams('id');
    const subName = useParams('sub');
    const sub = useParams('selfId');

    useEffect(() => {
        console.log(id, 'lid');
    }, [])
    const [modalData, setModalData] = useState();
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [questions, setQuestions] = useState();
    const [syllabus, setSyllabus] = useState([]);
    const [rightans, setRightans] = useState();
    const [syl, setSyl] = React.useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (user.role == 'SA') {
            getQuestionBySyl();
        }
        else {
            getUserBaseQuestionBySyl();
        }

        setSyl(id.selfId)
    }, [syl,page,perPage]);

    useEffect(() => {
        getSyllabusBySub(id.id);
    }, [syl]);

    // for all questions with syllabus 
    const getQuestionBySyl = () => {
        api.getQuestionBySyl(sub.selfId, page, perPage).then((res) => {
            console.log("======>", res);
            setQuestions(res.data)
        })
            .catch((err) => {
                console.log(err.response, 'errors qeustion');
            })
    }

    // for user base questions 
    const getUserBaseQuestionBySyl = () => {
        api.getUserBaseQuestionBySyl(sub.selfId, user.id, page, perPage).then((res) => {
            console.log("======>", res);
            setQuestions(res.data)
        })
            .catch((err) => {
                console.log(err.response);
            })
    }

    const getSyllabusBySub = (id) => {
        api.getSyllabusBySub(id)
            .then((res) => {
                setSyllabus(res.data.syllabus)
                console.log("syl", res);
            })
            .catch((err) => {
                console.log(err.response);
            })
    }



    const handleChange = (event) => {
        setSyl(event.target.value);
        navigation(`/admin/viewQuestion/${id.sub}/${id.id}/${event.target.value}`)
    };

    const handleClickEdit = async (row) => {
        console.log(row, 'row for edit input');
        // navigation(`/admin/editQuestionsPage/`, { state: { row: row, subname: subName.sub, id: id.id, selfId: sub.selfId } })
        let rightAnswer = row.ans.filter((el) => {
            if (el.isRight === 1) return el;
        });
        let wrong = row.ans.filter((el) => {
            if (el.isRight === 0) return el;
        });
        console.log({
            row: row,
            subname: row.subject.subName,
            id: row.subject.id,
            selfId: row.syllabus.id,
            rightAnswer: rightAnswer,
            subject: row.subject,
            syllabus: row.syllabus,
            wrong: wrong,
            type: "quesTable",
        }, 'for the array');
        setModalData({
            row: row,
            subname: row.subject.subName,
            id: row.subject.id,
            selfId: row.syllabus.id,
            rightAnswer: rightAnswer,
            subject: row.subject,
            syllabus: row.syllabus,
            wrong: wrong,
            type: "quesTable",
        })
        setModalOpen(true)
    }


    const handleApprove = (data) => {
        console.log("approve");
        console.log("achdeke", data);
        // setBackdropLoader(true);
        api.approveQuestion(data.q_id, data.isApprove, user)
            .then((res) => {
                console.log(res);
                getQuestionBySyl();
            }).catch((err) => {
                console.log(err.response);
            })
    }
    const handleCloseModal = () => {
        setModalOpen(false)
    }
    const handleDelete = ()=> {

    }


    return (

        <>
            <div>
                <Paper>
                    <div className='text-center p-2'>
                        <strong>
                            <h3> {id.sub} </h3>
                        </strong>
                    </div>
                </Paper>
                <Paper>
                    <div className='row p-1'>
                        <div className="col-4">
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth size="small">
                                    <InputLabel id="demo-simple-select-label">Syllabus</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={syl}
                                        label="Syllabus"
                                        onChange={handleChange}
                                    >
                                        {syllabus && syllabus.map((s) => (

                                            <MenuItem key={s.id} value={s.id}>{s.syllabus}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>

                        </div>
                        {/* <div className='col-4'>
                            <Button variant='contained'>Search</Button>
                        </div> */}
                        <div className='d-flex justify-content-end col-4 float-end'>
                            <Button variant='contained' onClick={() => navigation(`/admin/AddQuestion/${subName.sub}/${id.id}/${sub.selfId}`)}>Add Question</Button>
                        </div>
                    </div>
                </Paper>
            </div>

            <br />

            <QuestionAllTable
                questions={questions}
                handleClickEdit={handleClickEdit}
                getQuestionBySyl={getQuestionBySyl}
                rightans={rightans}
                handleApprove={handleApprove}
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
                handleDelete={handleDelete}
                // handleDeleteModal={props.handleDeleteModal}
            />

            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <EditQuestionPage
                        state={modalData}
                        setOpen={setModalOpen}
                        setAllChange={setQuestions}
                        wholeArray={questions?.data}
                    />
                </Box>
            </Modal>
        </>
    );
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    overflow: 'scroll', height: '100%'
};