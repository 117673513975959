import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";

import SubjectModal from "../pannel/subModal";
import ModeEditTwoToneIcon from "@mui/icons-material/ModeEditTwoTone";
import { Paper } from "@mui/material";
import Swal from "sweetalert2";
import api from "../../API/api";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import { Divider } from "@mui/material";
import AuthUser from "../../API/token";

const AddSubject = () => {
  const navigation = useNavigate();
  const { user } = AuthUser();
  console.log("useeerrrr==>>", user.role);

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [file, setFile] = useState(null);
  const [fileDataURL, setFileDataURL] = useState(null);
  const [editPreview, setEditPreview] = useState();

  const handleOpen = () => {
    setFileDataURL(null);
    setEditPreview(null);
    setOpen(true);
    setFile("");
  };
  const handleClose = () => {
    setOpen(false);
    setSubjectData({});
    setFileDataURL();
  };

  const handleEdit = (e, sub) => {
    console.log(e, sub, "check values");
    setOpen(true);
    setSubjectData(e);
    setEditPreview(e.image);
  };

  const [nameData, setNameData] = useState([]);

  const [viewSubData, setViewSubData] = useState([]);

  useEffect(() => {
    viewSubject();
  }, []);

  const viewSubject = () => {
    setLoading(true);
    api
      .getSubject()
      .then((res) => {
        console.log("sub=>", res);
        setViewSubData(res.data.subject);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const [subjectData, setSubjectData] = useState({
    subName: "",
    class: "",
    details: "",
    examName: "",
    id: "",
  });

  const handleInput = (e) => {
    setSubjectData({
      ...subjectData,
      [e.target.name]: e.target.value,
    });
  };

  const [subName, setSubName] = useState([]);

  //     const handleSubject = (e) => {
  //
  //         const value = e.target.value;
  //         const checked = e.target.checked;
  //
  //         if (checked) {
  //             setSubName([
  //                 ...subName, value
  //             ])
  //         } else {
  //             setSubName(subName.filter((e) => (e !== value)));
  //         }
  //     }

  const handleSubmit = () => {
    const postData = new FormData();
    console.log(subjectData, "subjectData");
    if (subjectData.id) {
      postData.append("id", subjectData.id);
    }
    postData.append("subName", subjectData.subName);
    postData.append("class", subjectData.class);
    postData.append("details", subjectData.details);
    postData.append("examName", subName);
    postData.append("image", file);
    // console.log(file && file ,'file');
    // return;

    api
      .postSubject(postData)
      .then((res) => {
        setSubName("");
        console.log("save subject", res);
        viewSubject();
        handleClose();
        Swal.fire("Good job!", "Subject Created!", "success");
        setEditPreview("");
        setFileDataURL("");
      })
      .catch((err) => {
        console.log(err.response);
      });

    // axios
    //   .post(`${process.env.REACT_APP_MAIN_API}/postSubject`, postData)
    //   .then((res) => {
    //     setSubName("");
    //     console.log("save subject", res);
    //     viewSubject();
    //     handleClose();
    //     Swal.fire("Good job!", "Subject Created!", "success");
    //     setEditPreview("");
    //     setFileDataURL("");
    //   })
    //   .catch((err) => {
    //     console.log(err.response);
    //   });
  };

  const Loading = () => {
    return (
      <>
        <div className="container p-5">
          <div className="row">
            <div className="col-md-12">
              <div className="p-4 row">
                <div className="col-md-2 my-2">
                  <Skeleton
                    height={250}
                    count={1}
                    style={{ marginBottom: 6 }}
                  />
                </div>
                <div className="col-md-2 my-2">
                  <Skeleton
                    height={250}
                    count={1}
                    style={{ marginBottom: 6 }}
                  />
                </div>
                <div className="col-md-2 my-2">
                  <Skeleton
                    height={250}
                    count={1}
                    style={{ marginBottom: 6 }}
                  />
                </div>
                <div className="col-md-2 my-2">
                  <Skeleton
                    height={250}
                    count={1}
                    style={{ marginBottom: 6 }}
                  />
                </div>
                <div className="col-md-2 my-2">
                  <Skeleton
                    height={250}
                    count={1}
                    style={{ marginBottom: 6 }}
                  />
                </div>
                <div className="col-md-2 my-2">
                  <Skeleton
                    height={250}
                    count={1}
                    style={{ marginBottom: 6 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const [isActive, setIsActive] = useState(false);

  const handleClick = (e, sub) => {
    // console.log(e);
    // console.log(sub);
    // return;
    switch (e.detail) {
      case 1:
        console.log("click");
        setIsActive(sub);
        break;
      case 2:
        console.log("double click");
        navigation(`/admin/subject/${sub.subName}/${sub.id}`);
        break;
      case 3:
        console.log("triple click");
        break;
    }
  };

  return (
    <>
      {/* {oldLayout()} */}

      <div className="m-2 p-3">
        <div className="row">
          {user.role == "SA" && (
            <div>
              <Button
                onClick={handleOpen}
                variant="contained"
                className="my-3 mx-1"
                style={{ float: "right" }}
                size="small"
              >
                + Add Subjects / Paper
              </Button>
              <SubjectModal
                subjectData={subjectData}
                handleSubmit={handleSubmit}
                open={open}
                handleClose={handleClose}
                handleInput={handleInput}
                file={file}
                setFile={setFile}
                fileDataURL={fileDataURL}
                setFileDataURL={setFileDataURL}
                editPreview={editPreview}
              />
            </div>
          )}
          {user.role == "Admin" && (
            <div>
              <Button
                onClick={handleOpen}
                variant="contained"
                className="my-3 mx-1"
                style={{ float: "right" }}
                size="small"
              >
                + Add Subjects / Paper
              </Button>
              <SubjectModal
                subjectData={subjectData}
                handleSubmit={handleSubmit}
                open={open}
                handleClose={handleClose}
                handleInput={handleInput}
                file={file}
                setFile={setFile}
                fileDataURL={fileDataURL}
                setFileDataURL={setFileDataURL}
                editPreview={editPreview}
              />
            </div>
          )}

          <Divider />
          <Divider />
          <Divider />
          <br />

          {loading ? (
            <>
              <LinearProgress color="secondary" />
              <Loading />
            </>
          ) : (
            <>
              {viewSubData &&
                viewSubData.map((sub) => (
                  <div
                    key={sub.id}
                    className="col-md-2 my-1"
                    title={sub.details}
                    onClick={(e) => handleClick(e, sub)}
                  >
                    <Paper elevation={5}>
                      <div className={`p-2 ${isActive == sub && "bg-active"}`}>
                        <div
                          className="d-flex justify-content-center align-items-center book book-content  bg-dark"
                          style={{
                            height: "250px",
                            width: "100%",
                            flexDirection: "column",
                            backgroundImage:
                              sub.image != null
                                ? `url("${process.env.REACT_APP_BACKEND}subject/${sub.image}")`
                                : "",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                        >
                          {/* <div className="d-flex justify-content-center align-items-center book book-content  bg-dark" style={{ height: '250px', flexDirection: 'column' }}> */}
                          {/* ${sub.image !== null ? 'book book-content' : '' } */}
                          <button
                            onClick={() => handleEdit(sub)}
                            className="my-3 mx-0 hover-pencil bg-none"
                          >
                            <ModeEditTwoToneIcon style={{ color: "#fff" }} />
                          </button>
                          <div
                            className="cus-font text-white"
                            style={{
                              backgroundColor:
                                sub.image != null ? "#00000099" : "",
                            }}
                          >
                            <strong> {sub.subName} </strong>
                            <div>
                              <span className="text-sm text-light ">
                                {" "}
                                <i>
                                  {" "}
                                  {sub.class && <>Class {sub.class}</>}{" "}
                                </i>{" "}
                              </span>
                            </div>
                          </div>
                          {/* <img src={`${process.env.REACT_APP_BACKEND}blog/${sub.image}`} height="30px" /> */}
                          {/* <div className='right-corner'>
                                                        <button>+</button>
                                                    </div> */}
                        </div>
                      </div>
                    </Paper>
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AddSubject;
