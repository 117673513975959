import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from "react-router-dom"
import { Button, Paper } from '@mui/material';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { styled } from '@mui/material/styles';
import tableCellClasses from '@mui/material/TableCell';
import { useTheme } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Form from 'react-bootstrap/Form';
import AccountTreeIcon from '@mui/icons-material/AccountTree';




const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};



const SetQuestionPaper = () => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#709dc9',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    const location = useLocation();
    const navigation = useNavigate();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [paperName, setPaperName] = useState()

    useEffect(() => {
        console.log("pp", location.state);
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const handleGenerate = () => {
        console.log("loading...");
        setOpen(true);
    }
    const onChange = (e) => {
        console.log(e.target.text, 'sdaasd');
        setPaperName(e.target.value)
    }
    const handleCreate = () => {

        navigation('/admin/GeneratedPaper', { state: {paperName: paperName, template: location.state }  })
    }

    return (
        <>
            {/* <Paper> */}
            <div className="tableQuestionPaper">
                <div className="top-header">
                    <h4>Set Question Paper</h4>
                </div>
                <div className='row table'>
                    <div className='m-2'>
                        <div className='headers'>
                            <div>
                                <strong>Template Name :</strong> {location.state.name}
                            </div>
                            <div>
                                <strong>Description :</strong> {location.state.name}
                            </div>
                            <div>
                                <strong>Total Questions :</strong> {location.state.tot_questions} ({location.state.marks_per_question}/Question)
                            </div>
                            <div>
                                <strong>Negative Marks :</strong> {location.state.negative_marks}/Question
                            </div>
                        </div>
                        
                        <div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <StyledTableRow >
                                            <StyledTableCell>Subject Name</StyledTableCell>
                                            <StyledTableCell align="right">Syllabus</StyledTableCell>
                                            <StyledTableCell align="right">Number of Questions</StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {location.state.sections.map((row) => (
                                            <StyledTableRow key={row.id}>
                                                <StyledTableCell component="th" scope="row">
                                                    {row.syllabus}
                                                </StyledTableCell>
                                                <StyledTableCell align="right">{row.sectionQuestions}</StyledTableCell>
                                                <StyledTableCell align="right">{row.sectionQuestions}</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>

                    </div>
                    {/* <h1>Paper Set</h1> */}
                    <div className='text-center'>
                        <Button variant='contained' onClick={handleGenerate}> Generate Paper <AccountTreeIcon fontSize='large' /> </Button>
                    </div>
                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 400 }}>
                    <h2 id="parent-modal-title">Add Paper Name</h2>
                    <Form.Group>
                        <Form.Label>Paper Name</Form.Label>
                        <input
                            value={paperName}
                            className='form-control'
                            placeholder=""
                            onChange={onChange}
                        />
                    </Form.Group>
                    <div className='text-center mt-4'>
                        <Button variant='contained' onClick={handleCreate} disabled={paperName && paperName != null ? false : true}>Generate </Button>
                    </div>
                </Box>
            </Modal>

        </>
    )

}

export default SetQuestionPaper