import React , {useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import { useNavigate } from 'react-router-dom';
import api from '../../API/api';
import Swal from 'sweetalert2';


function RewardsTable({ rewards ,handleEditReward}) {
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedValue, setSelectedValue] = useState();
    const open = Boolean(anchorEl);


    const handleClick = (event, data) => {
        setAnchorEl(event.currentTarget);
        setSelectedValue(data)
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleClickEdit = (data) => {
        console.log(selectedValue, 'data2');
        handleEditReward(selectedValue);
    }
    const handleDelete = (data) => {
        // console.log(data, 'data2');
        api.deletePaper(data.id).then(res => {
            console.log(res, 'res');
            Swal.fire({
                title: 'Deleted',
                text: 'Exam Paper is deleted',
            });
        }).catch(err => {
            console.log(err, 'err');
            Swal.fire({
                title: 'Something Went wrong',
                text: 'Please try again',
            });
        })
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Sl No.</TableCell>
                        <TableCell>Points</TableCell>
                        <TableCell align="center">No of Papers</TableCell>
                        <TableCell align="center">No of Syllabus</TableCell>
                        <TableCell align="center">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rewards && rewards.map((row, index) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {index + 1}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.points}
                            </TableCell>
                            <TableCell align="center">{row.no_of_papers}</TableCell>
                            <TableCell align="center">{row.no_of_syllabus}</TableCell>
                            <TableCell align="right">
                                <Stack spacing={1} className='text-center'>
                                    <IconButton aria-label="more" onClick={(e) => handleClick(e, row)}>
                                        <MoreVertIcon />
                                    </IconButton>
                                </Stack>

                                <StyledMenu
                                    id="demo-customized-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'demo-customized-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >

                                    <MenuItem
                                    onClick={() => handleClickEdit()} 
                                    >
                                        Edit
                                    </MenuItem>
                                    <MenuItem
                                    onClick={() => handleDelete(row)}
                                    >
                                        Delete
                                    </MenuItem>
                                </StyledMenu>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}


const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));
export default RewardsTable;