import { Box, Stack } from "@mui/material";
import { useMemo } from "react";

const UserDetailTab = (props) => {
  let active = props.active;

  const chipTypeStyle = useMemo(() => {
    return {
      borderRadius: "99999px",
      padding: "0.3rem 1rem",
      fontSize: "0.8rem",
      fontWeight: "bold",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      maxHeight: "2rem",
      color: "#575757",
      transition: "all 0.2s ease-in-out",
      "&:hover": {
        backgroundColor: "white",
        color: "#575757",
      },
    };
  }, []);

  return (
    <Stack
      sx={{
        backgroundColor: "#e8e8e8",
        borderRadius: "0.5rem",
        position: "sticky", // Keep it sticky
        top: 0, // Stick to the top
        zIndex: 1000,
      }}
      direction={"row"}
      spacing={2}
      maxHeight={"3rem"}
      minHeight={"3rem"}
      alignItems={"center"}
      px={2}
    >
      {props.tabs.map((tab) => (
        <Box
          key={tab}
          onClick={() => props.setActiveTab(tab)}
          sx={{
            ...chipTypeStyle,
            backgroundColor: props.active === tab ? "#a3a3a3" : "inherit",
            color: props.active === tab ? "#fff" : "#575757",
          }}
        >
          {tab}
        </Box>
      ))}
    </Stack>
  );
};

export default UserDetailTab;
