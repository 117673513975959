import axios from "axios";
// import AuthUser from './token';
//
// const {token} = AuthUser();
// console.log("from api", JSON.parse(sessionStorage.getItem('token')));
const API = axios.create({
  // baseURL: "http://127.0.0.1:8000/api/",
  // baseURL: "http://learnoindiatest.jodiperfect.co/public/api/",
  // baseURL: "https://learnoindia.zakticonsulting.in/backend/public/api/",
  // baseURL: "https://learnomain.zakticonsulting.in/backend/public/api/",
  // baseURL :  process.env.REACT_APP_API_URL,
  baseURL: process.env.REACT_APP_MAIN_API,

  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("token"))}`,
  },
});

export const FileAPI = axios.create({
  // baseURL: "http://127.0.0.1:8000/api/",
  // baseURL: "http://learnoindiatest.jodiperfect.co/public/api/",
  // baseURL: "https://learnoindia.zakticonsulting.in/backend/public/api/",
  // baseURL: "https://learnomain.zakticonsulting.in/backend/public/api/",
  // baseURL :  process.env.REACT_APP_API_URL,
  baseURL: process.env.REACT_APP_MAIN_API,

  headers: {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${JSON.parse(sessionStorage.getItem("token"))}`,
  },
});

export default API;
