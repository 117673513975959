import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from "react-router-dom"
import EditIcon from '@mui/icons-material/Edit';
import api from '../../API/api';
import { Divider } from '@mui/material';
import { Button } from '@mui/material';
import AuthUser from '../../API/token'




const htmlToFormattedText = require("html-to-formatted-text");

const TestPaper = e => {
    const { user } = AuthUser();
    const [questionSets, setQuestionSets] = useState([]);
    const [answerSets, setAnswerSets] = useState([]);


    useEffect(() => {
        getQuestionFirst();
    }, [])

    const getQuestionFirst = () => {
        api.getQuestionFirst('aa').then((res) => {
            console.log("==>", res);
            setQuestionSets(res.data.questions)
            savePaperQuestion(res.data.questions)
        })
            .catch((err) => {
                console.log(err);
            })
    }

    const savePaperQuestion = (questions) => {
        api.savePaperQuestion(questions, user.id).then((res) => {
            console.log("response from save data", res);
        })
            .catch((err) => {
                console.log(err);
            })
    }




    const handleClick = (q_id, ans_id, isRight) => {
        console.log(q_id, ans_id, isRight, user.id);
        let data = {
            'q_id': q_id,
            'ans_id': ans_id,
            'isRight': isRight,
            's_id': user.id
        }
        api.submittedAnswer(data).then((res) => {
            console.log(res);
        })
            .catch((err) => {
                console.log(err);
            })


    }


    var slNo = 1;


    return (
        <div className='Question-paper'>
            <h3 className='text-center'>Test </h3>
            <div >
                <div className='syllabus'>
                    <div className='headers'>
                        <p>
                            <strong>Instruction : </strong>
                            <div>
                                Syllabi included on this Paper are -
                            </div>
                            <div>
                                Every Refresh will Randomize the Questions.
                            </div>
                        </p>
                    </div>
                    <div>
                        <div className='space-between'>
                            <p>Total Questions : 100 (1/Question)</p>
                            <p>Negative Marks : 0/Question</p>
                        </div>
                    </div>
                    <div className='body'>

                        {/* {questionSets && questionSets.map((sets) => ( */}
                        <>
                            <div className='headers'>
                                <h5 className='my-4 text-center'>
                                    {/* {sets[0] ? sets[0].syllabus : <span className='text-danger'>Question Not Found</span>} */
                                        'AAA'
                                    }
                                </h5>
                            </div>
                            {/* <hr /> */}
                            {questionSets && questionSets.map((question, index) => (

                                <div className='row pb-3 my-3'>
                                    <div className='Question'>
                                        <div>
                                            <button className='edit-btn mx-3 float-end'>
                                                <EditIcon fontSize={'small'} />
                                            </button>
                                        </div>
                                        Question {index + 1}.
                                        <strong> &nbsp;
                                            {htmlToFormattedText(question.question)} | {question.q_id}

                                        </strong>
                                    </div>
                                    <div className='Answers'>
                                        <strong>Answer :</strong>
                                        <div className='row'>
                                            {question.ans.map((ans, i) => (
                                                <div className='col-md-6 p-2'>
                                                    <button className='btn' onClick={() => handleClick(question.q_id, ans.ans_id, ans.isRight)}>{i + 1}) &nbsp;
                                                        {htmlToFormattedText(ans.answer)} | {ans.ans_id} | {ans.isRight}
                                                    </button>
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                    <Divider />
                                </div>

                            ))}

                        </>
                        {/* ))} */}




                    </div>
                </div>
            </div>

            <div className='p-2 m-2 text-center'>
                <Button variant='contained'>Submit</Button>
            </div>
        </div>
    );
}

export default TestPaper;