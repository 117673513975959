import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";
import MenuAll from "../pannel/dialogAll";
import { Chip } from "@mui/material";
import api from "../../API/api";
import InfoIcon from "@mui/icons-material/Info";
import Collapse from "react-bootstrap/Collapse";

function Bundles({ listPcgk, handleEdit, handleDisable }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [bundles, setBundles] = useState([]);

  const open = Boolean(anchorEl);

  const getBundles = () => {
    api
      .getBundles()
      .then((res) => {
        console.log(res);
        setBundles(res.data.bundles.map((e) => ({ ...e, isOpen: false })));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getBundles();
  }, []);

  return (
    <>
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>sl no</TableCell>
            <TableCell>Name</TableCell>
            <TableCell align="center">Description</TableCell>
            <TableCell align="center">Discount</TableCell>
            <TableCell align="center">Bundle Price</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bundles &&
            bundles.map((row, index) => (
                <>
              <TableRow
                key={row.sl}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell component="th" scope="row" align="left">
                  {row.data.name}
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  {row.data.description}
                </TableCell>
                <TableCell align="right">
                  {row.data.discount} ({row.data.discount_type})
                </TableCell>
                <TableCell align="center">{row.data.price}</TableCell>
                <TableCell align="center">
                  {row.data.isActive === 1 ? (
                    <Chip label="Active" color="success" />
                  ) : (
                    <Chip label="Disable" color="error" />
                  )}
                </TableCell>
                <TableCell align="center">
                  {/* <MenuAll /> */}

                  <Button
                    className="p-1 m-1 btn-secondary"
                    onClick={() =>
                      setBundles((prevItems) => {
                        return prevItems.map((item) => {
                          if (item.data.id === row.data.id) {
                            return { ...item, isOpen: !item.isOpen };
                          }
                          return item;
                        });
                      })
                    }
                    aria-controls="example-collapse-text"
                    aria-expanded={row.isOpen}
                  >
                    <InfoIcon />
                  </Button>
                </TableCell>
                
              </TableRow>

              <Collapse in={row.isOpen}>
                  <div
                    id="example-collapse-text"
                    className="border"
                  >
                    {row?.syllabus.map((s, i) => (
                      <div className=" ">
                        {i + 1} ) {s.name}
                      </div>
                    ))}
                  </div>
                </Collapse>

              </>

            ))}
        </TableBody>
      </Table>
    </TableContainer>

    </>
  );
}
export default Bundles;
