import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ConfirmModal({handleDelete,handleCloseModal}) {
  //   const handleOpen = () => setOpen(true);
  //   const handleClose = () => setOpen(false);

  return (
    <Box>
      <Typography variant="h5" component="h5">
        Are you sure you want to delete
      </Typography>
      <Box sx={{display: 'flex',justifyContent :'space-around', mt : 3}}>
        <Button
          onClick={handleDelete}
        >Delete Paper</Button>
        <Button
          onClick={handleCloseModal}
        >Cancel</Button>
      </Box>
    </Box>
  );
}
