import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Paper } from "@mui/material";
import { Button } from "@mui/material";
import RichText from "../pannel/richtext";
import api from "../../API/api";

import Swal from "sweetalert2";
import { FileAPI } from "../../API/apiConfig";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function StudyMaterialForm(props) {
  const [subject, setSubject] = useState();
  const [syllabus, setSyllabus] = useState();
  const [backdroploader, setBackdropLoader] = useState();
  const [formData, setFormData] = useState({
    topic: "",
    subject_id: "",
    syllabus_id: "",
    document_type: 1,
  });

  const navigate = useNavigate();
  const [document, setDocument] = useState();
  const [pdfUrl, setPdfUrl] = useState();

  const getSubject = () => {
    setBackdropLoader(true);
    api
      .getSubject()
      .then((res) => {
        setBackdropLoader(false);
        console.log(res);
        setSubject(res.data.subject);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  const handleChangeSubject = (e) => {
    getSyllabusBySub(e.target.value);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const getSyllabusBySub = (id) => {
    setBackdropLoader(true);
    api
      .getSyllabusBySub(id)
      .then((res) => {
        setBackdropLoader(false);
        console.log("syl", res);
        setSyllabus(res.data.syllabus);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  const handleForm = (e) => {
    // console.log(e.target.value, 'chekc e');
    if (e.target) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const writeDocument = (e) => {
    setDocument(e);
  };

  const sendData = () => {
    console.log(formData, document, pdfUrl, "formData");

    const formdata = new FormData();
    formdata.append("topic", formData.topic || "");
    formdata.append("subject_id", formData.subject_id || "");
    formdata.append("syllabus_id", formData.syllabus_id || "");
    formdata.append("document_type", formData.document_type || "");

    formdata.append("document", document || "");
    formdata.append("pdf", pdfUrl || "");

    FileAPI.post("/postStudyMeterials", formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        console.log("res", res);
        Swal.fire({
          title: "Saved",
          text: "Study Material Uploaded. Redirecting to Study Materials.",
          icon: "success",
        });
        setTimeout(() => {
          navigate("/admin/studyMaterial");
        }, 1000);
      })
      .catch((err) => {
        console.log("err", err);
        Swal.fire({
          title: "Oppss",
          text: "Something went wrong",
          icon: "eror",
        });
      });
  };

  const handlePdf = (e) => {
    setPdfUrl(e.target.files[0]);
  };

  useEffect(() => {
    getSubject();
  }, []);

  return (
    <>
      <div>
        <h4 className="mb-4">
          {props.title && props.title}
          {!props.title && "Add a New Topic"}
        </h4>
      </div>
      <Paper elevation={4} className="p-4 m-2">
        <div className="row">
          <Form.Group className="col-md-4 mb-3">
            <Form.Label> Select Subject</Form.Label>
            <Form.Select
              name="subject_id"
              value={formData.subject_id}
              onChange={handleChangeSubject}
            >
              <option value="">--select-- </option>
              {subject &&
                subject.map((sub) => (
                  <option value={sub.id}>{sub.subName}</option>
                ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="col-md-4 mb-3">
            <Form.Label> Select Syllabus</Form.Label>
            <Form.Select
              name="syllabus_id"
              value={formData.syllabus_id}
              onChange={handleForm}
            >
              <option value="">--select-- </option>
              {syllabus &&
                syllabus.map((sub) => (
                  <option value={sub.id}>{sub.syllabus}</option>
                ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="col-md-4 mb-3">
            <Form.Label>Document Type</Form.Label>
            <Form.Select
              onChange={handleForm}
              value={formData.document_type}
              name="document_type"
            >
              <option disabled>--- Select Options ---</option>
              <option value="1">Pdf</option>
              <option value="2">Write New</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="col-md-8 mb-3">
            <Form.Label> Write Topic</Form.Label>
            <Form.Control
              type="text"
              placeholder="Write a Topic"
              name="topic"
              onChange={handleForm}
            />
          </Form.Group>
          {formData.document_type == 1 ? (
            <>
              <Form.Group className="col-md-6 mb-3">
                <Form.Label>Upload PDF</Form.Label>
                <Form.Control
                  type="file"
                  placeholder="Write a Topic"
                  name="pdf"
                  onChange={handlePdf}
                />
              </Form.Group>
            </>
          ) : (
            <Form.Group className="col-md-12 mb-3">
              <Form.Label> Write the actual doc</Form.Label>
              <RichText
                name="document_write"
                handleForm={writeDocument}
                value={formData.document}
              />
            </Form.Group>
          )}
          <div className="text-center">
            <Button color="success" variant="contained" onClick={sendData}>
              Save & Continue
            </Button>
          </div>
        </div>
      </Paper>
    </>
  );
}

export default StudyMaterialForm;
