import React , { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Modal} from '@mui/material';
import Button from 'react-bootstrap/Button';
import Box from '@mui/material/Box';


function EditSections(props){
    // console.log(props ,'props');
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
    return (
        <>
         <Modal
            open={props.openEditModal}
            onClose={props.closeEditSection}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <div className='row'>
                    <Form.Group className="mb-3 col-md-12" controlId="formBasicPassword">
                        <Form.Label>Edit Section Name</Form.Label>
                        <Form.Control type="text" placeholder="What this template is about" onChange={props.handleSectionEdit} 
                         defaultValue={props?.selectedSection}
                         />
                            <Form.Text className="text-danger">
                                {props.sectionValueError && props.sectionValueError}
                            </Form.Text>
                    </Form.Group>
                    <Button className='col-12' onClick={props.addSection} variant="primary">Add Section</Button>
                </div>
            </Box>
        </Modal>
        </>
    )
}

export default EditSections;