import React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from 'react-bootstrap/esm/Button';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#709dc9',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


// tableData is sections
export default function SyllabusTable(props) {
  console.log(props.template ,'props 22 for edit check');

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead className='bg-theme'>  
          <TableRow>
            <StyledTableCell>SL </StyledTableCell>
            <StyledTableCell align="right">Subjects</StyledTableCell>
            <StyledTableCell align="right">Syllabus</StyledTableCell>
            <StyledTableCell align="right">Questions</StyledTableCell>
            <StyledTableCell align="right">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {console.log(table && table,'table')} */}
          {props.tableData?.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {index + 1}
              </StyledTableCell>
              
              <StyledTableCell align="right">{row.subName}</StyledTableCell>
              <StyledTableCell align="right">{row.syllabus}</StyledTableCell>
              <StyledTableCell align="right">{row.sectionQuestions}</StyledTableCell>
              <StyledTableCell align="right">
                <Button type="button" onClick={() => props.handleSectionDelete(row)}>Delete</Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
          <TableRow>
            <TableCell rowSpan={3} />
            <TableCell colSpan={1}>Subtotal</TableCell>
            <TableCell align="right">{props.count}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}