import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
// import Accordion from 'react-bootstrap/Accordion';
// import PointsTable from '../table/points';
// import SubjectModal from '../pannel/subModal';
import RewardsTable from "../table/rewards";
import DialogModal from "../modal/modal";
import { TextField, Input } from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import api from "../../API/api";
import MultipleSelectChip from "../../components/common/multiselect";

const RewardMaster = () => {
  // const [medals, setMedals] = useState([]);
  const [rewards, setRewards] = useState();
  const [open, setOpen] = React.useState(false);
  const [paperList, setPaperList] = useState();
  const [studyMaterials, setStudyMaterials] = useState();
  const [papersID, setPapersId] = useState();
  const [studyMaterialsID, setStudyMaterialsID] = useState();

  // selected fields in selectBox different states for different chips selects
  const [selectedPapers, setSelectedPapers] = useState([]);
  const [selectedStudyMaterials, setSelectedStudyMaterials] = useState([]);

  const handleOpen = () => {
    setOpen(true);
    setPointsInput("");
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [pointsInput, setPointsInput] = useState({
    points: "",
    papers: "",
    syllabus: "",
  });
  const [personName, setPersonName] = React.useState([]);

  // edit modal functions
  const handleEditReward = (data) => {
    setOpen(true);
    console.log(data, "edit row");
    setPointsInput({
      points: data.points,
    });
  };
  // edit modal functions

  const handleChange = (event, change_type) => {
    const {
      target: { value },
    } = event;

    if (change_type === "papers")
      setSelectedPapers(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    if (change_type === "studyMaterials")
      setSelectedStudyMaterials(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    // setPersonName(
    // On autofill we get a stringified value.
    //     typeof value === 'string' ? value.split(',') : value,
    // );
    // console.log(event.target.value, 'personName');
    let newValue = [];
    if (event.target.value.length > 0) {
      event.target.value.forEach((element) => {
        newValue.push(element.id);
      });
    }
    // console.log(newValue, 'newValue');
    if (change_type === "papers") setPapersId(newValue);
    if (change_type === "studyMaterials") setStudyMaterialsID(newValue);
  };

  const handleInput = (e) => {
    setPointsInput({
      ...pointsInput,
      [e.target.name]: e.target.value,
    });
  };

  const getValues = () => {
    const data = new FormData();
    data.append("points", pointsInput.points);
    data.append("papers", pointsInput.papers);
    data.append("syllabus", pointsInput.syllabus);
    data.append("paperIds", papersID);
    data.append("studyMaterialIds", studyMaterialsID);

    api
      .rewardMaster(data)
      .then((res) => {
        setOpen(false);
        console.log(res);
        Swal.fire("Good job!", "Medal Created!", "success");
      })
      .catch((error) => {
        console.log(error.response);
      });
    // axios.post(`${process.env.REACT_APP_MAIN_API}/rewardMaster`, data)
    //     .then((res) => {
    //         setOpen(false)
    //         console.log(res);
    //         Swal.fire(
    //             'Good job!',
    //             'Medal Created!',
    //             'success'
    //         )
    //     })
    //     .catch((err) => {
    //         console.log(err.response);
    //     })
  };

  useEffect(() => {
    getRewards();
  }, []);

  const getRewards = () => {
    api
      .getRewards()
      .then((res) => {
        console.log(res);
        setRewards(res.data.rewards);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const listPaper = () => {
    // setBackdropLoader(true);
    api
      .listPaidPaper()
      .then((res) => {
        setPaperList(res.data.papers);
        console.log(res, "res papaer list");
        // setBackdropLoader(false);
      })
      .catch((err) => {
        console.log(err, "err paper list");
      });
  };

  const listStudyMaterials = () => {
    // setBackdropLoader(true);
    api
      .getStudyMeterials()
      .then((res) => {
        // res data won't be in the same form as papers
        // so do some js lame
        let tempStudyMaterials = res.data;
        tempStudyMaterials = tempStudyMaterials.map((element, index) => {
          return {
            id: element.id,
            name: `${element.subject.subName} - ${element.syllabus.syllabus}`,
          };
        });

        setStudyMaterials([...tempStudyMaterials]);
        // setBackdropLoader(false);
      })
      .catch((err) => {
        console.log(err, "err study materials");
      });
  };

  useEffect(() => {
    listPaper();
    listStudyMaterials();
  }, []);

  return (
    <section>
      <div>
        <h3>Reward Master</h3>
        <Button
          onClick={handleOpen}
          variant="contained"
          style={{ float: "right" }}
          size="small"
        >
          + Add Rewards
        </Button>
        <DialogModal
          open={open}
          setOpen={setOpen}
          handleCloseMove={handleClose}
          width={"md"}
          form={
            <AddRewardModal
              handleInput={handleInput}
              getValues={getValues}
              paperList={paperList}
              studyMaterials={studyMaterials}
              personName={personName}
              setPersonName={setPersonName}
              selectedPapers={selectedPapers}
              selectedStudyMaterials={selectedStudyMaterials}
              setSelectedStudyMaterials={setSelectedStudyMaterials}
              setSelectedPapers={setSelectedPapers}
              handleChange={handleChange}
              pointsInput={pointsInput}
            />
          }
        />
      </div>
      <div>
        <RewardsTable rewards={rewards} handleEditReward={handleEditReward} />
      </div>
    </section>
  );
};

const AddRewardModal = ({
  handleInput,
  getValues,
  paperList,
  personName,
  setPersonName,
  handleChange,
  pointsInput,
  studyMaterials,
  selectedPapers,
  selectedStudyMaterials,
  setSelectedPapers,
  setSelectedStudyMaterials,
}) => {
  useEffect(() => {
    return () => {
      setSelectedPapers([]);
      setSelectedStudyMaterials([]);
    };
  }, []);

  return (
    <div className="w-100">
      <div className="mb-4 text-center">
        <h4>Add Reward Master</h4>
        <small>Add Papers and limits of the rewards based on points</small>
      </div>
      <div className="row">
        <div className="w-50 d-block">
          <h6>Points</h6>
          <TextField
            id="filled-basic"
            type="number"
            variant="outlined"
            onChange={(e) => handleInput(e)}
            name="points"
            className="w-100"
            value={pointsInput.points}
          />
        </div>
        <div className="w-50">
          <h6>No of Papers</h6>
          <TextField
            id="filled-basic"
            type="number"
            variant="outlined"
            onChange={(e) => handleInput(e)}
            name="papers"
            className="w-100"
            value={pointsInput.papers}
          />
        </div>
        <div className="w-50">
          <h6>No of Syllabus</h6>
          <TextField
            id="filled-basic"
            type="number"
            variant="outlined"
            onChange={(e) => handleInput(e)}
            name="syllabus"
            className="w-100"
            value={pointsInput.syllabus}
          />
        </div>
        <div className="w-100 my-3">
          <MultipleSelectChip
            chipID={"paper_select_id"}
            change_type={"papers"}
            title={"Select Paper here"}
            label={"Select Paper"}
            data={paperList}
            selected={selectedPapers}
            setSelected={setSelectedPapers}
            handleChange={handleChange}
          />
        </div>

        {/* study materials */}
        {/* <div className="w-100 my-3">
          <MultipleSelectChip
            chipID={"studyMaterials_select_id"}
            change_type={"studyMaterials"}
            title={"Select Study Materials"}
            label={"Select Study Materials"}
            data={studyMaterials}
            selected={selectedStudyMaterials}
            setSelected={setSelectedStudyMaterials}
            handleChange={handleChange}
          />
        </div> */}
        <Button variant="contained" onClick={getValues}>
          Submit
        </Button>
      </div>
    </div>
  );
};
export default RewardMaster;
