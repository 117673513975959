import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Paper,
} from "@mui/material";
import { toast } from "react-toastify";
import api from "../../../API/api";
import { Stack } from "react-bootstrap";

const PushNotificationBatchModal = ({
  open,
  handleClose,
  invalidUsers,
  setInvalidUsers,
  title,
  body,
}) => {
  const [batchSize, setBatchSize] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [currentBatch, setCurrentBatch] = useState(0);
  const [startedSending, setStartedSending] = useState(false);
  const [appUsersTotalPagesData, setAppUsersTotalPagesData] = useState({});

  const getTotalPagesForAppUsers = (batch_size) => {
    api.getTotalPagesForAppUsers(batch_size).then((res) => {
      setAppUsersTotalPagesData(res.data);
    });
  };

  const handleSendBatchNotifications = async () => {
    if (!batchSize) {
      toast.error("Please select a batch size");
      return;
    }

    if (!appUsersTotalPagesData.total_pages) {
      toast.error("Please select a batch size");
      return;
    }

    setIsSending(true);
    setStartedSending(true);
    setCurrentBatch(1);
    setInvalidUsers([]);

    // check if the title and body are not empty
    if (!title || !body) {
      toast.error("Title and body are required");
      setIsSending(false);
      setStartedSending(false);
      return;
    }
    let localInvalidUsers = [...invalidUsers];
    for (let i = 1; i <= appUsersTotalPagesData.total_pages; i++) {
      setCurrentBatch(i);
      //   await sendNotification(batchSize, i + 1);

      if (i < appUsersTotalPagesData.total_pages + 1) {
        try {
          const response = await api.sendPushNotificationToUsersInBatch({
            page: i,
            batch_size: batchSize,
            invalidUsers,
            title,
            body,
          });

          if (response.data.success) {
            if (response.data.invalid_users.length > 0) {
              setInvalidUsers((prev) => [
                ...prev,
                ...response.data.invalid_users,
              ]);
              localInvalidUsers = [
                ...localInvalidUsers,
                ...response.data.invalid_users,
              ];
            }
          }
        } catch (error) {
          console.log(error, "error");
          toast.error("Failed to send batch notification");
        }
      }
    }

    setIsSending(false);

    let successMessage = "Batch Notificatons sent successfully";
    if (localInvalidUsers.length > 0) {
      successMessage =
        "Batch Notificatons sent successfully with some invalid users";
    }
    toast.success(successMessage);
    setBatchSize(null);
    setCurrentBatch(0);
    setStartedSending(false);
    setAppUsersTotalPagesData({});
    handleClose();
  };

  const handleBatchSizeChange = (e) => {
    setCurrentBatch(0);
    setBatchSize(e.target.value);
    getTotalPagesForAppUsers(e.target.value);
  };

  useEffect(() => {
    console.log(invalidUsers, "invalidUsers");
  }, [invalidUsers]);

  return (
    <Modal open={open} disableEscapeKeyDown>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Send Batch Notifications
        </Typography>
        <Paper elevation={3} sx={{ padding: 2, my: 2, borderRadius: 2 }}>
          <Typography variant="body1" sx={{ mb: 1 }}>
            Batch Size
          </Typography>
          <Select
            value={batchSize}
            onChange={handleBatchSizeChange}
            fullWidth
            sx={{ mb: 2 }}
            disabled={isSending} // Disable the select when isSending is true
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Paper>

        <Paper
          elevation={3}
          sx={{
            borderRadius: 1,
            padding: 2,
            marginBottom: 2,
          }}
        >
          <Stack spacing={2}>
            <Typography variant="body1">
              Sending Batch: {currentBatch}
            </Typography>
            <Typography variant="body1">
              Total Users: {appUsersTotalPagesData.total_users} | Total Pages:{" "}
              {appUsersTotalPagesData.total_pages}
            </Typography>
          </Stack>
        </Paper>
        <Button
          variant="contained"
          onClick={handleSendBatchNotifications}
          disabled={isSending}
          fullWidth
        >
          Send Notifications
        </Button>
        {isSending && (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <CircularProgress />
          </Box>
        )}
        <Button
          variant="outlined"
          onClick={() => {
            setBatchSize(null);
            setCurrentBatch(0);
            setAppUsersTotalPagesData({});
            handleClose();
          }}
          sx={{ mt: 2 }}
          fullWidth
          disabled={startedSending}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default PushNotificationBatchModal;
