import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CreatePaper from "./createPaper";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function EditPaperModal({
  open,
  handleOpen,
  handleClose,
  value,
  updateList,
}) {
  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              width: "100%",

              maxHeight: "80vh",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            <CreatePaper
              hasEdit={"edit"}
              value={value}
              handleEditClose={handleClose}
              updateList={updateList}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
