import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import api from '../../API/api';

import SyllabusForm from '../form/syllabusForm';


export default function SyllabusModal(props) {

    const [open, setOpen] = React.useState(false);

    const [type, setType] = useState();
    const [name, setName] = useState();

    useEffect(() => {
        console.log(props.id);
    }, [])

    const handleClickOpen = (type) => {
        setType(type);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleInput = (e) => {
        setName(e.target.value)
    }

    const handleSubmit = () => {
        // console.log(type, props.id, name, props.selfId);
        // return;
        api.createSylFile(type, props.id, name, props.selfId)
            .then((res) => {
                console.log(res);
                props.getSyllabusById()
                handleClose();

            })
            .catch((err) => {
                console.log(err.response);
                handleClose();

            })

    }



    return (
        <div>
            <Button variant='outlined' size='small' onClick={() => handleClickOpen('folder')}>
                +Create Folder
            </Button>
            &nbsp;
            <Button variant='outlined' size='small' onClick={() => handleClickOpen('file')}>
                +Create Syllabus
            </Button>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Create New {type} </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label={`New ${type}`}
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleInput}
                        value={name}
                    />

                    {/* <SyllabusForm name={name} type={type} handleInput={handleInput}/> */}


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>Create</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
