import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import AppUsersTable from "../table/appusers";
import api from "../../API/api";

const AppUsers = (props) => {
  const [backdroploader, setBackdropLoader] = useState(false);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdroploader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <section>


        <div className="row">
          <Paper>
            <div className="my-2">
              <h3>App Users and Details </h3>
            </div>
          </Paper>
        </div>

        <div>
          <div className="row">
            <div className="col-12">
              <div style={{ margin: 5 }}>
                <AppUsersTable setBackdropLoader={setBackdropLoader} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AppUsers;
