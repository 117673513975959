import React, { useEffect, useState } from "react";
import api from "../../API/api";
import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useParams } from "react-router-dom";
import AuthUser from "../../API/token";

import QuestionExcelTable from "../table/questionsExcel";
import Form from "react-bootstrap/Form";
import { Button } from "@mui/material";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import exel_formate from "../../assets/exel_formate.png";

const QuestionsFromExcel = (props) => {
  const { user } = AuthUser();
  const [backdroploader, setBackdropLoader] = useState(false);
  const [excelFile, setExcelFile] = useState({});
  const [open, setOpen] = React.useState(false);
  const [subject, setSubject] = useState();
  const [syllabus, setSyllabus] = useState();
  const [uploaded, setUploaded] = useState(1);

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const [questionForm, setQuestionForm] = useState({
    subject_id: "",
    syllabus_id: "",
  });

  const handleClose = () => {
    setBackdropLoader(false);
  };
  const handleExcelFile = (e) => {
    console.log(e.target.files[0], "excel file");
    setExcelFile(e.target.files[0]);
  };

  const handleUplaodExcel = () => {
    // return;
    if (!questionForm.subject_id) {
      handleCloseModal();
      Swal.fire({
        title: "Error",
        text: "Please select a Subject",
        icon: "error",
      });
      return;
    }

    handleCloseModal();
    setBackdropLoader(true);

    const ExData = new FormData();
    ExData.append("file", excelFile);
    ExData.append("added_by", user.id);
    ExData.append("subject_id", questionForm.subject_id);
    ExData.append("syllabus_id", questionForm.syllabus_id);

    api
      .importExcel(ExData)
      .then((res) => {
        console.log("syl", res);
        setBackdropLoader(false);
        // handleCloseModal()
        if (res.data.status == 200) {
          setUploaded(uploaded + 1);
          Swal.fire({
            title: "Updated",
            text: "Data Imported Successfully ",
            icon: "success",
          });
        }
      })
      .catch((err) => {
        console.log("syl", err);
        setBackdropLoader(false);
        // handleCloseModal()
        Swal.fire({
          title: "Error!",
          text: err.response.data.message,
          imageUrl: require("../../assets/exel_formate.png"),
          imageWidth: "100%",
          imageHeight: "auto",
          imageAlt: "Custom image",

          // title: 'Error',
          // text: 'Something Went Wrong, ' + err.response.data.message,
          icon: "error",
        });
      });

    // axios
    //   .post(`${process.env.REACT_APP_MAIN_API}/importExcel`, ExData)
    //   .then((res) => {
    //     console.log("syl", res);
    //     setBackdropLoader(false);
    //     // handleCloseModal()
    //     if (res.data.status == 200) {
    //       setUploaded(uploaded + 1);
    //       Swal.fire({
    //         title: "Updated",
    //         text: "Data Imported Successfully ",
    //         icon: "success",
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("syl", err);
    //     setBackdropLoader(false);
    //     // handleCloseModal()
    //     Swal.fire({
    //       title: "Error!",
    //       text: err.response.data.message,
    //       imageUrl: require("../../assets/exel_formate.png"),
    //       imageWidth: "100%",
    //       imageHeight: "auto",
    //       imageAlt: "Custom image",

    //       // title: 'Error',
    //       // text: 'Something Went Wrong, ' + err.response.data.message,
    //       icon: "error",
    //     });
    //   });
  };

  const handleForm = (e) => {
    // console.log(e);
    if (e.target) {
      // console.log(e.target.value);
      setQuestionForm({
        ...questionForm,
        [e.target.name]: e.target.value,
      });
    }
  };

  const getSubject = () => {
    setBackdropLoader(true);
    api
      .getSubject()
      .then((res) => {
        setBackdropLoader(false);
        console.log(res);
        setSubject(res.data.subject);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleChangeSubject = (e) => {
    console.log("subject=>", e.target.value);
    getSyllabusBySub(e.target.value);
    setQuestionForm({
      ...questionForm,
      [e.target.name]: e.target.value,
    });
  };

  const getSyllabusBySub = (id) => {
    setBackdropLoader(true);
    api
      .getSyllabusBySub(id)
      .then((res) => {
        setBackdropLoader(false);
        console.log("syl", res);
        setSyllabus(res.data.syllabus);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  useEffect(() => {
    getSubject();
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdroploader}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <section>
        <div className="row mb-3">
          <div>
            <div className="row">
              <div className="col-12">
                <div className="d-flex justify-content-between">
                  <h3 className="text-center">Excel Imported Questions</h3>
                  <div>
                    <Button
                      className="float-end"
                      size="sm"
                      variant="contained"
                      color="secondary"
                      onClick={handleOpenModal}
                    >
                      {" "}
                      <ContentPasteGoIcon /> &nbsp; Upload Excel
                    </Button>
                  </div>
                </div>
                <Modal
                  open={open}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={styleSmall}>
                    <div>
                      <h5>Upload Your Excel here </h5>

                      <Form.Group
                        className="col-md-12 mb-3 mt-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label> Select Subject</Form.Label>
                        <Form.Select
                          name="subject_id"
                          aria-label="Default select example"
                          onChange={handleChangeSubject}
                          value={questionForm?.subject}
                        >
                          <option value="">--select-- </option>
                          {subject &&
                            subject.map((sub) => (
                              <option value={sub.id}>{sub.subName}</option>
                            ))}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group
                        className="col-md-12 mb-3 mt-1"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Select Syllabus</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="syllabus_id"
                          onChange={handleForm}
                          value={questionForm?.syllabus}
                        >
                          <option value="">--select-- </option>
                          {syllabus &&
                            syllabus.map((syl) => (
                              <option key={syl.id} value={syl.id}>
                                {syl.syllabus}
                              </option>
                            ))}
                        </Form.Select>
                      </Form.Group>

                      <input
                        className="my-4 form-control"
                        type="file"
                        name="file"
                        placeholder="Choose file"
                        onChange={handleExcelFile}
                      />
                      <div className="text-center">
                        <button
                          type="submit"
                          onClick={handleUplaodExcel}
                          className="btn btn-info btn-sm"
                        >
                          Upload Excel{" "}
                        </button>
                      </div>
                    </div>
                  </Box>
                </Modal>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="row">
            <div className="col-12">
              <QuestionExcelTable uploaded={uploaded} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default QuestionsFromExcel;

const styleSmall = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
