import React, { useState, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import { Paper, Modal } from '@mui/material';
import RichText from '../pannel/richtext';
import Button from 'react-bootstrap/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditSections from './editSection';
import SectionAddTable from './sectionsTable';


function AddQuestionSets(props) {

    console.log(props, 'propsprops');
    const [open, setOpen] = useState();
    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <>
            {/* <Form> */}
            <div className='row'>
                <Form.Group className="mb-3">
                    <Form.Label>Template Name</Form.Label>
                    <Form.Control type="text" name='name' value={props.template.name} onChange={props.handleTemplate} placeholder='Type Template Name' />
                    {props.error?.name ? <small style={{ color: 'red' }}>{props.error.name}</small> : ''}
                </Form.Group>
                <Form.Group className="mb-3 col-md-12" controlId="formBasicPassword">
                    <Form.Label>Description</Form.Label>
                    <Form.Control type="text" name='description' value={props.template.description} onChange={props.handleTemplate} placeholder="What this template is about" />
                    {props.error?.description ? <small style={{ color: 'red' }}>{props.error.description}</small> : ''}
                </Form.Group>
                <Form.Group className="mb-3 col-md-3">
                    <Form.Label>Total Questions</Form.Label>
                    <Form.Control type="number" name='tot_questions' value={props.template.tot_questions} onChange={props.handleTemplate} placeholder='Type Template Name' />
                    {props.error?.tot_questions ? <small style={{ color: 'red' }}>{props.error.tot_questions}</small> : ''}
                </Form.Group>
                <Form.Group className="mb-3 col-md-3">
                    <Form.Label>Marks Per Question</Form.Label>
                    <Form.Control type="number" name='marks_per_question' value={props.template.marks_per_question} onChange={props.handleTemplate} placeholder='Type Template Name' />
                    {props.error?.marks_per_question ? <small style={{ color: 'red' }}>{props.error.marks_per_question}</small> : ''}
                </Form.Group>
                <Form.Group className="mb-3 col-md-3">
                    <Form.Label>Negative Marks per Question</Form.Label>
                    <Form.Control type="number" name='negative_marks' value={props.template.negative_marks} onChange={props.handleTemplate} placeholder='Type Template Name' />
                </Form.Group>
                <Form.Group className="mb-3 col-md-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Sections</Form.Label>
                    <Form.Control type="number" defaultValue={4} />
                </Form.Group>

                <Form.Group className="col-md-3 mb-5 mt-3" controlId="exampleForm.ControlInput1">
                    <Form.Label> Select Subject</Form.Label>
                    <Form.Select onChange={props.handleChangeSubject} name='subject_id' value={props.questionForm.subject_id}  >
                        <option value='' disabled>--select-- </option>
                        {props.subject && props.subject.map((sub) => (
                            <option value={sub.id}>{sub.subName}</option>
                        ))}
                    </Form.Select>
                    {
                        props.error?.sectionArrayId ? <small style={{ color: 'red' }}>{props.error.sectionArrayId}</small> : ''
                    }
                </Form.Group>

                <Form.Group className="col-md-3 mb-5 mt-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Select Syllabus</Form.Label>
                    <Form.Select name='syllabus_id' onChange={props.handleChangeSyllabus} value={props.questionForm.syllabus}>
                        <option value=''>--select-- </option>
                        {props.syllabus && props.syllabus.map((syl) => (
                            <option key={syl.id} value={syl.id}>{syl.syllabus}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <Form.Group className="col-md-3 mb-5 mt-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Questions from this section</Form.Label>
                    <Form.Control type="text" defaultValue={props.sectionQues} onChange={props.handleSectionQues} />
                </Form.Group>
                <Form.Group className="col-md-3 mb-5 mt-5">
                    <Button onClick={props.handleSections} variant="primary">+ Add Sections</Button>
                </Form.Group>
                {
                    // console.log(props.sections ,'props.sections')
                    props.sections.length > 0 ?
                        <SectionAddTable tableData={props.sections} countTotal={props.count} handleSectionDelete={props.handleSectionDelete} />
                        : ''
                }
                {
                    props.error?.count ? <small style={{ color: 'red' }}>{props.error.count}</small> : ''
                }


                <div className='text-center'>
                    <Button className='btn btn-primary mt-5 btn-block' variant='primary' onClick={props.handleSubmit}>Submit</Button>
                </div>

                <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box sx={style}>
                        <div className='row'>
                            <Form.Group className="mb-3 col-md-12" controlId="formBasicPassword">
                                <Form.Label>Section Name</Form.Label>
                                <Form.Control type="text" placeholder="What this template is about" onChange={props.handleSection} />
                                <Form.Text className="text-danger">
                                    {props.sectionValueError && props.sectionValueError}
                                </Form.Text>
                            </Form.Group>
                            <Button className='col-12' onClick={props.addSection} variant="primary">Add Section</Button>
                        </div>
                    </Box>
                </Modal>
                <EditSections handleSectionEdit={props.handleSectionEdit} openEditModal={props.openEditModal} setOpenEditModel={props.setOpenEditModel} closeEditSection={props.closeEditSection} selectedSection={props.selectedSection} />

            </div>
        </>
    );

}

export default AddQuestionSets;