import React, { useEffect, useState } from 'react'
import { Paper } from '@mui/material';
import api from '../../API/api';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AuthUser from '../../API/token'
import ReportsTable from '../table/reports';



const Reports = (props) => {
    const [backdroploader, setBackdropLoader] = useState(false);



    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdroploader}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* <LinearWithValueLabel /> */}
            <section>
                <div className='row'>
                    <Paper>
                        <div className='my-2'>
                            <h3>Reports </h3>
                        </div>
                    </Paper>
                </div>

                <div>
                    <div className="row">
                        <div className="col-12">
                            <div style={{margin : 5}}>
                                <ReportsTable />
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Reports;