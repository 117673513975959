import React, { useEffect, useState  } from 'react'
import AddQuestionSets from '../qtemplate/addSets';
import { Paper } from '@mui/material';
import api from '../../API/api';
import Swal from 'sweetalert2';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const AddQuestionTemplate = (props) => {
    const [loader, setLoader] = useState(false);
    const [testing , setTesting ] = useState(0);
    const [error , setError] = useState();
    const [backdroploader, setBackdropLoader] = useState(false);
    const handleClose = () => {
        setBackdropLoader(false);
    };
    const  [count , setCount] = useState(0);
    const [subject, setSubject] = useState();
    const [syllabus, setSyllabus] = useState();
    // add those syllabus
    const [sections, setSections] = useState([])
    const [openEditModal, setOpenEditModel] = useState(false);
    const [selectedSection, setSelectedSection] = useState();
    const [sectionsValue, setSectionsValue] = useState();
    const [sectionValueError, setSectionValueError] = useState();
    const [totalQuestion, setTotalQuestion] = useState(100);
    const [quesPerSection, setQuesPerSection] = useState();
    const [questionForm, setQuestionForm] = useState({
        subject_id: '',
        syllabus_id: '',
    })
    const [sectionQues, setSectionQues] = useState(0);
    const [template, setTemplate] = useState({
        name: '',
        description: '',
        tot_questions: 100,
        marks_per_question: '',
        negative_marks: 0,
    })
    const [sectionArray , setSectionArray] = useState({
        subj : '',
        syllabusCatch : '',
        questiosn : ''
    })
    const [sectionArrayId , setSectionArrayId] = useState([])


    const handleTemplate = (e) => {
        setTemplate({
            ...template,
            [e.target.name]: e.target.value
        })
    }

    const handleSection = (e) => {
        setSectionsValue(e.target.value);
        setSectionValueError('')
    }
    const handleSectionEdit = (e) => {
        console.log(e.target.value);
        setSections(
            sections?.map(obj => {

            })
        )
    }

    const addSection = () => {
        setSections(current => [...current, { name: sectionsValue, id: sections.length + 1 }]);
        setQuesPerSection(totalQuestion && totalQuestion / sections?.length)
    }
    const handleEditSection = (item) => {
        setOpenEditModel(true);
        setSelectedSection(item.name)
    }
    const closeEditSection = () => {
        setOpenEditModel(false);
    }
    const handleDeleteSection = async (id) => {
        setBackdropLoader(true);
        setSections(current =>
            current.filter(obj => {
                return obj.id !== id;
            })
        )
        setQuesPerSection(totalQuestion / sections?.length);
        console.log(quesPerSection && quesPerSection, 'quesPerSection');
        setBackdropLoader(false);
    }
    const getSubject = () => {
        setBackdropLoader(true);
        api.getSubject()
            .then((res) => {
                setBackdropLoader(false);
                console.log(res);
                setSubject(res.data.subject)
            })
            .catch((err) => {
                console.log(err.response);
            })
    }
    const getSyllabusBySub = (id) => {
        setBackdropLoader(true);
        api.getSyllabusBySub(id)
            .then((res) => {
                setBackdropLoader(false);
                console.log("syl", res);
                setSyllabus(res.data.syllabus);
            })
            .catch((err) => {
                console.log(err.response);
            })
    }
    const handleChangeSubject = (e) => {
        getSyllabusBySub(e.target.value);
        let index = e.nativeEvent.target.selectedIndex;
        let getSubjectText =  e.nativeEvent.target[index].text;

        setSectionArray(current => ({...current , subj:getSubjectText}))
        setQuestionForm({
            ...questionForm,
            [e.target.name]: e.target.value
        })
    }

    const handleChangeSyllabus = (e) => {
        let index = e.nativeEvent.target.selectedIndex;
        let getSubjectText =  e.nativeEvent.target[index].text;
        setQuestionForm({
            ...questionForm,
            [e.target.name]: e.target.value
        })
        setSectionArray(current => ({...current , syllabusCatch:getSubjectText , syl_id : e.target.value}))
    }

    useEffect(() => {
        getSubject();
        setQuesPerSection(totalQuestion && totalQuestion / sections?.length);
        
    }, [])

    const handleSectionQues = (e) => {
        setSectionArray(current => ({...current , questiosn:e.target.value.replace(/\D/g, '')}))
        setSectionQues(e.target.value.replace(/\D/g, ''))
        console.log(sectionArray && sectionArray ,'sectionArray');
    }


    const handleSectionDelete = (row) => {
        console.log(sections && sections , 'sections');
        setSectionArrayId(current => current.filter((data)=> 
        data.syllabus_id != row.syl_id
         ))
         setSections(current => current.filter((data) => data.syl_id !=  row.syl_id))
         setCount( prevState => parseInt(prevState) -  parseInt(row.questiosn))
        setTesting(testing + 1);
    }

    
    const handleSections = () => {
        if (parseInt(sectionArray.questiosn) <= template?.tot_questions) {
            if(parseInt(sectionArray.questiosn) + count > template?.tot_questions){
                Swal.fire({  
                    title: 'Limit Exists',  
                    text: `Question number cant exist beyond ${parseInt(sectionArray.questiosn)}  `,
                }); 
            }else {
                // console.log(questionForm?.syllabus_id ,'questionForm?.syllabus_id');
               if (sectionArrayId.length > 0) {
                console.log('if');
                 const objPresent = sectionArrayId.find(obj =>obj.syllabus_id == questionForm?.syllabus_id);
                        if (!objPresent) {
                            sections?.push(sectionArray && sectionArray);
                            sections?.find((obj) => {
                                setCount( parseInt(count) +  parseInt(obj.questiosn))
                            })
                            setSectionArrayId(current => [...current , {
                                subject_id      : questionForm?.subject_id,
                                syllabus_id     : questionForm?.syllabus_id,
                                sectionQuestions: sectionArray?.questiosn}
                            ])
                        }else {
                            Swal.fire({  
                                title: 'Syllabus Already exist',  
                                text: 'Please try another Syllabus ',
                            }); 
                        }
                    // }
                } else {
                    console.log('else');
                    sections?.push(sectionArray && sectionArray);
                    sections?.find((obj) => {
                        setCount( parseInt(count) +  parseInt(obj.questiosn))
                    })
                    sectionArrayId.push({
                        subject_id      : questionForm?.subject_id,
                        syllabus_id     : questionForm?.syllabus_id,
                        sectionQuestions: sectionArray?.questiosn
                    })
               }
            }
        } else {
            Swal.fire({  
                title: 'Limit Exists',  
                text: 'Question number cant exist beyond 100 ',
            }); 
        }
        setBackdropLoader(false)
    }


    const handleSubmit = () => {
        console.log("==>", template, sectionArrayId);
        console.log(error && error , 'error');
        // return;
        if(template.description === ''){
            setError({ ...error, description: 'Description is Empty' })
            console.log(error && error ,';error');
        }else if (template.marks_per_question === ''){
            setError({ ...error, marks_per_question: 'Field is Empty' })
        }else if (template.name === '') {
            setError({ ...error, name: 'Name is Empty' })
        }else if (template.tot_questions === ''){
            setError({ ...error, tot_questions: 'Field is Empty' })
        }else if (sectionArrayId.length === 0){
            setError({ ...error, sectionArrayId: 'Sections Cant be Empty' })
        // }else if (count && count !== template?.tot_questions){
        //     setError({ ...error, count: 'Total Section marks must be of 100' })
        }else {
            console.log( sectionArrayId.length , 'guly');
            // return 0;
            api.addQuestionTemplate(template, sectionArrayId).then((res) => {
                console.log("res", res);
                Swal.fire(
                    'Success',
                    // '',
                    'Question Template is Created'
                )
            })
            
            .catch((err) => {
                console.log(err.response);
            })
        }
    }

    return (
        <>
            {/* <PreviewQuestion open={preview}/> */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdroploader}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* <LinearWithValueLabel /> */}
            <section>
                <div className='row'>
                    <Paper>
                        <div className='my-3'>
                            {/* <Button variant="contained" style={{ 'float': 'right' }} size="small">View Questions</Button> */}
                        </div>
                        <div>
                            <div className="row">
                                <div className="col-12">
                                    <h3 className='text-center'>Add Questions Template </h3>
                                </div>
                            </div>
                        </div>
                    </Paper>
                </div>
                <div>
                    <div className="row">
                        <div className="col-12">
                            {/* <Paper elevation={4} className='p-4 m-2'> */}
                            <div className="p-4">
                                <AddQuestionSets handleSectionEdit={handleSectionEdit} sections={sections} handleSection={handleSection} addSection={addSection} sectionValueError={sectionValueError} loader={loader} handleEditSection={handleEditSection} openEditModal={openEditModal} setOpenEditModel={setOpenEditModel} handleDeleteSection={handleDeleteSection} quesPerSection={quesPerSection} closeEditSection={closeEditSection} selectedSection={selectedSection} getSubject={getSubject} getSyllabusBySub={getSyllabusBySub} subject={subject} questionForm={questionForm} handleChangeSubject={handleChangeSubject} error={error} syllabus={syllabus} handleSections={handleSections} handleSubmit={handleSubmit} handleChangeSyllabus={handleChangeSyllabus} sectionQues={sectionQues} handleSectionQues={handleSectionQues} template={template} handleTemplate={handleTemplate} count={count} handleSectionDelete={handleSectionDelete} />   
                            </div>
                            {/* </Paper> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AddQuestionTemplate;