import React, { useEffect, useState } from 'react'
import { Paper } from '@mui/material';
import Swal from 'sweetalert2';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import UseListTable from '../../table/listOfUsers';
import api from '../../../API/api';

const ListUsers = () => {

    const [backdroploader, setBackdropLoader] = useState(false);
    const handleClose = () => {
        setBackdropLoader(false);
    };

    // const [users, setUsers] = useState();

    // useEffect(() => {
    //     getAllUser()
    // }, []);

    // const getAllUser = () => {
    //     setBackdropLoader(true);
    //     api.getAllUser().then((res) => {
    //         console.log(res);
    //         setUsers(res.data.users);
    //         setBackdropLoader(false);
    //     }).catch((err) => {
    //         console.log(err.response);
    //     })
    // }

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdroploader}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* <LinearWithValueLabel /> */}
            <section>
                <div className='row'>
                    <Paper>
                        <div className='my-3'>
                            {/* <Button variant="contained" style={{ 'float': 'right' }} size="small">View Questions</Button> */}
                        </div>
                        <div>
                            <div className="row">
                                <div className="col-12">
                                    <h3 >List of All Users </h3>
                                </div>
                            </div>
                        </div>
                    </Paper>
                </div>

                <div>
                    <div className="row">
                        <div className="col-12">
                            <Paper elevation={4} className='p-4 m-2'>
                                <UseListTable  setBackdropLoader={setBackdropLoader}/>
                            </Paper>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}


export default ListUsers;