import { Skeleton, TableCell, TableRow } from "@mui/material";

const SkeletonList = ({ rowsNum, rowCol = 1 }) => {
  return [...Array(rowsNum)].map((_, index) => (
    <TableRow key={index}>
      {[...Array(rowCol)].map((_, index) => (
        <TableCell key={index}>
          <Skeleton animation="wave" height={40} />
        </TableCell>
      ))}
    </TableRow>
  ));
};

export default SkeletonList;
