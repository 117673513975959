import React, { useEffect, useRef, useState } from "react";
import InfoQues from "../question/infoQues";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MenuAction from "../question/menuActions";
import api from "../../API/api";
import AuthUser from "../../API/token";
import { Button, Modal, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import EditQuestionPage from "../Pages/editQuestionPage";
import Swal from "sweetalert2";

import { Backdrop } from "@mui/material";
import { convertLatexToAsciiMath } from "mathlive";
import { QuestionFetchApi } from "../../redux/reducer/fetchQuestions";
import ConfirmModal from "../pannel/confirmModal";
// import "//unpkg.com/mathlive";
import EnhancedTable from "./questionsPaginate";

const htmlToFormattedText = require("html-to-formatted-text");

//  all question new compo ,delete old one

const ViewQuestions = (props) => {
  const { user } = AuthUser();
  const dispatch = useDispatch();
  const [backdroploader, setBackdropLoader] = React.useState(false);
  const [isApprove, setIsApproved] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const childRef = React.useRef(null);
  const handleClick = () => {
    childRef.current.handleClose();
  };
  const handleCloseLoader = () => {
    setBackdropLoader(false);
  };

  // const [open, setOpen] = useState();
  const [all, setAll] = useState();
  const [total, setTotal] = useState();
  const [filter, setFilter] = useState({
    type: "",
    type_id: "",
    user_type: user.role,
    user_id: user.id,
    subject_id: "",
    syllabus_id: "",
    added_by: "",
    subName: "Any",
  });

  const [searchQuery, setSearchQuery ] = useState("");

  const [page, setPage] = useState(1);

  const subjectRef = useRef(null);

  const getData = () => {
    setBackdropLoader(true);
    console.log(page, filter, isApprove, "for values");
    // .getQuestionPage(page, filter, isApprove)
    filter['searchQuery'] = searchQuery;
    api
      .getQuestionPaginate(page, user.id, perPage, filter, isApprove)
      .then((res) => {
        console.log("ress", res);
        if (res.data.data) {
          setAll(res.data);
          // setTotal(res.data.total)
          props.setAllParent(res.data.data);
        } else {
          setAll("");
          setTotal(0);
        }
        handleCloseLoader();
      })
      .catch((err) => {
        handleCloseLoader();
        console.log("err", err);
      });
  };

  const handleCloseModal = () => {
    props.setOpen(false);
  };

  useEffect(() => {
    // dispatch(QuestionFetchApi(page, filter))
    getData();
  }, [page, filter, isApprove, perPage]);

  const handleNextPage = () => {
    setPage(page + 1);
  };

  const changeSubject = (e) => {
    if (e.target.value.id === "Any") {
      setFilter({
        ...filter,
        type: "subject",
        type_id: e.target.value,
        subject_id: "",
        syllabus_id: "",
        subName: "Any",
      });

      return;
    }
    props.getSyllabusBySub(e.target.value);
    setPage(1);
    setFilter({
      ...filter,
      type: "subject",
      type_id: e.target.value,
      subject_id: e.target.value,
      syllabus_id: "",
      subName: e.target.value
    });
  };

  const changeSyllabus = (e) => {
    console.log(filter);
    console.log(e.target.value);
    setPage(1);
    if (e.target.value === "Any") {
      setFilter({
        ...filter,
        type: "subject",
        type_id: e.target.value,
        syllabus_id: "",
      });

      return;
    }
    setFilter({
      ...filter,
      type: "syllabus",
      type_id: e.target.value,
      syllabus_id: e.target.value,
    });
  };
  const changeUser = (e) => {
    console.log(e.target.value);
    console.log(e.target.value);
    setPage(1);
    if (e.target.value === "Any") {
      setFilter({
        ...filter,
        type: "user",
        type_id: e.target.value,
        added_by: "",
      });

      return;
    }
    setFilter({
      ...filter,
      type: "user",
      type_id: e.target.value,
      added_by: e.target.value,
    });
  };

  const changeSearch = (e) => {
    setPage(1);
    let localSearch = e.target.value;
   setSearchQuery(e.target.value)

  };

  // useEffect with setTimeout
  useEffect(() =>{
    if(searchQuery.length > 3) {
      setTimeout(() => {
        getData()
      }, 500)
    }
  },[searchQuery])

  const removeFilter = () => {
    changeSubject({ target: { value: "Any" } });
    changeSyllabus({ target: { value: "Any" } });
    changeUser({ target: { value: "Any" } });
    setFilter({
      // ...filter,
      // type: "",
      // type_id: "",
      type: "",
      type_id: "",
      user_type: user.role,
      user_id: user.id,
      subject_id: "",
      syllabus_id: "",
      added_by: "",
      subName: "Any",
    });
    setSearchQuery("");
  };

  const handleStatusFiter = (e) => {
    if (e.target.value === 1) {
      setPage(1);
      setIsApproved(1);
    } else {
      setPage(1);
      setIsApproved(null);
    }
  };

  const handleApprove = (data) => {
    console.log(data, "dsadbsa");
    api
      .approveQuestion(data.q_id, data.isApprove, user)
      .then((res) => {
        console.log(res, "dsadbsa");
        setPage(1);
        setAll({
          ...all,
          // data: all.map((e) =>
          //     e.q_id === res.data.q_id ? { ...e, isApprove: e.isApprove === null ? 1 : null } : e
          // )
          data: Object.keys(all.data).map((key) =>
            all?.data[key].q_id === res.data.q_id
              ? {
                  ...all.data[key],
                  isApprove: data.isApprove === null ? 1 : null,
                }
              : all.data[key]
          ),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDelete = (data) => {
    console.log(props.modalData, all.data, "dataforDelete");
    // console.log(all?.data.filter(o => o.q_id !== props.modalData.q_id), 'dataforDelete');
    // return;
    api
      .deleteQuestion(props.modalData.q_id)
      .then((res) => {
        setAll({
          ...all,
          data: all.data.filter((o) => o.q_id !== props.modalData.q_id),
        });
        props.setOpen(false);
        Swal.fire({
          title: "Deleted",
          text: `${res.data.message}`,
          icon: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "oops",
          text: "Somethign went Wrong",
          icon: "error",
        });
      });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdroploader}
        // onClick={handleCloseLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="row my-3">
        <div className="col-md-3">
          {filter.type || searchQuery.length > 3 ? (
            <Button variant="outlined" fullWidth onClick={() => removeFilter()}>
              Remove Filter &nbsp; <CancelIcon />
            </Button>
          ) : (
            <Button variant="outlined" fullWidth disabled>
              Filter &nbsp; <ArrowCircleRightIcon />
            </Button>
          )}
        </div>

        <div className="col-md-3">
          <Box>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Subjects</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={syl}
                // defaultValue="Any"
                value={filter.subject_id}
                label="Filter By Subject"
                //   onChange={(e) => FilterData("BySubject", e)}
                onChange={(e) => changeSubject(e)}
              >
                {props.subject &&
                  [
                    { id: "0", value: "Any", subName: "Any" },
                    ...props.subject,
                  ].map((s) => (
                    <MenuItem
                      key={s.id}
                      value={s.id}
                    >
                      {s.subName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        <div className="col-md-3">
          <Box>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Syllabus</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value=""
                defaultValue=""
                value={filter.syllabus_id}
                label="Syllabus"
                onChange={(e) => changeSyllabus(e)}
                //   onChange={(e) => FilterData("filterBySyllabus", e)}
              >
                {props.syllabus &&
                  filter.subject_id !== "" &&
                  [
                    { id: "Any", value: "Any", syllabus: "Any" },
                    ...props.syllabus,
                  ].map((s) => (
                    <MenuItem key={s.id} value={s.id}>
                      {s.syllabus}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        <div className="col-md-3">
          <Box>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Added By</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Syllabus"
                defaultValue="Any"
                value={filter.added_by}
                onChange={(e) => changeUser(e)}
                //   onChange={(e) => FilterData("filterByUser", e)}
                // onChange={(e) => filterByUser(e)}
              >
                {props.users &&
                  [
                    { id: "", value: "Any", username: "Any" },
                    ...props.users,
                  ].map((u) => (
                    <MenuItem value={u.id}>
                      {u.username} {u.id}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        <div className="col-md-3">
          <Box>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Syllabus"
                defaultValue="1"
                onChange={(e) => handleStatusFiter(e)}
                //   onChange={(e) => FilterData("filterByUser", e)}
                // onChange={(e) => filterByUser(e)}
              >
                <MenuItem value={1}>Aproved</MenuItem>
                <MenuItem value={2}>Draft</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>

                  {/* search query */}
        <div className="col-md-8 pt-2">
          <Box>
            <FormControl fullWidth size="small">
              {/* <InputLabel id="demo-simple-select-label">Saerch</InputLabel> */}
              <TextField
                label="Search"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={searchQuery}
                // defaultValue="1"
                onChange={(e) => changeSearch(e)}
                //   onChange={(e) => FilterData("filterByUser", e)}
                // onChange={(e) => filterByUser(e)}
              >
                <MenuItem value={1}>Aproved</MenuItem>
                <MenuItem value={2}>Draft</MenuItem>
              </TextField>
            </FormControl>
          </Box>
        </div>
      </div>

      <EnhancedTable
        all={all}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        handleDelete={handleDelete}
        handleApprove={handleApprove}
        handleClickEdit={props.handleClickEdit}
        handleDeleteModal={props.handleDeleteModal}
      />
      <Modal
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={[
            style,
            {
              width: props.modalBody === 1 ? "90%" : "25%",
              height: props.modalBody === 1 ? "100%" : "auto",
              border: props.modalBody === 2 && 0,
              overflow: props.modalBody === 2 && "hidden",
            },
          ]}
        >
          {props.modalBody === 1 ? (
            <EditQuestionPage
              state={props.modalData}
              setOpen={props.setOpen}
              setAllChange={setAll}
              wholeArray={all?.data}
            />
          ) : (
            <ConfirmModal
              handleDelete={handleDelete}
              handleCloseModal={handleCloseModal}
            />
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ViewQuestions;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: '90%',
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflow: "scroll",
  // height: '100%'
};
