import React, { useState } from 'react'
import { Paper } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import PaperList from '../table/paperlist';

const PaperPage = (props) => {
    const [backdroploader, setBackdropLoader] = useState(false);




    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdroploader}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* <LinearWithValueLabel /> */}
            <section>
                <div className='row gap-2'>
                    <Paper>
                        <div className='my-2'>
                            <h3>Generated Paper </h3>
                        </div>
                    </Paper>
                    <div>
                        <PaperList  setBackdropLoader={setBackdropLoader}/>
                    </div>
                </div>

            </section>
        </>
    )
}

export default PaperPage;