import React, { useState, useEffect } from "react";
import { Button, Modal, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import api from "../../API/api";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useNavigate, useParams } from "react-router-dom";
// import InfoQues from '../question/infoQues';
import AuthUser from "../../API/token";
// import MenuAction from '../question/menuActions';
import QuestionAllTable from "./questionAllTable";
import { Backdrop } from "@mui/material";
import { CircularProgress } from "@mui/material";
import ViewQuestions from "./new_view_questions";
import EditQuestionPage from "../Pages/editQuestionPage";

const htmlToFormattedText = require("html-to-formatted-text");

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function QuestionList() {
  const { user } = AuthUser();
  const navigation = useNavigate();
  const id = useParams("id");
  const subName = useParams("sub");
  const sub = useParams("selfId");

  useEffect(() => {
    getSubject();
  }, []);

  const [questions, setQuestions] = useState([]);
  const [filterQuestions, setFilterQuestion] = useState();

  const [backdroploader, setBackdropLoader] = useState(false);
  const [syl, setSyl] = React.useState("");
  const [subject, setSubject] = useState();
  const [syllabus, setSyllabus] = useState();
  const [noData, setNoData] = useState();
  const [users, setUsers] = useState();
  const [filterType, setFilterType] = useState("all");
  const [filterEvent, setFilterEvent] = useState("");
  const [testing, setTesting] = useState(0);
  const [open, setOpen] = useState();
  const [modalData, setModalData] = useState();
  const [modalBody, setModalBody] = useState(0);
  const [all, setAll] = useState([]);


  const handleCloseModal = () => {
    setOpen(false)
  }

  const getSubject = () => {
    // setBackdropLoader(true);
    api
      .getSubject()
      .then((res) => {
        setSubject(res.data.subject);
        // setBackdropLoader(false);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const getQuestionBySyllbus = (id) => {
    api
      .getQuestionBySyllbus(id)
      .then((res) => {
        // console.log(res, 'whats the res');
        if (res.data.question) {
          setQuestions(res.data.question);
        } else {
          setQuestions("");
          setNoData("Questions not Found  ");
        }
      })
      .catch((err) => {
        console.error(err, "err");
      });
  };
  const getSyllabusBySub = (event) => {
    console.log(event);
    api
      .getSyllabusBySub(event)
      .then((res) => {
        console.log(res, 'check by syll');
        setSyllabus(res.data.syllabus);
      })
      .catch((err) => {
        console.log(err, "check by syll eerr");
      });
  };

  useEffect(() => {
    getSubject();
    // getQuestion(5);
    getAllUser();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleInfinitescroll);
    return () => window.removeEventListener("scroll", handleInfinitescroll);
  }, []);

  const [page, setPage] = useState(1);

  const handleInfinitescroll = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      console.log("error=>", error);
    }
  };

  const [card, setCard] = useState([]);

  const getData = async () => {
    const res = await fetch(
      `http://127.0.0.1:8000/api/getQuestionPage/2?page=${page}`
    );

    const data = await res.json();
    console.log("res", data);
    setCard((prev) => [...prev, ...data]);

    // if (user.role == 'SA') {
    //     setQuestions(res.data.question)
    // } else {
    //     var filterdata = res.data.question.filter((data) => data.addedBy?.id == user.id);
    //     setQuestions(filterdata)
    // }
  };

  // useEffect(()=>{
  //     console.log("p", page);
  //     console.log("p", card);
  //     getData();
  // }, [page])

  const getAllUser = () => {
    api
      .getAllUser()
      .then((res) => {
        if (user.role == "SA") {
          setUsers(res.data.users);
        } else {
          setUsers([user]);
        }
      })
      .catch((err) => {
        console.log("get user error", err.response);
      });
  };

  const getQuestion = () => {
    setBackdropLoader(true);
    // let limit = 20;
    api
      .getQuestion()
      .then((res) => {
        console.log("question", res);
        if (res.data.question) {
          if (user.role == "SA") {
            setQuestions(res.data.question);
            // FilterData(filterType, filterEvent)
            // defaultFilter(res.data.question)
          } else {
            var filterdata = res.data.question.filter(
              (data) => data.addedBy?.id == user.id
              //  console.log(data,'chekc ata');
            );
            console.log(filterdata, "filterdata");
            setQuestions(filterdata);
            // FilterData(filterType, filterEvent)
            // defaultFilter(filterdata)
          }
        }
        setBackdropLoader(false);
      })
      .catch((err) => {
        console.log(err, "check error");
      });
  };

  useEffect(() => {
    FilterData(filterType, filterEvent);
  }, [questions]);

  const FilterData = (type, e) => {
    // remember filter
    setFilterType(type);
    if (e?.target) {
      setFilterEvent(e?.target.value);
      var event = e?.target.value;
    } else {
      setFilterEvent(e);
      var event = e;
    }

    if (type == "all") {
      setFilterQuestion(questions);
    }

    if (type == "BySubject") {
      var BySubject = questions.filter((data) => data.subject?.id == event);
      setFilterQuestion(BySubject);
      setSyl(event);
      getSyllabusBySub(event);
    }

    if (type == "filterByUser") {
      var filterByUsers = questions.filter((data) => data.addedBy?.id == event);
      setFilterQuestion(filterByUsers);
    }
    if (type == "filterBySyllabus") {
      console.log(event);
      var BySyllabus = questions.filter((data) => data.syllabus?.id == event);
      setFilterQuestion(BySyllabus);
    }
  };

  const handleApprove = (data) => {
    api
      .approveQuestion(data.q_id, data.isApprove, user)
      .then((res) => {
        console.log(res);
        // getQuestionBySyl();
        // getQuestion();
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleClickEdit = (row) => {
    setModalBody(1)
    setOpen(true)
    console.log(row,'check row');
    let rightAnswer = row.ans.filter((el) => {
      if (el.isRight === 1) return el;
    });
    let wrong = row.ans.filter((el) => {
      if (el.isRight === 0) return el;
    });
    setModalData({
      row: row,
      subname: row.subject.subName,
      id: row.subject.id,
      selfId: row.syllabus.id,
      rightAnswer: rightAnswer,
      subject: subject,
      syllabus: syllabus,
      wrong: wrong,
      type: "quesTable",
    }
    )
    // console.log("===>all==>>", row);
    // // return;
    // console.log(wrong, "wrong2");
    // navigation(`/admin/editQuestionsPage/`, {
    //   state: {
    //     row: row,
    //     subname: row.subject.subName,
    //     id: row.subject.id,
    //     selfId: row.syllabus.id,
    //     rightAnswer: rightAnswer,
    //     subject: subject,
    //     syllabus: syllabus,
    //     wrong: wrong,
    //     type: "quesTable",
    //   },
    // });
  };
  const handleDeleteModal = (data)=> {
    setModalBody(2)
    setOpen(true)
    setModalData(data)
  }

  return (
    <>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdroploader}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <div>
        <Paper>
          <div className=" space-between p-2">
            <h4>All Questions </h4>
            <Button
              variant="contained"
              size="small"
              onClick={() => navigation("/admin/AddQuestion")}
            >
              + Add New Question
            </Button>
          </div>
          {/* <div className="row p-3">
            <div className="w-100 row space-between mb-4">
              <div className="col-md-4">
                <h6>Filter By</h6>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => FilterData("all")}
                >
                  View All Questions
                </Button>
              </div>

              <div className="col-md-3">
                <Box>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Subjects
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value={syl}
                      defaultValue=""
                      label="Filter By Subject"
                      onChange={(e) => FilterData("BySubject", e)}
                    >
                      {subject &&
                        subject.map((s) => (
                          <MenuItem key={s.id} value={s.id}>
                            {s.subName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className="col-md-3">
                <Box>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Syllabus
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // value=""
                      defaultValue=""
                      label="Syllabus"
                      onChange={(e) => FilterData("filterBySyllabus", e)}
                    >
                      {syllabus &&
                        syllabus.map((s) => (
                          <MenuItem key={s.id} value={s.id}>
                            {s.syllabus}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className="col-md-3">
                <Box>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Added By
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Syllabus"
                      defaultValue=""
                      onChange={(e) => FilterData("filterByUser", e)}
                      // onChange={(e) => filterByUser(e)}
                    >
                      {users &&
                        users.map((u) => (
                          <MenuItem value={u.id}>{u.username}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>
          </div> */}
        </Paper>
      </div>

      <br />
      {/* old  */}
      {/* <QuestionAllTable questions={filterQuestions} getQuestionBySyl={getQuestionBySyllbus} setTesting={setTesting} testing={testing} setQuestions={setQuestions} handleApprove={handleApprove} handleClickEdit={handleClickEdit} /> */}

      {/* new  */}
      <ViewQuestions
        //   questions={filterQuestions} 
        //   getQuestionBySyl={getQuestionBySyllbus} 
        //   setTesting={setTesting} testing={testing} 
        //   setQuestions={setQuestions} 
        handleApprove={handleApprove}
        handleClickEdit={handleClickEdit}
        handleDeleteModal={handleDeleteModal}
        subject={subject}
        syllabus={syllabus}
        getSyllabusBySub={getSyllabusBySub}
        users={users}
        setAllParent={setAll}
        perentAll={all}
        modalData={modalData}
        setOpen={setOpen}
        open={open}
        setModalData={setModalData}
        modalBody={modalBody}
        setModalBody={setModalBody}
      />

      {/* modal edit question */}
      {/* <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <EditQuestionPage
            state={modalData} setOpen={setOpen}
          />
        </Box>
      </Modal> */}
      {/* modal edit question */}
    </>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  overflow: 'scroll', height: '100%'
};