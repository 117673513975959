import React, { useState } from "react";
import api from "../../API/api";
import Swal from "sweetalert2";

import { useRef } from "react";

const ChangeAns = (props) => {
  const [img, setImg] = useState();
  const [preview, setPreview] = useState();

  const handleImg = (e) => {
    setImg(e.target.files[0]);
    setPreview(URL.createObjectURL(e.target.files[0]));
  };

  const handleChangeAnsImg = () => {
    let data = {
      img: img,
      ans_id: props.data.ans_id,
    };

    api
      .handleChangeAnsImg(data)
      .then((res) => {
        console.log("res", res);
        if (res.data.status === 200) {
          Swal.fire("Updated", "success");
        }
        props.handleCloseMove();
      })
      .catch((err) => {
        console.log(err);
      });

    return;
  };

  return (
    <>
      <div>
        <div className="row">
          <div className="col-md-6 d-flex align-items-center">
            <input
              type="file"
              onChange={(e) => handleImg(e)}
              className="form-control"
              name=""
              id=""
            />
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6 text-center border border-primary">
                <div>
                  <u>Previous Image</u>
                </div>
                <div className="text-center">
                  <img
                    src={`${process.env.REACT_APP_BACKEND}answers/img/${props.data.preview}`}
                    className="col-10"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 text-center border border-primary">
                <div>
                  <u>New Image</u>
                </div>
                <div>
                  {/* <img src="/assets/test.gif" className='col-10' alt="" /> */}
                  <img src={preview} className="col-10" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <div className="text-center">
              <button
                className="btn btn-warning bt-sm"
                onClick={handleChangeAnsImg}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeAns;
