import {
  Box,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import LoadingSpinner from "./loading-spinner";
import api from "../../API/api";
import UserDetailPurchaseItem from "./components/user-detail-purchase-item";
import UserPurchacesPagination from "./components/user-purchases-pagination";
import UserDetailSubscriptionItem from "./components/user-detail-subscription-item";

const UserDetailPaper = ({ user }) => {
  const [loading, setLoading] = useState(true);

  const theme = useTheme();

  const isBelowMD = useMediaQuery(theme.breakpoints.down("md"));

  const [subscriptions, setSubscriptions] = useState([]);
  const [paginateOptions, setPaginateOptions] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
    last_page: 1,
  });

  const getSubscriptions = (page, perPage, filters = {}) => {
    api
      .appUserPapers({
        id: user.id,
        page: page,
        perPage: perPage,
        ...filters,
      })
      .then((res) => {
        if (res.data.success) {
          let localSubscriptions = res.data.subscriptions;
          setSubscriptions(localSubscriptions.data);

          delete localSubscriptions.data;
          setPaginateOptions({
            ...localSubscriptions,
          });
        }
        setLoading(false);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = (page) => {
    setPaginateOptions({ ...paginateOptions, current_page: page });
  };

  const handlePerPageChange = (perPage) => {
    setPaginateOptions({ ...paginateOptions, per_page: perPage });
  };

  useEffect(() => {
    if (user) {
      getSubscriptions();
    }
  }, [user, paginateOptions.current_page, paginateOptions.per_page]);

  return (
    <Stack
      direction={"column"}
      spacing={2}
      mt={2}
      bgcolor={"#f0f0f0"}
      borderRadius={2}
      color={"#424242"}
      flexGrow={1}
      p={2}
      overflow={"hidden"}
    >
      {loading && <LoadingSpinner />}
      {!loading && (
        <>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography variant="h6">Papers</Typography>
            {subscriptions.length > 0 && (
              <Box>
                <UserPurchacesPagination
                  paginationOptions={paginateOptions}
                  onPageChange={handlePageChange}
                />
              </Box>
            )}
          </Stack>
          <Stack
            direction={"row"}
            flexWrap={"wrap"}
            sx={{
              overflowY: "scroll",
              height: isBelowMD ? "60vh" : "100%",
            }}
          >
            {subscriptions.length === 0 && (
              <Typography variant="body1">No subscriptions found</Typography>
            )}
            {subscriptions.map((subscription) => (
              <UserDetailSubscriptionItem
                key={subscription.id}
                subscription={subscription}
              />
              //   <UserDetailPurchaseItem
              //     key={subscription.id}
              //     subscription={subscription}
              //   />
            ))}
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default UserDetailPaper;
