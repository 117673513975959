import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import api from "../../API/api"
import FolderIcon from '@mui/icons-material/Folder';
import Swal from "sweetalert2";


const MoveFolder = (props) => {

    const vals = useParams();
    // console.log(vals, 'props2');
    let selfId = props.selfId.id;
    let id = props.fId;
    const [files, setFiles] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [folderId, setFolderId] = useState(0);

    const getSyllabusById = () => {
        api.getSyllabusById(selfId).then((res) => {
            console.log(res, 'check syl ref');
            setFiles(res.data.files);
            // setLoader(false)
        })
            .catch((err) => {
                // console.log(err.response);
            })
    }

    useEffect(() => {
        getSyllabusById();
    }, [])

    const goBack = () => {
        getSyllabusById(selfId, id);
    }

    const handleClick = (e, file) => {
        // return;
        if (props?.selectId.id == file?.id) {
            Swal.fire({
                // title: 'Are you sure?',
                text: "Cant Move to Same folder ",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                // confirmButtonText: 'Yes, delete it!'
            })
            return;
        }
        setFolderId(file)
        switch (e.detail) {
            case 1:
                setIsActive(file.id);
                break;
            case 2:
                console.log("double click");
                api.getSyllabusById(vals.id, file.id).then((res) => {
                    setFiles(res.data.files);
                })
                    .catch((err) => {
                        console.log(err.response);
                    })
                break;
            case 3:
                console.log("triple click");
                break;
        }
    }

    const handlePaste = () => {
        console.log(props.selectId, 'folderId');
        let catchMatch = files.filter(obj => {
            console.log(obj, obj.id === props.selectId.id, 'check true');
            if (obj.id === props.selectId.id) {
                Swal.fire({
                    // title: 'Are you sure?',
                    text: "Same file/folder already Exist",
                    icon: 'error',
                    showCancelButton: true,
                    // confirmButtonColor: '#3085d6',
                    // cancelButtonColor: '#d33',
                    // confirmButtonText: 'Yes, delete it!'
                })
                return obj;
            }
        })

        if (catchMatch.length > 0) {
            Swal.fire({
                text: "Same file/folder already Exist",
                icon: 'error',
                showCancelButton: true,
            })
        } else {
            if (props.copy === true) {
                // console.log('copy here');
                api.copySyllabus(folderId, props.selectId.data).then(res => {
                    props.setOpenMove(false);
                    console.log(res);
                    Swal.fire({
                        // title: 'Are you sure?',
                        text: "File copied Successfully",
                        icon: 'success',
                        // showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        // confirmButtonText: 'Yes, delete it!'
                    }).then(result => {
                        // console.log(result,'check swal result');
                        if (result) {
                            props.getSyllabusById(selfId, id);
                        }
                    })
                }).catch(err => {
                    // console.log(err , 'log err');
                })
            } else {
                api.moveSyllabus(folderId, props.selectId.data).then(res => {
                    props.setOpenMove(false);
                    getSyllabusById();
                    console.log(res);
                    Swal.fire({
                        // title: 'Are you sure?',
                        text: "File Moved Successfully",
                        icon: 'success',
                        // showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        // confirmButtonText: 'Yes, delete it!'
                    }).then(result => {
                        // console.log(result,'check swal result');
                        if (result) {
                            props.getSyllabusById(selfId, id);
                        }
                    })
                }).catch(err => {
                    // console.log(err , 'log err');
                })
            }
        }
    }
    console.log(props.selectId, 'check copy')

    return (
        <>
            {/* go back */}
            <h4>Move File to :-</h4>
            <div onClick={e => goBack()}>
                <div className="col-md-12 p-2 m-1">
                    <FolderIcon style={{ fontSize: 25 }} color="primary" />
                    <strong className={''}>  ... </strong>
                </div>
            </div>
            {
                files?.map((file) => (
                    <>
                        {/* <div className={` ${isActive == file ? "bg-active" : 'file'}`} onClick={e => handleClick(e, file)}> */}
                        <div className={` ${isActive == file.id ? "bg-active p-1" : 'file'}`} onClick={e => handleClick(e, file)} >
                            {file.type === 'folder' &&
                                <>
                                    <div className={`fileTwo p-2 m-1 ${!isActive == file ? "bg-active" : 'file'}`}>
                                        <FolderIcon style={{ fontSize: 25 }} color="primary" />
                                        <strong className={''}>  {file.syllabus} </strong>
                                    </div>
                                </>
                            }
                        </div>
                    </>
                ))}

            <div className="text-end mt-3">
                <button className="btn btn-primary btn-sm" onClick={() => handlePaste()}>Paste here</button>
            </div>
        </>
    )
}

export default MoveFolder;