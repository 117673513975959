import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";

export default function DialogModal(props) {
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState(props?.width ? props?.width : "sm");
  const [title, setTitle] = React.useState(props?.title ? props?.title : false);

  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  const handleClose = () => {
    props.handleCloseMove();
  };

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={props.open}
      onClose={handleClose}
    >
      {title && (
        <DialogTitle>
          {props?.title }
        </DialogTitle>
      )}
      <DialogContent>{props.form}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
