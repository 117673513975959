import { Grid } from "@mui/material";
import LivePaperList from "./features/live-paper.list";
import LivePaperLeaderboard from "./features/live-paper.leaderboard";
import { useEffect, useState } from "react";

const LivePaperIndex = () => {
  const [selectedLivePaper, setSelectedLivePaper] = useState(null);

  const changeSelectedLivePaper = (livePaper) => {
    console.log("livePaper", livePaper);
    setSelectedLivePaper(livePaper);
  };

  useEffect(() => {
    console.log("selectedLivePaper", selectedLivePaper);
  }, [selectedLivePaper]);

  return (
    <Grid container spacing={2} sx={{ minHeight: "85vh", width: "100%" }}>
      <Grid item xs={6}>
        <LivePaperList
          changeSelectedLivePaper={changeSelectedLivePaper}
          selectedLivePaper={selectedLivePaper}
        />
      </Grid>
      <Grid item xs={6}>
        <LivePaperLeaderboard selectedLivePaper={selectedLivePaper} />
      </Grid>
    </Grid>
  );
};

export default LivePaperIndex;
